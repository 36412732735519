import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import format from "date-fns/format";
//import {fetchUser,resetUser} from "../../actions/authAction";
import { fetchAppParameter } from "../../actions/optionAction";
import {

  Container,
  Segment,
  Button,
  // Header,
  // Confirm
} from "semantic-ui-react";




class AppParameterDetail extends Component {
  // state = {
  //   id: "",
  //   open: false,
  //   result: "show the modal to capture a result"
  // };

  componentDidMount() {
    if (!this.props.parameter ){
      this.props.fetchAppParameter()
        }
  }


  // componentWillUnmount() {
  //     this.props.resetUser();
  //   }
  render() {

    const {
      currentURL,
      imageInfoURL,
      imageProfileURL,
      limitCompany,
      limitBorrowLend,
      limitLocation,
      limitTask,
      limitRP,
      limitFinance,
      limitContact,
    } =
      this.props.parameter !== undefined &&
      this.props.parameter !== null &&
      this.props.parameter;

//********************


    return (
      <Container text>

        {/**/}
        <div className='cashbook-H'>

<div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
  App Parameter Detail
</div>
</div>

{/*<div
style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
onClick={this.handleSearchSingleAccountFinance}>
      <span className='icon-button border1' > <Icon name='search' />Search</span >
    </div>*/}


</div>
{/**/}

        <Segment attached>
          <Button.Group size="mini" floated="right">


            <div ><Button
              // basic
              // inverted
              floated="right"
              size="mini"
              color="teal"
              type="button"
              as={Link}
              //target='_blank'
              to={`/appParameter/AppParameterEdit`}
            >
              Edit
            </Button></div>

          </Button.Group>
          <br />
          <br />
          <div >
        {currentURL && (
        <div className='row'>
        <div className='side'>
        <b>currentURL</b>
        </div>
        <div className='main'>
        <b>{currentURL}</b>
        </div>

        </div>
        )}
        </div>

        <div >
          {imageInfoURL && (
        <div className='row'>
        <div className='side'>
        <b>imageInfoURL</b>
        </div>
        <div className='main'>
        <b>{imageInfoURL}</b>
        </div>

        </div>
        )}
        </div>

        <div >
        {imageProfileURL && (
        <div className='row'>
        <div className='side'>
        <b>imageProfileURL</b>
        </div>
        <div className='main'>
        <b>{imageProfileURL}</b>
        </div>

        </div>
        )}
        </div>


                
        

        </Segment>
      </Container>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    parameter:state.commons.parameter,

  };
};

export default connect(
  mapStateToProps,
  {fetchAppParameter}
)(AppParameterDetail);
