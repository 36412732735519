import React, { Component } from "react";


class ContactDetail extends Component {
  
 render() {

   const {
      //advertisedBy,
      //startedOn,
      //expiredOn,
      name,
      phone1,
      phone2,
      email,
      whatsapp,
      _id

   } = this.props.contact;


   //******************


   const renderContactDetail = () => {
    
    return (
    <div >

       <div >
       {name && (
         <div className='row'>
           <div className='side'>
             <b>Name</b>
           </div>
           <div className='main'>
             <b>{name}</b>
           </div>

         </div>
       )}
     </div>

     <div >
     {phone1 && (
       <div className='row'>
         <div className='side'>
           <b>Phone1</b>
         </div>
         <div className='main'>
           <b>{phone1}</b>
         </div>

       </div>
     )}
   </div>

   <div >
   {phone2 && (
     <div className='row'>
       <div className='side'>
         <b>Phone2</b>
       </div>
       <div className='main'>
         <b>{phone2}</b>
       </div>

     </div>
   )}
 </div>

 <div >
 {email && (
   <div className='row'>
     <div className='side'>
       <b>Email</b>
     </div>
     <div className='main'>
       <b>{email}</b>
     </div>

   </div>
 )}
</div>

<div >
{whatsapp && (
 <div className='row'>
   <div className='side'>
     <b>Whatsapp ID</b>
   </div>
   <div className='main'>
     <b>{whatsapp}</b>
   </div>

 </div>
)}
</div>

</div>
)
    
    }
    //**** */
   return (
    <div className='card-attached border-sb '  >


    <div className='pxy-20' >
    <div className='border-bottom heading' >
    <span  > Owner Contact Detail </span>
    </div>
    {renderContactDetail()}
    </div>
    

 <br/>
     </div>

     //**********************************

   );
 }
}


export default ContactDetail
