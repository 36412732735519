import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { getUserForAuth} from "../../actions/authAction";
import { fetchAppParameter } from "../../actions/optionAction";

import {
  Confirm,
  //Header,
  Container,
  Segment,
  //Button,
  Divider,
} from "semantic-ui-react"; 



class URLList extends Component {
  state = { url: "", open: false, result: "show the modal to capture a result" };

  componentDidMount() {
    this.props.getUserForAuth()
    this.props.fetchAppParameter()
  }
  show = value => {

    this.setState({ open: true, url: value });
  };

  handleConfirm = async () => {
    const values = {url:this.state.url};


    //const res = await axios.put(`/api/AgreementInactive/${did}`);
    const res = await axios.post(`/api/URLDelete`, values);

    if(res.data.msg ==='success'){
      //this.props.getUserForAuth() 
      this.props.fetchAppParameter()
      this.setState({ result: "confirmed", open: false });
      toastr.success('Deleted Successfully')
    }
  };
  handleCancel = () => this.setState({ result: "cancelled", open: false });

renderList=(item)=> {
  //  const { accountsName, _id } = item;

    const renderButton = () =>
      this.props.userA !== null &&
      (this.props.userA.financeDelete === "Yes" ||this.props.userA.userType === "guest"||
        this.props.userA.userType === "admin") ? (
          <span
        //  style={{marginLeft:'10px'}}
          onClick={() => this.show(item)}
          className="icon-button"
          >
          <img className= 'yimg ' src='/deleteIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
      ) : (
        <div></div>
      );

    return (
      <Segment key={item}>

        <Divider fitted />

        <div >
        {item && (
          <div className='rowERP'>
          <div className='sideERP'>

            <b>URL</b>
          </div>
          <div className='mainERP'>
            <b>{item}</b>{' '}
          </div>
          <div>
          {renderButton()}</div>
          </div>
        )}
        </div>


      </Segment>
    );
  }

  render() {
    const { open,  } = this.state;
    
    return (
      <Container text>
      <Confirm
         header='You Want To Delete !'
        size="mini"
        open={open}
        onCancel={this.handleCancel}
        onConfirm={() => this.handleConfirm()}
      />

        {/**/}
        <div className='margin-auto-H' >
        <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
          {/*  */}
          URL List
       {/*  */}
       </div>
       </div>
       {/**/}
        {this.props.parameter !== undefined &&
      this.props.parameter !== null &&
      this.props.parameter.allURL.length >0
          && this.props.parameter.allURL.sort().map(this.renderList)}
      </Container>
    );
  }
  
  }



const mapStateToProps = (state) => {

  return {
    parameter:state.commons.parameter,
    userA: state.auth.userA,
  };
};

export default connect(
  mapStateToProps,
  {getUserForAuth,fetchAppParameter}
)(URLList);
