import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import {createProjectNameCom} from "../../actions/appParameterAction";

import {
  Container,
  Segment,
  Form,
  Button,
  
} from "semantic-ui-react";
import TextInput from "../form/TextInput";


  class ProjectNameComCreate extends Component {

   onSubmit = values => {
    this.props.createProjectNameCom(values);
  };

render(){
  const { handleSubmit, pristine, reset, submitting } = this.props;
  if (
    this.props.userA !== null &&
    (this.props.userA.userType === "admin")
  ) {
  return (
    <Container text>
    <div style={{maxWidth:'700px',margin:'auto'}}>
    
        {/**/}
        <div className='margin-auto-H' >
        <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
          {/*  */}
          Create ProjectNameCom
       {/*  */}
       </div>
       </div>
       {/**/}
        <div className="card border">
          <div className=" label" >

        <Form style={{ padding: 10 }} onSubmit={handleSubmit(this.onSubmit)}>
          <label >ProjectNameCom</label>
          <Field
            name="projectNameCom"
            type="text"
            component={TextInput}
            placeholder="ProjectNameCom"
          />

          <br />

          <Button color="olive" type="submit" disabled={pristine || submitting}>
            Submit
          </Button>
          <Button
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
        </Form>
        <br/><br/><br/>
            </div>
            </div>
            </div>
            </Container>
  );
}

    return (
      <Container text>
        <Segment>
          <div style={{ color: "red", textAlign: "center" }}>
            <h2>You Are Not Authorised To Create ProjectNameCom </h2>
          </div>
        </Segment>
      </Container>
    );
  }
}
  const mapStateToProps = state => {
    return {
      state,
      userA: state.auth.userA

    };
  };
export default connect(
   mapStateToProps,
{createProjectNameCom}
)(
  reduxForm({
    form: "ProjectNameComCreate" // a unique identifier for this form
    // validate
  })(ProjectNameComCreate)
);
