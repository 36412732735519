import React, { Component } from "react";
import { connect } from "react-redux";
//import { Grid ,Button,Icon } from "semantic-ui-react";
import { Switch, Route, } from "react-router-dom";
import { showBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";

import AddImageVideo from "./AddImageVideo";
import ImageEdit from "../imageVideo/ImageEdit"
import VideoEdit from "../imageVideo/VideoEdit"

import AdByDealersSearchList from "./AdByDealersSearchList";
AdByDealersSearchList
import ContactReqForDealerList from "./ContactReqForDealerList";
import ContactReqByPartyList from "./ContactReqByPartyList";
import AdByDealerList from "./AdByDealerList";
import CreditDetailList from "./CreditDetailList";
import CreditAddCreate from './CreditAddCreate';
import CreditAddEdit from './CreditAddEdit';
import CreditAddSearchList from './CreditAddSearchList';
import AdByFinancerList from "./AdByFinancerList";
import Layout from "../layout/Layout";

class ActivityDashboard extends Component { 
  componentDidMount() {
    if(this.props.auth.isAuthenticated){
      this.props.getUserForAuth();
    }

    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }

  }
  
  onLogoutClick = () => {
   
    this.props.logoutUser();
  };

  render() {
    return (
    <div>
    <div className='maindiv'>

    <Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
    <Route exact path="/activity/AddImageVideo/:id" component={AddImageVideo} />
    <Route exact path="/activity/ImageEdit/:id" component={ImageEdit} />
    <Route exact path="/activity/VideoEdit/:id" component={VideoEdit} />
    <Route
      exact
      path="/activity/ContactReqForDealerList/:id"
      component={ContactReqForDealerList}
    />
        <Route
      exact
      path="/activity/ContactReqByPartyList/:id"
      component={ContactReqByPartyList}
    />

    <Route exact path="/activity/CreditAddCreate/:id" component={CreditAddCreate} />
    <Route exact path="/activity/CreditAddEdit/:id" component={CreditAddEdit} />
    <Route
      exact
      path="/activity/CreditAddSearchList"
      component={CreditAddSearchList}
    />
    <Route
      exact
      path="/activity/CreditDetailList"
      component={CreditDetailList}
    />
    <Route
      exact
      path="/activity/CreditDetailList/:id"
      component={CreditDetailList}
    />
    <Route
      exact
      path="/activity/AdByDealerList"
      component={AdByDealerList}
    />
    <Route
      exact
      path="/activity/AdByDealerList/:id"
      component={AdByDealerList}
    />
<Route
      exact
      path="/activity/AdByFinancerList/:id"
      component={AdByFinancerList}
    />

<Route
      exact
      path="/activity/AdByDealersSearchList"
      component={AdByDealersSearchList}
    />

    </Switch>
        </Layout >
        </div>

    <div className='nodisplay'>
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>
      );


}
}
const mapStateToProps = state => ({
auth: state.auth
});


export default connect(
   mapStateToProps,
  {showBackButton, getUserForAuth, logoutUser  }
)(ActivityDashboard);
