import React, { Component } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
// import {
//   Button,
// } from "semantic-ui-react";

class FinancerAd extends Component {

  handleBookFinancerAd = async (values) => {
    

    if(values.balanceCreditProp > values.creditRequireForFinancerAd){
      
      try {
      
        const res = await axios.post(`/api/FinancerAdBookOrRenew`, values);
        if (res.data.msg ==='success'){
          this.props.handleRefresh()
          this.props.handleModalClose()
        toastr.success('Ad Created Successfully');
        }
        
      } catch (error) {
       
        toastr.error("Oops", "Something went wrong.Please Try Again CreateAd");
      }
    }else{
      toastr.error("You Do Not Have Enough Credit To Create Ad");
  
    }
    
  };
  
  //@@@@@@@@@@@@
  handleRenewFinancerAd = async (values) => {
    
    if(values.balanceCreditProp > values.creditRequireForFinancerAd){
      
      try {
      
        const res = await axios.post(`/api/FinancerAdBookOrRenew`, values);
    

        if (res.data.msg ==='success'){
        this.props.handleRefresh()
        this.props.handleModalClose()
        toastr.success('Ad Renew Successfully');
        }
        
      } catch (error) {
      
        toastr.error("Oops", "Something went wrong.Please Try Again CreateAd");
      }
    }else{
      toastr.error("You Do Not Have Enough Credit To Renew Ad");
  
    }
    
  };
  

  render() {
    

const {
  action,
  balanceCreditProp,
  creditRequireForFinancerAd,
} = this.props.valueFinAd


//*********** */
const renderFieldAndButton = ()=>{
  if(action === 'Book'){
    return(
        <>
        <div >
          {balanceCreditProp >0 && (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Total Credit Available</b>
            </div>
            <div className='main flex-value-A'>
            <b>{balanceCreditProp}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
  
          <div >
          {creditRequireForFinancerAd >0 && (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Credit Required To Create Ad</b>
            </div>
            <div className='main flex-value-A'>
            <b>{creditRequireForFinancerAd}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>

          {/* <div >
          {creditRequireToLock && ( */}
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Ad Will be Created For Days</b>
            </div>
            <div className='main flex-value-A'>
            <b>30 Days{' '}</b>{' '}
            </div>
  
          </div>
          {/* )}
          </div> */}
          <br/>
          <div className = 'disflex-paginator'>
          <span
            className = 'btnNumber ml-002'
            onClick={()=> this.handleBookFinancerAd (this.props.valueFinAd)}
          >
            Create Ad
          </span>
          
          <span
            className = 'btnNumber ml-002 bgColor-dodgerblue'
            onClick={this.props.handleModalClose}
          >
            cancel
          </span>
        </div>
        </>
    )
  }
  if(action === 'Renew'){
    return(
        <>
        <div >
          {balanceCreditProp >0 && (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Total Credit Available</b>
            </div>
            <div className='main flex-value-A'>
            <b>{balanceCreditProp}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
  
          <div >
          {creditRequireForFinancerAd >0 && (
          <div className='row'>
            <div className='side flex-value-B'>
            <b>Credit Required To Extend Validity</b>
            </div>
            <div className='main flex-value-A'>
            <b>{creditRequireForFinancerAd}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
          
          
          <br/>
          <div className = 'disflex-paginator'>
          <span
            className = 'btnNumber ml-002'
            onClick={()=> this.handleRenewFinancerAd (this.props.valueFinAd)}
          >
            Extend Validity
          </span>
          
          <span
            className = 'btnNumber ml-002 bgColor-dodgerblue'
            onClick={this.props.handleModalClose}
          >
            cancel
          </span>
          </div>
        </>
    )
  }
  
  }
    //*************** */
 const renderHeading=()=>{
   if(this.props.valueFinAd.action === 'Renew'){
     return(
      <div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
      <div className='heading-2'>
      <span  > Extend Validity For 30 Days</span>
      </div>
    
      </div>
     )
   }
   if(this.props.valueFinAd.action === 'Book'){
    return(
     <div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
 
     <div className='heading-2'>
     <span  > Book Financer Ad</span>
     </div>
   
     </div>
    )
  }

 }

    //*************** */
    return (
<div className = 'maindiv-768' >
        {/*  */}
{renderHeading()}

	<div className='card-attached border-radius-bottom border-sb pxy-10 mb-010'  >

  		{/*  */}
      
      {renderFieldAndButton()}
        {/*  */}
        </div>
      </div>

//@@@@@@@@@@@@@

    );
  }
}

export default FinancerAd
