import {
  FETCH_VIDEOS,
  FETCH_VIDEO,
  SEARCH_VIDEOS,
  FETCHING_VIDEOS,
} from '../actions/types';

const INITIAL_STATE = {
  fetching:true,
  videos: [],
  videosPRY: [],
  videosPRT: [],
  video: null,
  offset: 0,
  // limit: 10 
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_VIDEOS:
      return { ...state, ...action.payload };
    case FETCH_VIDEO:
      return { ...state, video: action.payload };
    
    case SEARCH_VIDEOS: 
      
      return { ...state, ...action.payload };
      case FETCHING_VIDEOS:
        return { ...state, fetching: action.payload };
    
    default:
      return state;
  }
};
