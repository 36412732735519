import axios from "axios";
import { toastr } from "react-redux-toastr";
//import startOfToday from "date-fns/start_of_today";
import {openModal, closeModal } from "./modalAction";

import {
  FETCH_REQRESI,
  SEARCH_REQRESIS,
  FETCHING_REQRESIS,
} from "./types";


//search  ReqResis

export const searchReqResis = (
  values,
  limit,
  offset
) => async dispatch => {
 

  try {
    const res = await axios.post("/api/ReqResiSearch", {
      values,
      limit,
      offset
    });

    dispatch({ type: SEARCH_REQRESIS, payload: res.data });
    dispatch({ type:FETCHING_REQRESIS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_ReqResiSearch");
    }
  
  }
};


//****************************
export const searchReqResisUnlocked = (
  id,
  values,
  limit,
  offset
) => async dispatch => {


  try {
    const res = await axios.post(`/api/ReqResiSearchUnlocked/${id}`, {
      values,
      limit,
      offset
    });

   
    dispatch({ type: SEARCH_REQRESIS, payload: res.data });
    dispatch({ type:FETCHING_REQRESIS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_ReqResiSearchUnlocked");
    }
  
  }
};


//fetch individual  MyReqResi
export const fetchMyReqResis = () => async dispatch => {
  try {
    const res = await axios.post(`/api/MyReqResis`);

    dispatch({ type: FETCH_REQRESIS, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_MyReqResi");
    }
  
  }
};

//fetch single ReqResi
export const fetchReqResi = id => async dispatch => {
  try {
    const res = await axios.get(`/api/ReqResiDetail/${id}`);
 
    dispatch({ type: FETCH_REQRESI, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_ReqResiDetail");
    }
  
  }
};

//delete ReqResi
export const deleteReqResi = (id) => async dispatch => {
  try {
    const res = await axios.delete(`/api/ReqResiDelete/${id}`);
    if(res.data.msg ==='success'){
      toastr.success('Requirement Deleted')
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_ReqResiDelete");
    }
  
  }
};
//update ReqResi
export const updateReqResi = (id, values) => async dispatch => {
  try {
    const res = await axios.put(`/api/ReqResiEdit/${id}`, values);
    if(res.data.msg ==='success'){
      toastr.success('Property Updated')
    }
    dispatch({ type: FETCH_REQRESI, payload: null });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_ReqResiEdit");
    }
  
  }
};
//#####################################################
//update PropResi to INACTIVE
export const updateReqResiToInactive = (id) => async dispatch => {
  try {
    const res = await axios.put(`/api/ReqResiInactive/${id}`);
    if(res.data.msg ==='success'){
      toastr.success('Requirement Deleted')
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_ReqResiInactive");
    }
  
  }
};



// CREATE REQRESIMENT
export const createReqResi = (values) => async dispatch => {
  try {
    
    const res = await axios.post(`/api/ReqResiCreate`, {
      values
    });

    if (res.data.msg ==='success') {
      toastr.success('ReqResi Created Successfully');
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_ReqResiCreate");
    }
  
  }
};
