import React, { Component } from 'react';
import {  Link } from "react-router-dom";
import { Icon,
  Confirm
 } from 'semantic-ui-react';
 import axios from 'axios';
 import { toastr } from "react-redux-toastr";
import { connect } from 'react-redux';
//import ReactPlayer from 'react-player'
import { closeModal } from '../../actions/modalAction';
import {fetchProjectVideos ,fetchPropertyVideos} from "../../actions/videoAction";
import { fetchAppParameter } from "../../actions/commonAction";

class ShowVideos extends Component {
  state = {
    path:'',
    open: false,
};
  componentDidMount = () =>{
    const id = this.props.id
    if (!this.props.parameter){
      this.props.fetchAppParameter()
        }
      
        if((this.props.imageOf === 'ProjectC') || (this.props.imageOf === 'ProjectR')){
          this.props.fetchPropertyVideos(id)
        }
        if((this.props.imageOf === 'PropertyC') || (this.props.imageOf === 'PropertyR')){
          this.props.fetchPropertyVideos(id)
          
        }
    
  } 
//**********************

show = (id,path) => {

  this.setState({ open: true, id:id, path:path });
};
//**********************

  handleConfirm = async () => {
    this.setState({ open: false});
    const  value = {path:this.state.path}
    const imageURL_F=this.state.path.substr(0, 23)

     try {

        const resImage = await axios.post(`${imageURL_F}/api/VideoDelete`, value);

        if(resImage.data.msg ==='success'){
           const id=this.state.id ;

        const res= await axios.put(`/api/VideoDelete`, {id:id});
        if(res.data.msg ==='success'){
          const Id=this.props.id
          if((this.props.imageOf === 'ProjectC') || (this.props.imageOf === 'ProjectR')){
            this.props.fetchPropertyVideos(Id)
          }
          if((this.props.imageOf === 'PropertyC') || (this.props.imageOf === 'PropertyR')){
            this.props.fetchPropertyVideos(Id)
            
          }
        toastr.success('Video Deleted Successfully')
        }
      }


    } catch (error) {
   
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }


  };

  handleCancel = () => this.setState({ result: "cancelled", open: false });
  //*********************
  
renderList=(item)=> {

  return (
    <div key={item.path}>
{/**/}
<div style={{background:'white',fontSize:'16px'}}>
<div style={{padding:'10px'}}>
{item.path}
</div>
<div style={{padding:'10px'}}>
{item.description}
</div>


</div>

{/**/}
<div className='disflex-paginator' style={{color:'white'}}>


<span className='icon2-button border1' 
onClick={this.props.closeModal}
> <Icon name='close' />
</span >
<span
onClick={() => this.show(item._id, item.path)}
className="icon-button"
>
<img className= 'yimg ' src='/deleteIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
</span>
<span
  onClick={this.props.closeModal}
  className="icon-button"
  >
    <Link to={`/activity/VideoEdit/${item._id}`} >
    <img className= 'yimg ' src='/editIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </Link>
  
  </span>


</div>
<br />



{/**/}


    </div>
    )
  }
  //********* */
    videos = () => {
    if((this.props.imageOf === 'ProjectC') || (this.props.imageOf === 'ProjectR')){
      return this.props.videosPRT
    }
    if((this.props.imageOf === 'PropertyC') || (this.props.imageOf === 'PropertyR')){
      return this.props.videosPRY
      
    }
    
    
    };
    //***** */
  render() {
    const { open,  } = this.state;

      const renderVideos = ()=>{
        if(this.props.videosPRY.length>0 || this.props.videosPRT.length>0){
          return (
            this.videos().map(this.renderList)
          )
        }else{
          return (
            <div className='card-noShow' >
             Oops ! No Video Available
            </div>
          )
        }
      }
            //***** */
    return (
      <div className='main-div' >
              <div style={{padding:'15px'}}>
      {/**/}
      <Confirm
         header='You Want To Delete !'
        size="mini"
        open={open}
        onCancel={this.handleCancel}
        onConfirm={() => this.handleConfirm()}
      />
      {/**/}
<div >

      {/**/}
      <div className='margin-auto-H smalldiv border-radius-3'  >

       <div  style={{display: 'flex',flex: '70%',justifyContent: 'center',}} >
       <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
       All Videos 
       </div>
       </div>

       <div
       style={{display: 'flex',flex: '30%',justifyContent: 'center',alignItems: 'center',}}
       onClick={this.props.closeModal} >
       <span className='icon-button border1' >Close</span >
       </div>

       </div>
       {/**/}
{/**/}
{renderVideos()}
        {/* {this.props.images !== null && this.props.images.imagePaths.sort().map(this.renderList)} */}


        {/**/}

        </div>
        </div>
              </div>
    );
  }
}

const mapState = state => ({
  state,
  videosPRT:state.videos.videosPRT,
  videosPRY:state.videos.videosPRY,
});
export default connect(
  mapState,
  {closeModal,fetchProjectVideos ,fetchPropertyVideos,
    fetchAppParameter}
)(ShowVideos);
