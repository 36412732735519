const cityO = ['Dwarka', 'Gurugram','Noida', 'Rohini'];
export const city = () => {
  return cityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const userGroupO = ['Builder'];
export const userGroupOption = () => {
  return userGroupO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const stateO = ['Delhi', 'Haryana', 'U P'];
export const state = () => {
  return stateO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const typeOfPropO = [
  'Flats',
  'Builder Floor',
  'Residential Land /Plot',
  'Office Space',
    'Shop',
    'Space in Shopping Mall',
    'Showroom',
    'Commercial Land /Plot'
]
export const typeOfProp = () => {
  return typeOfPropO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const typeOfDealingsO = [
  'Buy',
  'Sell',
  'Renting',
  'Leasing',
  'Consultancy',
  'Original Booking',
  'Interior'
];
export const typeOfDealings = () => {
  return typeOfDealingsO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const localityO = [
  'Sector-01',
  'Sector-02',
  'Sector-03',
  'Sector-04',
  'Sector-05',
  'Sector-06',
  'Sector-07',
  'Sector-08',
  'Sector-09',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18A',
  'Sector-18B',
  'Sector-19A',
  'Sector-19B',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

const companyStatusO = ['active', 'inactive'];
export const companyStatus = values => {
  return companyStatusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//@@@
export const imageNames = [
  'image01',
  'image02',
  'image03',
  'image04',
  'image05',
  'image06',
  'image07',
  'image08',
  'image09',
  'image10',
  'image11',
  'image12',
  'image13',
  'image14',
  'image15',
  'image16',
  'image17',
  'image18',
  'image19',
  'image20',
  'image21',
  'image22',
  'image23',
  'image24',
  'image25',
  'image26',
  'image27',
  'image28',
  'image29',
  'image30',
  'image31',
  'image32',
  'image33',
  'image34',
  'image35',
  'image36',
  'image37',
  'image38',
  'image39',
  'image40',
  'image41',
  'image42',
  'image43',
  'image44',
  'image45',
  'image46',
  'image47',
  'image48',
  'image49',
  'image50',
];