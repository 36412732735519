import axios from "axios";
import { toastr } from "react-redux-toastr";
import {openModal, closeModal } from "./modalAction";

import {
  FETCH_CREDITADDS,
  FETCH_CREDITADD,
  SEARCH_CREDITADDS,
  FETCHING_CREDITADDS,
} from "./types";

export const resetFetching = () => async dispatch => {
  dispatch({ type:FETCHING_CREDITADDS, payload: true });
};

//search  CreditAdds
export const searchCreditAdds = (
  values,
  limit,
  offset
) => async dispatch => {

  try {
    const res = await axios.post("/api/CreditAddSearch", {
      values,
      limit,
      offset
    });

    dispatch({ type: SEARCH_CREDITADDS, payload: res.data });
    dispatch({ type:FETCHING_CREDITADDS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetch all CreditAdds for one Dealer
export const fetchCreditAdds = (
  values,
  limit,
  offset) => async dispatch => {

  try {
 
    const res = await axios.post("/api/CreditAddList",{
      values,
      limit,
      offset
    } );

    dispatch({ type: FETCH_CREDITADDS, payload: res.data });
    dispatch({ type:FETCHING_CREDITADDS, payload: false });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again fetchCreditAdds");
  }
};

//fetch single CreditAdd
export const fetchCreditAdd = id => async dispatch => {
  try {
    const res = await axios.get(`/api/CreditAddDetail/${id}`);
   
    dispatch({ type: FETCH_CREDITADD, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//delete CreditAdd
export const deleteCreditAdd = (id) => async dispatch => {
  try {
    const res = await axios.delete(`/api/CreditAddDelete/${id}`);
    if (res.data.msg ==='success'){
      toastr.success('Credit Add Deleted Successfully ');
    }
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//update CreditAdd
export const updateCreditAdd = (id, values) => async dispatch => {
  try {
    const res = await axios.put(`/api/CreditAddEdit/${id}`, values);
    if (res.data.msg ==='success'){
      toastr.success('Credit Add Udated Successfully ');
      dispatch({ type: FETCH_CREDITADD, payload: null });
    }
    
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};


//########################################################################
// create CreditAdd
export const createCreditAdd = (id, values) => async dispatch => {
  try {
   
    const res = await axios.post(`/api/CreditAddCreate/${id}`, values);
    if (res.data.msg === 'success') {
    toastr.success('Credit Added Successfully ');
  }
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
export const resetCreditAdd = () => async dispatch => {
  try {
    
    dispatch({ type: FETCH_CREDITADD, payload: null });
  } catch (error) {
    
      toastr.error("Oops", "Something went wrong.Please Try Again"); 
  }
};
//************************************************************************
