import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import ShowImages from '../imageVideo/ShowImages';
import { closeModal } from '../../actions/modalAction';

const actions = { closeModal };

class ShowImageModal extends Component {
  render() {
    return (
      <Modal 
      //size="tiny" 
      open={true} 
      onClose={this.props.closeModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      style = {{
        //background:'transparent',
         backgroundColor:'black',
        margin:'0px',
        padding: '0px',
      }}
      >
       
        <Modal.Content 
        style = {{
        //background:'transparent',
         backgroundColor:'black',
        //opacity: 0.7,
        margin:'0px',
        padding: '0px',
      }}
       >
        <ShowImages 
            id = {this.props.id}
            imageOf = {this.props.imageOf}
            />
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(ShowImageModal);
