import axios from "axios";
import { toastr } from "react-redux-toastr";
//import { closeModal, openModal } from "./modalAction";

import {
 
  // appPARAMETER types
  FETCH_PROJECTNAMERESIS,
  FETCH_PROJECTNAMECOMS,
  FETCH_LOCALITYS
  
} from "./types";


// ProjectNameResi ACTION
//fetch all ProjectNameResis
export const fetchProjectNameResis = () => async dispatch => {
  try {
    const res = await axios.post(`/api/ProjectNameResiList`);
    
    dispatch({ type: FETCH_PROJECTNAMERESIS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

// create ProjectNameResi
export const createProjectNameResi = (values) => async () => {
  try {
    const res = await axios.post("/api/ProjectNameResiCreate", values);   
    if(res.data.msg === 'errorAlready'){
      toastr.error('ProjectNameResi Name Already Exist Use Another');
    }
  
      if(res.data.msg ==='success'){
        toastr.success('ProjectNameResi Created Successfully');
      }
     
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

// ProjectNameCom ACTION
//fetch all ProjectNameComs
export const fetchProjectNameComs = () => async dispatch => {
  try {
    const res = await axios.post(`/api/ProjectNameComList`);
    
    dispatch({ type: FETCH_PROJECTNAMECOMS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

// create ProjectNameCom
export const createProjectNameCom = (values) => async () => {
  try {
    const res = await axios.post("/api/ProjectNameComCreate", values);   
    if(res.data.msg === 'errorAlready'){
      toastr.error('ProjectNameCom Name Already Exist Use Another');
    }
  
      if(res.data.msg ==='success'){
        toastr.success('ProjectNameCom Created Successfully');
      }
     
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

// Locality ACTION
//fetch all Localitys
export const fetchLocalitys = () => async dispatch => {
  try {
    const res = await axios.post(`/api/LocalityList`);
    
    dispatch({ type: FETCH_LOCALITYS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

// create Locality
export const createLocality = (values) => async () => {
  try {
    const res = await axios.post("/api/LocalityCreate", values);   
    if(res.data.msg === 'errorAlready'){
      toastr.error('Locality Name Already Exist Use Another');
    }
  
      if(res.data.msg ==='success'){
        toastr.success('Locality Created Successfully');
      }
     
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
// //reset Employees
// export const resetEmployees = () => async dispatch => {
//   try {
//     dispatch({ type: FETCH_EMPLOYEES, payload: {employees: []} });
   
//   } catch (error) {
//     toastr.error("Oops", "Something went wrong.Please Try Again");
//   }
// };