import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import {fetchProjectNameComs,} from "../../actions/appParameterAction";
import { openModal } from "../../actions/modalAction"; 
import {
  Container, 
  Segment,
  Button,
  Icon,
  Input,
} from "semantic-ui-react";
import Confirm from "../common/Confirm_1";



class ProjectNameComList extends Component {
  state = {
    search: "",
    header:true,
    searchField:false,
    projectNameCom: "",
     open: false,
  };

  componentDidMount() {
    this.props.fetchProjectNameComs();
  }
  show = val => {
    this.setState({ open: true, projectNameCom: val });
  };
  
  handleConfirm = async () => {
    const values = {projectNameCom:this.state.projectNameCom};

    try{
    
      const res = await axios.post(`/api/ProjectNameComDelete`, values);

    if(res.data.msg ==='success'){
      this.props.fetchProjectNameComs() 
      this.setState({  open: false });
      toastr.success('Deleted Successfully')
    }
  }catch (error) {
    if(error.response.status==401){
      openModal('LoginModal') 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
}
  };
  handleCancel = () => this.setState({ open: false });

  renderList=(item)=> {
    const projectNameCom = item;

    const renderButton = () =>
      this.props.userA !== null &&
      (this.props.userA.userType === "admin") ? (
        <Button
          // basic

          inverted
          floated="right"
          color="red"
          type="button"
          onClick={() => this.show(projectNameCom)}
        >
          Delete
        </Button>
      ) : (
        <Button
          // basic
          disabled
          inverted
          floated="right"
          color="red"
          type="button"
          onClick={() => this.show(projectNameCom)}
        >
          Delete
        </Button>
      );
    return (
      <Segment key={projectNameCom}>
        <Button.Group size="mini" floated="right">

          
          {/* <div ><Button
            // basic
            // inverted
            floated="right"
            color="teal"
            type="button"
            as={Link}
            //target='_blank'
            to={`/company/EmployeeEdit/${_id}`}
          >
            Edit
          </Button></div> */}
          {renderButton()}

        </Button.Group>

        {/* <Divider fitted /> */}
        <div >
        {projectNameCom && (
        <div className='row'>
        <div className='side'>
        <b>Project Name</b>
        </div>
        <div className='main'>
        <b>{projectNameCom}</b>
        </div>

        </div>
        )}
        </div>

      </Segment>
    );
  }
//@@@@@@@@
showSearchField = () => {
  this.setState({header:false,searchField:true,});
};
showHeader = () => {
  this.setState({header:true,searchField:false,search: ""});
};
//^^^^^^^^^^^^^^^^^^^^^^^^^
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//@@@@@@@

//****************** */

  render() {
    const { open,  } = this.state;
    //@@@
const { search } = this.state;
const arrF = this.props.projectNameComs.filter(item => {
  return item.toLowerCase().indexOf(search.toLowerCase()) !== -1;
});
//@@@
//******************
const renderHeaderOrSearch =()=>{
  if(this.state.header===true){
    return(
      <React.Fragment>
      <div className='cashbook-H'>

      <div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
      <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
     {this.props.countC} Project Names Commercial
      </div>
      </div>

      <div
      style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
      onClick={this.showSearchField}
      >
          <span className='icon-button border1' > <Icon name='search' /></span >
        </div>


      </div>
      {/* <Segment attached >
     <div >
            {this.renderButtonGroup()}<span>{renderTotalReceivablePayableAmount()}</span>
           

          </div>
          </Segment> */}
      </React.Fragment>
    )
  }
  if(this.state.searchField===true){
    return(
      <React.Fragment>
<Segment attached>
<div className='disflex'>
{/**/}
<div
style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
onClick={this.showHeader}
>
<button

onClick={this.showHeader}

    className='back-btn'
  >
    <Icon name="arrow left" />

  </button>
  </div>

{/**/}
<div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >

<Input
 icon='search' placeholder='Search...'
  type="text"
  name="search"
  value={this.state.search}
  onChange={this.onChange}
/>

</div>




</div>

</Segment>
      </React.Fragment>
    )
  }
}
    //******************

    if (
      this.props.userA !== null &&
      (this.props.userA.userType === "admin")
    ) {
    return (
      <Container text>
      <Confirm
      open={open}
      onCancel={this.handleCancel}
      onConfirm={this.handleConfirm}
      />
        
        {/**/}
        {renderHeaderOrSearch()}
       {/**/}
       
        
        {arrF.sort().map(this.renderList)}
        {/* {this.props.projectNameComs.map(this.renderList)} */}
      </Container>
    );
  }
  return (
    <Container text>
      <Segment>
        <div style={{ color: "red", textAlign: "center" }}>
          <h2>You Are Not Authorised To View Project Names Commercial </h2>
        </div>
      </Segment>
    </Container>
  );
  }
  }



const mapStateToProps = ({ auth, appParameters }) => {

  return {
    countC: appParameters.countC,
    projectNameComs: appParameters.projectNameComs,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
  {fetchProjectNameComs,openModal}
)(ProjectNameComList);
