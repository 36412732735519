import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modalAction';
import PropResiSaleSearch from '../propertyResi/PropResiSaleSearch';
//import * as actions from '../../actions/companysAction';
const actions = { closeModal };

class SearchPropResiSaleModal extends Component {
  render() {
    return (
      <Modal  size='small' open={true} onClose={this.props.closeModal}>

        <Modal.Content className='border'>
          <Modal.Description>
            <PropResiSaleSearch />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(SearchPropResiSaleModal);
