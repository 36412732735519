import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import writtenNumber from 'written-number';
import {searchPropResiSales} from '../../actions/propresiAction';
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,  Header,  } from 'semantic-ui-react';
import NumberInput from "../form/NumberInput";
import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';
//import { projectName } from './DataProjectName';
import {
  //propertyType, 
  bedroom,
  bathroom,
  additionalRooms,
  furnishedStatus,

  floorNo,
  // locality,
  sorting
} from './Data';
import {
  carParkingCovered,
  carParkingOpen,
   entry,
} from './DataS';
import { 
  // projectResi, 
  locality,
 //  companyId
} from "../functions/DataFunctions";

const BuilderFloorSaleSearch = props => {
  const { maxPrice, minPrice } =
    props.state.form &&
    props.state.form.BuilderFloorSaleSearch !== undefined &&
    props.state.form.BuilderFloorSaleSearch.values !== undefined &&
    props.state.form.BuilderFloorSaleSearch.values;
  const { handleSubmit, pristine, reset, submitting } = props;

  const onSubmit = values => {
    props.searchPropResiSales(values);
    
  };

  return (
    <div className='border-formFH' >
        <div  className ='border-bottom-form'  >
        <Header
                color="blue"
               content="Search Builder Floor Available for Sale "
              />
        </div>
        <div className="card-form ">
          <div className=" label" >

          <Form style={{maxWidth:'500px',margin:'auto',}} onSubmit={handleSubmit(onSubmit)}>


          <Button
              color="teal"
              size="mini"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          <Button
            size="mini"
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <br /><br />
          <label htmlFor="">Sort By</label>
          <Field
            //search={true}
            name="sorting"
            type="text"
            component={SelectInput}
            options={sorting()}
            placeholder="Sort By"
          />

{/*
            <label htmlFor="">Property Type</label>
            <Field
              name="propertyTypeA"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />  */}
            <label htmlFor="">Bedroom</label>
            <Field
              name="bedroomA"
              placeholder="Bedroom"
              type="text"
              options={bedroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label htmlFor="">Bathroom</label>
            <Field
              name="bathroomA"
              placeholder="Bathroom"
              type="text"
              options={bathroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label htmlFor="">Additional Rooms</label>
            <Field
              name="additionalRoomsA"
              placeholder="Additional Rooms"
              type="text"
              options={additionalRooms()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label htmlFor="">Furnished Status</label>
            <Field
              name="furnishedStatusA"
              placeholder="Furnished Status"
              type="text"
              options={furnishedStatus()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label htmlFor="">
              Minimum Price{' '}
              <span>
                <b>
                  ({writtenNumber(minPrice, {
                    lang: 'enIndian'
                  }).toUpperCase()}
                  ){' '}
                </b>
              </span>
            </label>
            <Field
              name="minPrice"
              placeholder="Minimum Price"
              type="number"
              component={NumberInput}
            />
            <label htmlFor="">
              Maximum Price{' '}
              <span>
                <b>
                  ({writtenNumber(maxPrice, {
                    lang: 'enIndian'
                  }).toUpperCase()}
                  ){' '}
                </b>
              </span>
            </label>
            <Field
              name="maxPrice"
              placeholder="Maximum Price"
              type="number"
              component={NumberInput}
            />


            <label htmlFor="">Entry</label>
            <Field
              name="entryA"
              placeholder="Entry"
              type="text"
              options={entry()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label htmlFor="">Covered Car Parking</label>
            <Field
              name="carParkingCoveredA"
              placeholder="Covered Car Parking"
              type="text"
              options={carParkingCovered()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label htmlFor="">Open Car Parking</label>
            <Field
              name="carParkingOpenA"
              placeholder="Open Car Parking"
              type="text"
              options={carParkingOpen()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label htmlFor="">Floor No</label>
            <Field
              name="floorNoA"
              placeholder="Floor No"
              type="text"
              options={floorNo()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
{/*
            <label htmlFor="">Society/Apartment Name</label>
            <Field
              name="projectNameA"
              placeholder="Society/Apartment Name"
              type="text"
              options={projectResi(this.props.state.projectRs.projectRsForData)}
              search={true}
              multiple={true}
              component={SelectInput}
            />  */}

<label htmlFor="">Locality</label>
<Field
name="localityA"
placeholder="Locality"
type="text"
options={locality(this.props.state.appParameters.localitys)}
search={true}
multiple={true}
component={SelectInput}
/>
            <label htmlFor="">Property Created FROM</label>
            <Field
              name="from"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy "
              placeholder="Property Created FROM"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Property Created TO</label>
            <Field
              name="to"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy "
              placeholder="Property Created TO"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />


            <br />

            <Button
              color="blue"
              type="submit"
              disabled={pristine || submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
          </Form>
          <br/><br/><br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA,
  };
};
export default connect(
  mapStateToProps,
   {searchPropResiSales,closeModal  }
)(
  reduxForm({
    form: 'BuilderFloorSaleSearch', // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(BuilderFloorSaleSearch)
);
