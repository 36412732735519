import React from 'react';
const imageOfProjectO = [
    'Park',
    'Children Play Area',
    'Entrance View',
    'Front Elevation 1',
    'Front Elevation 2',
    'Side Elevation',
    'Paved Compound',
    'Car Parking',
    'Temple',
    'Security Room',
    'Community Hall'
];
export const imageOfProject = () => {
  return imageOfProjectO.map(value => ({ key: value, text: value, value: value }));
};
const imageOfPropertyO = [
  'Drawing Room',
  'Living Room',
  'Dining Room',
  'Kitchen',
  'Lobby',
  'Main Door',
  'Entrance View',
  'Overlooking View',
  'View From Balcony',
  'Master Bedroom',
  "Master Bedroom's Bathroom",
  'Bathroom 1',
  'Bedroom 1',
  'Bathroom 2',
  'Bedroom 2',
  'Bathroom 3',
  'Bedroom 3',
  'Bathroom 4',
  'Bedroom 4',
  'Bathroom 5',
  'Bedroom 5',
  'Bathroom 6',
  'Bedroom 6'
  
  
];
export const imageOfProperty = () => {
  return imageOfPropertyO.map(value => ({ key: value, text: value, value: value }));
};

const belongToO = ['Property', 'Project'];
export const belongToOption = () => {
  return belongToO.map(value => ({ key: value, text: value, value: value }));
};
