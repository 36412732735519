import React from "react";
import { connect } from "react-redux";
import { closeModal } from "../../actions/modalAction";
import {  Button,  Header,  } from "semantic-ui-react";


const PrivacyPolicy = props => {
  
  return (
    <div style={{maxWidth:'700px',margin:'auto'}} >
        <div  className ='border-bottom-form'  >
        <Header
                color="blue"
               content="BITS Cookie Policy "
              />
        </div>
        
        
        <React.Fragment>

        <p>Effective from 15th July, 2019.
        </p>
          <p>
At BITS, we believe in being transparent about how we collect and use data. This policy provides information about how and when we use cookies for these purposes. Capitalized terms used in this policy but not defined have the meaning set forth in our Privacy Policy, which also includes additional details about the collection and use of information at BITS.
</p>
          <h6>

What is a cookie?

</h6>
          <p>
Cookies are small text files sent by us to your computer or mobile device. They are unique to your account or your browser. Session-based cookies last only while your browser is open and are automatically deleted when you close your browser. Persistent cookies last until you or your browser deletes them or until they expire.
</p>
          
          <h6>
Does REA-CONNECT use cookies?
</h6>
          <p>
Yes. REA-CONNECT uses cookies and similar technologies like single-pixel gifs and web beacons. We use both session-based and persistent cookies. REA-CONNECT sets and accesses our own cookies on the domains operated by REA-CONNECT and its corporate affiliates (collectively, the “Sites”). In addition, we use third party cookies, like Google Analytics.
</p>
          <h6>
How is REA-CONNECT using cookies?
</h6>
          <p>
Some cookies are associated with your account and personal information in order to remember that you are logged in and which workspaces you are logged into. Other cookies are not tied to your account but are unique and allow us to carry out analytics and customization, among other similar things.
</p>
          <p>
Cookies can be used to recognize you when you visit a Site or use our Services, remember your preferences, and give you a personalized experience that’s consistent with your settings. Cookies also make your interactions faster and more secure.
</p>
          <h6>
Categories of Use and their Description
</h6>
          
          <h6>
Authentication
</h6>
          <p>
If you're signed in to our Services, cookies help us show you the right information and personalize your experience.
</p>
          <h6>
Security
</h6>
          <p>
We use cookies to enable and support our security features, and to help us detect malicious activity.
</p>
          <h6>
Preferences, features and services
</h6>
          <p>
Cookies can tell us which language you prefer and what your communications preferences are. They can help you fill out forms on our Sites more easily. They also provide you with features, insights, and customized content.
</p>
          <h6>
Marketing
</h6>
          <p>
We may use cookies to help us deliver marketing campaigns and track their performance (e.g., a user visited our Help Center and then made a purchase). Similarly, our partners may use cookies to provide us with information about your interactions with their services, but use of those third-party cookies would be subject to the service provider’s policies.
</p>
         
          <h6>
Performance, Analytics and Research
</h6>
          <p>
Cookies help us learn how well our Sites and Services perform. We also use cookies to understand, improve, and research products, features, and services, including to create logs and record when you access our Sites and Services from different devices, such as your work computer or your mobile device.
</p>
          <h6>
How are cookies used for advertising purposes?
</h6>
          <p>
Cookies and other ad technology such as beacons, pixels, and tags help us market more effectively to users that we and our partners believe may be interested in Agile CRM. They also help provide us with aggregated auditing, research, and reporting, and know when content has been shown to you.
</p>
          <h6>
What can you do if you don't want cookies to be set or want them to be removed, or if you want to opt out of interest-based targeting?
</h6>
          <p>
Some people prefer not to allow cookies, which is why most browsers give you the ability to manage cookies to suit you. In some browsers you can set up rules to manage cookies on a site-by-site basis, giving you more fine-grained control over your privacy. What this means is that you can disallow cookies from all sites except those that you trust.
</p>
          <p>
Browser manufacturers provide help pages relating to cookie management in their products. Please see below for more information.
</p>
          <p>
●     <a  target='_blank' rel="noopener noreferrer"
 href="https://support.google.com/chrome/answer/95647?hl=en-GB">Google Chrome</a> 
</p>
          <p>
●      <a  target='_blank' rel="noopener noreferrer"
 href="https://support.microsoft.com/en-us/kb/260971">Internet Explorer</a>
</p>
          <p>
●     <a  target='_blank' rel="noopener noreferrer"
 href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a> 
</p>
          <p>
●     <a  target='_blank' rel="noopener noreferrer"
  href="https://support.apple.com/kb/PH5042?locale=en_US">Safari (Desktop)</a> 
</p>
          <p>
●      <a  target='_blank' rel="noopener noreferrer"
 href="https://support.apple.com/en-us/HT201265">Safari (Mobile)</a>
</p>
          <p>
●     <a target='_blank' rel="noopener noreferrer"
href="http://support.google.com/ics/nexus/bin/answer.py?hl=en&amp;answer=2425067">Android Browser</a> 
</p>
          <p>
●     <a  target='_blank' rel="noopener noreferrer"
 href="http://www.opera.com/help">Opera</a> 
</p>
          <p>
●      <a  target='_blank' rel="noopener noreferrer"
 href="http://www.opera.com/help/mobile/android#privacy">Opera (Mobile)</a>
</p>
          <p>
For other browsers, please consult the documentation that your browser manufacturer provides.
</p>
          <p>
You may opt-out of third party cookies from Google Analytics on its website.
</p>
          <p>
You can opt out of interest-based targeting provided by participating ad servers through the Digital Advertising Alliance (http://youradchoices.com). In addition, on your iPhone, iPad or Android, you can change your device settings to control whether you see online interest-based ads.
</p>
          <p>
If you limit the ability of websites and applications to set cookies, you may worsen your overall user experience and/or lose the ability to access the services, since it will no longer be personalized to you. It may also stop you from saving customized settings, like login information.
</p>
          <h6>
Does REA-CONNECT respond to Do Not Track Signals?
</h6>
          <p>
Our Sites and Services do not collect personal information about your online activities over time and across third-party websites or online services. Therefore, “do not track” signals transmitted from web browsers do not apply to our Sites or Services, and we do not alter any of our data collection and use practices upon receipt of such a signal.
</p>
          

            </React.Fragment>

                           <Button
              color="color5"

              type="button"
              onClick={()=>props.closeModal()}

            >
             Got it
            </Button>


            </div>
  );
};

export default connect(
 null,
   {closeModal}
)(PrivacyPolicy)

