import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
//import writtenNumber from "written-number";

import {fetchContactReqsForDealer} from "../../actions/contactReqAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,  Header,  } from "semantic-ui-react";
// import TextInput from "../form/TextInput";
// import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";




const SearchContactReqsForDealer = props => {

  const { handleSubmit, pristine, reset, submitting } = props;

  const onSubmit = values => {
    props.fetchContactReqsForDealer(values);
  };
  return (
    <div className='border-formFH' >
        <div  className ='border-bottom-form'  >
        <Header
                color="blue"
               content="Search Contact Request Received"
              />
        </div>
        <div className="card-form ">
          <div className=" label" >

          <Form style={{maxWidth:'500px',margin:'auto',}} onSubmit={handleSubmit(onSubmit)}>


          <Button
              color="color5"
              size="mini"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          <Button
            size="mini"
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <br /><br />

{/* <label htmlFor=""> Ad Location For Search</label>
            <Field
              search={true}
              name="adLocationForSearchDealerA"
              type="text"
              component={SelectInput}
              multiple={true}
              options={adLocationForSearchDealer()}
              placeholder="Ad Location For Search "
            /> */}
            {/*  */}
            <label htmlFor="">Lower Date</label>
            <Field
              name="createdAtL"
              type="text"
              component={DateInput}
              placeholder= "In DD-MM-YYYY format Lower Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Higher Date</label>
            <Field
              name="createdAtH"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            



            <br />

            <Button
              color="olive"
              type="submit"
              disabled={pristine || submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {fetchContactReqsForDealer,closeModal}
)(
  reduxForm({
    form: "SearchContactReqsForDealer", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(SearchContactReqsForDealer)
);
