import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import axios from "axios";
import { toastr } from "react-redux-toastr";

import {fetchAppParameter,updateAppParameter} from "../../actions/optionAction";
import { withRouter } from "react-router-dom";

import {  Form, Button,   } from "semantic-ui-react";
import SelectInput from "../form/SelectInput";

//import TextInput from "../form/TextInput";




class AppParameterEdit extends Component {
  componentDidMount() {
    //const { id } = this.props.match.params;
    this.props.fetchAppParameter();
  }

  onSubmit = values => {
    //const { id } = this.props.match.params;
    this.props.updateAppParameter(values, this.props.history);
    //this.props.history.goBack()
  };
  handleChangeURL = async() => {
    //************
      try {
        const res = await axios.put(`/api/ChangeURL` );

        if(res.data.msg ==='success'){
          toastr.success('Updated Successfully')
      }
        }catch (error) {
         
       toastr.error("Oops", "Something went wrong.Please Try Again");
      }
    //**********
  }
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    
    const url = ()=>{
      return this.props.parameter !== undefined &&
      this.props.parameter !== null &&
      this.props.parameter.allURL.length >0
          && this.props.parameter.allURL.map( (value) =>({
        key:value,
        text:value,
        value:value
      })


      )
    }

    const urlOf = ()=>{
      return ['Company','Image','Video'].map( (value) =>({
        key:value,
        text:value,
        value:value
      })


      )
    }
    return (
      <div style={{maxWidth:'700px',margin:'auto'}}>
      {/**/}
      <div className='margin-auto-H' >
<div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
 {/*  */}
 Edit URL
{/*  */}
</div>
</div>
{/**/}

        <div className="card border ">
          <div className=" label" >

            <Form
              style={{ padding: 25 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >

             <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          <br /><br />
          <label >currentURL</label>
              <Field
                name="currentURL"
                type="text"
                options={url()}
                search={true}
                component={SelectInput}
                placeholder="currentURL"
              />
              <label >imageInfoURL</label>
              <Field
                name="imageInfoURL"
                type="text"
                options={url()}
                search={true}
                component={SelectInput}
                placeholder="imageInfoURL"
              />
              <label >imageProfileURL</label>
                <Field
                  name="imageProfileURL"
                  type="text"
                  options={url()}
                  search={true}
                  component={SelectInput}
                  placeholder="imageProfileURL"
                />
    
                <label >Change URL of</label>
                <Field
                  name="urlOf"
                  type="text"
                  options={urlOf()}
                  search={true}
                  component={SelectInput}
                  placeholder="imageProfileURL"
                />
                                


              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
              
            </Form>
            <Button
                color="blue"
                type="button"
                onClick = {this.handleChangeURL}
               // disabled={pristine || submitting}
              >
                Change URL
              </Button>
            <br/><br/><br/>
            </div>
            </div>
            </div>
    );

  }
}

export default connect(
  state => {
    

    return {parameter: state.commons.parameter, initialValues: state.commons.parameter, }; // pull initial values from common reducer
  },
  {fetchAppParameter,updateAppParameter}
)(
  reduxForm({
    form: "AppParameterEdit" // a unique identifier for this form
    // validate
  })(withRouter(AppParameterEdit))
);
