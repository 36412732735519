import {
  // appPARAMETER types
  FETCH_PROJECTNAMERESIS,
  FETCH_PROJECTNAMECOMS,
  FETCH_LOCALITYS
} from '../actions/types';

const INITIAL_STATE = {
  projectNameResis: [],
  projectNameComs: [],
  localitys: [],
  // countC,
  // countL,
  // countR,
  offset: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PROJECTNAMERESIS:
      return { ...state, ...action.payload };
      case FETCH_PROJECTNAMECOMS:
      return { ...state, ...action.payload };
      case FETCH_LOCALITYS:
      return { ...state, ...action.payload };
      
      default:
      return state;
  }
};
