import without from "lodash/without";
import {
  CREATE_DEALERSUGGESTEDS,
  FETCH_DEALERSUGGESTEDS,
  FETCH_DEALERSUGGESTED,
  //DELETE_DEALERSUGGESTED,
  UPDATE_DEALERSUGGESTED,
  SEARCH_DEALERSUGGESTEDS,
  DESELECT_DEALERSUGGESTED,
  SELECT_DEALERSUGGESTED,
  RESET_SELECTION_DEALERSUGGESTED,
  FETCHING_DEALERSUGGESTEDS,
} from '../actions/types';

const INITIAL_STATE = {
    fetching:true,
    selections: [],
    dealerSuggesteds: [],
    dealerSuggestedsForProp: [],
    dealerSuggestedsForProj: [],
    adByDealer: [],
    dealerSuggested: null,
    offsetDS: 0,
    offset: 0,
    limitDS: 10,
    limit: 10,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DEALERSUGGESTEDS:
      return { ...state, ...action.payload };
    case FETCH_DEALERSUGGESTED:
      return { ...state, dealerSuggested: action.payload };
    case CREATE_DEALERSUGGESTEDS:
      return {
        ...state,
        dealerSuggesteds: [...state.dealerSuggesteds, ...action.payload]
      };
    case UPDATE_DEALERSUGGESTED:
      return {
        ...state,
        dealerSuggested: { ...state.dealerSuggested, ...action.payload }
      };
    case SEARCH_DEALERSUGGESTEDS:
      
      return { ...state, ...action.payload };
      case FETCHING_DEALERSUGGESTEDS:
        return { ...state, fetching: action.payload };
        case SELECT_DEALERSUGGESTED:
    
          return { ...state, selections: [...state.selections, action.payload] };
        case DESELECT_DEALERSUGGESTED:
          return {
            ...state,
            selections: without(state.selections, action.payload)
          };
    
        case RESET_SELECTION_DEALERSUGGESTED:
          
          return { ...state, selections: [] };
    default:
      return state;
  }
};
