import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import LoginForModal from '../auth/LoginForModal';
import { closeModal } from '../../actions/modalAction';

const actions = { closeModal };

class LoginModalDealerSearch extends Component {
  render() {
    return (
      <Modal size="tiny" 
      open={true} 
      onClose={this.props.closeModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      >
       
        <Modal.Content className='border'>
          <Modal.Description>
            <LoginForModal />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(LoginModalDealerSearch);
