import React from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import {
  Image,
  Form,
  Header,
  Button,
  Icon
} from 'semantic-ui-react';
import Resizer from 'react-image-file-resizer';
import { toastr } from "react-redux-toastr";
import {fetchImagesOther,addFirstImageInfo} from "../../actions/imageAction";
import { fetchAppParameter } from "../../actions/commonAction";
import {  imageNames, } from "./Data";


class AddImage extends React.Component {
  state = {
    files: [],
    imageName: '',
    cropResult: null,
    image: {},
    imageURL: '',
  };
  componentDidMount() {
    const id = this.props.id;
    this.props.fetchImagesOther(id)
    if (!this.props.parameter){
      this.props.fetchAppParameter()
        }
  }
  //**************
  fileChangedHandler=(event)=> {
        var fileInput = false
        this.setState({files:event.target.files})
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
          Resizer.imageFileResizer(
                event.target.files[0],
                768,
                1280,
                'JPEG',
                90,
                0,
                uri => {

                    let imageUrl = URL.createObjectURL(uri);
                    this.setState({
                      cropResult: imageUrl,
                      image: uri
                    });

                },
                'blob'
            );
        }
    }

    // ****************
    handleChange = event => {
      this.setState({ imageName: event.target.value });
    };
    //************ */
  cancelImage = () => {
    this.setState({
      files: [],
      image: {},
      cropResult: null,
      cropResult_one: null,
    });
  };



  handleUploadImage = async ev => {
    ev.preventDefault();
    const imageURL=this.props.parameter !== null && this.props.parameter.imageInfoURL
    const imageURL_F =()=>{
      if(this.props.images!== null && this.props.images.imagePaths.length >0){
        return this.props.images.imagePaths[0].substr(0, 23)
      }else{
        return imageURL
      }
    }

    const data = new FormData();
    data.append('file', this.state.image);
    data.append('imageName', this.state.imageName);
    data.append('id', this.props.id);
    data.append('imageURL_F', imageURL_F());

     try {
      if(this.state.imageName){

       if(this.props.images=== null){
        

          const resImage = await axios.post(`${imageURL_F()}/api/ImageAddInfo`, data);
          if(resImage.data.msg ==='success'){

            const values = {
              path:resImage.data.path,
              id:this.props.id
            }

            this.props.addFirstImageInfo(values)
            this.cancelImage()
          }

      }
      //%%%
      if(this.props.images!== null){
        

      const resImage = await axios.post(`${imageURL_F()}/api/ImageAddInfo`, data);
if(resImage.data.msg ==='success'){

  if (
    this.props.images.imagePaths.filter(
    item => item.toString() === resImage.data.path
  ).length > 0 // path  already exists in the array
) {
  this.cancelImage()
   toastr.success('Image Added Successfully')
}
 //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 if (
  this.props.images.imagePaths.filter(
  item => item.toString() === resImage.data.path
).length < 1 // path  did not exists in the array
) {
//$$$$
const values = {
  path:resImage.data.path,
  id:this.props.id
}

const res= await axios.post(`/api/EditForImageInfoAdd`, values);

if(res.data.msg ==='success'){
  this.props.fetchImagesOther()
  this.cancelImage()
  // this.props.handleAddImageModalClose()
  toastr.success('Image Added Successfully')
}
//##
}


}


      }
//%%
    }else{
      toastr.error("Enter Page No.");
    }// end bracket for first if statement

    } catch (error) {

      toastr.error("Oops", "Something went wrong.Please Try Again_addimage");
    }


  };

  render() {
   
    const renderAddImageButton =()=>{
      if(this.state.files.length <1){
        return(
          <React.Fragment>
          <div>
    <label size="tiny" for="hidden-new-file" class="ui icon button">

    <span className="icon-size">
    <Icon  name="plus circle"/>
    </span><span className="icon-size">Add Image</span>

    </label>
    <input
    type="file" id="hidden-new-file" accept="image/*"
    style={{display: 'none'}}
    onChange={this.fileChangedHandler}
    />
    </div>
        </React.Fragment>
        )
      }
    }
    //*******************
    const renderPreview =()=>{
      if(this.state.files.length >0){
        return(
          <React.Fragment>
          <div
          //style={{padding:'15px'}}
          >
          <br/>
          <Header  color="teal" content="Preview and Upload" />
            <br/>
            <Image
            src={this.state.cropResult}/>

            </div>


            <br /><br />
      <div className='disflex-paginator'>
            <Button.Group>
              <Button
                //loading={loading}
                onClick={this.handleUploadImage}
                // style={{ width: '120px' }}
                positive
                icon="check"
                content="upload"
              />
              <Button
                size='mini'
                onClick={this.cancelImage}
                // style={{ width: '100px' }}
                icon="close"
                content="cancell"
              />
            </Button.Group>

        </div>
        </React.Fragment>
        )
      }
    }
    return (
      <div className='main-div' style={{backgroundColor:'white'}}>
              <div style={{padding:'15px'}}>
{/**/}

{/**/}
<div className='margin-auto-H  border-radius-3'  >
<div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{/*  */}
Add Info Images
{/*  */}
</div>
</div>
{/**/}

  <br/>

  {/**/}
  <br/>
  <div className='disflex-paginator'>
    <Form onSubmit={this.handleSubmit}>
      {/*<Header color="teal" sub content="Select Page No." />*/}

      <Form.Field >
      <label>Select Image No. </label>
      <select
        name="imageName"
        value={this.state.imageName}
        onChange={this.handleChange}
      >
      <option value="">Select Image No.  </option>
      {
      imageNames.map(value => (
        <option key={value} value={value}>{value} </option>

      ))
         };
      
      </select>
      </Form.Field>

    </Form>
    </div>
    <br />
{/**/}
<div className='disflex-paginator'>
{renderAddImageButton()}
              
</div>
{/**/}


              {renderPreview()}


</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => { 

  return {
    parameter:state.commons.parameter,
    images:state.images.imagesInfo,
    userA: state.auth.userA
  };
};

export default connect(
  mapStateToProps,
{ fetchImagesOther,addFirstImageInfo, fetchAppParameter}
)(AddImage);
