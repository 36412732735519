import React, { Component } from "react";
import { connect } from "react-redux";
//import { Grid } from "semantic-ui-react";
import { Switch, Route,  } from "react-router-dom";
import { showBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import PropComCreate from "./PropComCreate";

import PropComDetail from "./PropComDetail";
import PropComEdit from "./PropComEdit";

import PropComSaleSearchList from "./PropComSaleSearchList";
import PropComRentSearchList from "./PropComRentSearchList";

import Layout from "../layout/Layout";
 
class PropComDashboard extends Component {
  componentDidMount() {
    this.props.getUserForAuth();
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };
  render() {

  return (
<div>
<div className='maindiv'>


<Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
      
      <Route
        exact
        path="/propcom/PropComCreate"
        component={PropComCreate}
      />
      <Route
        exact
        path="/propcom/PropComDetail/:id"
        component={PropComDetail}
      />
      <Route
        exact
        path="/propcom/PropComEdit/:id"
        component={PropComEdit}
      />


      <Route
        exact
        path="/propcom/PropComSaleSearchList"
        component={PropComSaleSearchList}
      />

      <Route
        exact
        path="/propcom/PropComRentSearchList"
        component={PropComRentSearchList}
      />
      
    </Switch>
    </Layout >
    </div>

<div >
    <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
</div>

</div>
  );
}
}
// const mapStateToProps = state => ({
// auth: state.auth
// });


export default connect(
  // mapStateToProps,
  null,
  { showBackButton,getUserForAuth, logoutUser  }
)(PropComDashboard);
