import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import {searchCreditAdds} from "../../actions/creditAddAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,  } from "semantic-ui-react"; 

import DateInput from "../form/DateInput";





const CreditAddSearch = props => {
  const { handleSubmit,  reset, submitting } = props;

  const onSubmit = values => {
    const offsetD = 0
const { limitD} = props.state.statics;
    props.searchCreditAdds(values, limitD,offsetD);
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Credit Added
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form ">
          <div  >

          <Form 
          style={{ padding: '10px' }} 
          onSubmit={handleSubmit(onSubmit)}
          >

<Button
              color="teal"
              size='mini'
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
            
            <Button
            size='mini'
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            size='mini'
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />

          
            {/*  */}
            <label >Lower Added On Date</label>
            <Field
              name="startL"
              type="text"
              component={DateInput}
              placeholder= "In DD-MM-YYYY format Lower Added On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Higher Added On Date</label>
            <Field
              name="startH"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Added On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            {/* <label >Lower Expired On Date</label>
            <Field
              name="endL"
              type="text"
              component={DateInput}
              placeholder= " In DD-MM-YYYY format Lower Expired On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Higher Expired On Date</label>
            <Field
              name="endH"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Expired On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            /> */}



            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
  
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {searchCreditAdds,closeModal}
)(
  reduxForm({
    form: "CreditAddSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(CreditAddSearch)
);
