import axios from "axios";
import { toastr } from "react-redux-toastr";
import {openModal,} from "./modalAction";

import {
  FETCH_IMAGES,
  FETCH_IMAGE,
  SEARCH_IMAGES,
} from "./types";



//fetch PROPERTY Images

export const fetchPropertyImages = ( id) => async dispatch => {

  try {
    const res = await axios.post("/api/FetchPropertyImages", {id:id });

    
    dispatch({ type: SEARCH_IMAGES, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetch PROPERTY Images

export const fetchProjectImages = ( id) => async dispatch => {

  try {
    const res = await axios.post("/api/FetchProjectImages", {id:id });

     dispatch({ type: SEARCH_IMAGES, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetch single property/project images
export const fetchImages = (id) => async dispatch => {
  try {
    const res = await axios.post(`/api/FetchImages`, {id:id});

    dispatch({ type: FETCH_IMAGES, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//update image
export const updateImage = (id, values) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/ImageEdit/${id}`, values);
    
    if(res.data.msg==='success') {
      dispatch({ type: FETCH_IMAGE, payload: null });
      toastr.success('Image Updated Successfully');
      }
  } catch (error) {
    
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetch single Image
export const fetchImage = id => async dispatch => {
  try {
    const res = await axios.get(`/api/ImageDetail/${id}`);
    
    dispatch({ type: FETCH_IMAGE, payload: res.data });
  } catch (error) {
  
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//reset image
export const resetImage = () => async dispatch => {
  try {
    
    dispatch({ type: FETCH_IMAGE, payload: null });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//@@@@@@@@@@@@@@@@@@@@@@@
//fetch  Images

// export const fetchImages = () => async dispatch => {

//   try {
//     const res = await axios.post("/api/FetchImages");

//     dispatch({ type: SEARCH_IMAGES, payload: res.data });
//   } catch (error) {
//     toastr.error("Oops", "Something went wrong.Please Try Again");
//   }
// };
//fetch others Images

export const fetchImagesOther = (id) => async dispatch => {

  try {
    const res = await axios.post("/api/FetchImagesOther",{id});

    dispatch({ type: SEARCH_IMAGES, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//********
//delete ImageInfo
export const deleteImageInfo = (val) => async dispatch => {
  
  try {
    const res = await axios.put(`/api/EditForImageInfoDelete`,val);
    if(res.data.msg ==='success'){
    dispatch({ type: SEARCH_IMAGES, payload: res.data });
    toastr.success('Image Deleted Successfully')
    }

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//create Image document for company and add info image first time
export const addFirstImageInfo = (val) => async dispatch => {
  
  try {
    const res = await axios.post(`/api/AddFirstImageInfo`,val);
    if(res.data.msg ==='success'){
    dispatch({ type: SEARCH_IMAGES, payload: res.data });
    toastr.success('Image Added Successfully')
    }

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};







