import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {fetchCompanys} from "../../actions/companysAction";
import {updateCreditAdd,fetchCreditAdd,resetCreditAdd} from '../../actions/creditAddAction';
import { withRouter } from 'react-router-dom';
import writtenNumber from "written-number";
//import Layout from "../layout/Layout";
import { Segment, Form, Button,  Header,  } from 'semantic-ui-react';
//import TextInput from '../form/TextInput';
import TextArea from "../form/TextArea";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";
import NumberInput from "../form/NumberInput";

import {
  combineValidators,
  isRequired,
} from 'revalidate';

const validate = combineValidators({
  amountPaid:isRequired('Amount Paid') ,
  creditAdd:isRequired('Credit Add') ,
  creditType:isRequired('Credit Type') ,
  expiredOn:isRequired('ExpiredOn') ,
  paymentDetail:isRequired('Payment Detail') ,
});

 
class CreditAddEdit extends Component {

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchCreditAdd(id);
    this.props.fetchCompanys();
  }

  componentWillUnmount() {
    this.props.resetCreditAdd()
  }


   onSubmit = values => {
    const { id } = this.props.match.params;
    const creditAddInitialValue = this.props.state.creditAdds.creditAdd.creditAdd;
    const creditTypeInitialValue = this.props.state.creditAdds.creditAdd.creditType
    const valuesF = {...values,creditAddInitialValue,creditTypeInitialValue}
    this.props.updateCreditAdd(id, valuesF);
  };
  render(){
    const { handleSubmit, pristine, reset, submitting } = this.props;
    const { amountPaid,creditAdd} =
      this.props.state.form &&
      this.props.state.form.CreditAddEdit !== undefined &&
      this.props.state.form.CreditAddEdit.values !== undefined &&
      this.props.state.form.CreditAddEdit.values;
    const creditType = () => {
      return ['Credit For Property','Credit For Require'].map(value => ({
        key: value,
        text: value,
        value: value
      }));
    };

    return (
       <div style={{maxWidth:'700px',margin:'auto'}}>
      <Segment
          textAlign="center"
          attached="bottom"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Create CreditAdd"
              />
        </Segment>
        <div className="card ">
          <div className=" label" >

          <Form style={{ padding: 25 }} onSubmit={handleSubmit(this.onSubmit)}>
          <Button
            size="mini"
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <br /><br />
          <label >
          Amount Paid{' '}
                  <span>
                    <b>
                      ({writtenNumber(amountPaid, {
                        lang: 'enIndian'
                      }).toUpperCase()}
                      ){' '}
                    </b>
                  </span>
                </label>
            
            <Field
              name="amountPaid"
              type="number"
              component={NumberInput}
              placeholder="Amount Paid"
            />
            <label >
            CreditAdded{' '}
                  <span>
                    <b>
                      ({writtenNumber(creditAdd, {
                        lang: 'enIndian'
                      }).toUpperCase()}
                      ){' '}
                    </b>
                  </span>
                </label>
            
            <Field
              name="creditAdd"
              type="number"
              component={NumberInput}
              placeholder="credit Added"
            />
            
            <label >Credit Type</label>
            <Field
              name="creditType"
              type="text"
              component={SelectInput}
              options={creditType()}
              placeholder="Credit Type"
            />


            <label >Expired On</label>
            <Field
              name="expiredOn"
              type="text"
              component={DateInput}
              placeholder="Expired On"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Payment Detail</label>
                <Field
                  name="paymentDetail"
                  placeholder="Payment Detail"
                  type="text"
                  component={TextArea}
                  rows={3}
                />

            <label >Note</label>
                <Field
                  name="note"
                  placeholder="Note"
                  type="text"
                  component={TextArea}
                  rows={3}
                />

            <br />

            <Button
              color="olive"
              type="submit"
              disabled={pristine || submitting}
            >
              Submit
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
          </Form>
          <br/>
                   </div>
                   </div>
                   </div>


    );
}
};

export default connect(
  state => {
   
    return {
      state,
      companys: state.companys.companys,
      initialValues: state.creditAdds.creditAdd
     }; // pull initial values from account reducer
  },
  {fetchCompanys,updateCreditAdd,fetchCreditAdd,resetCreditAdd}
)(
  reduxForm({
    form: 'CreditAddEdit', // a unique identifier for this form
     validate
  })(withRouter(CreditAddEdit))
);
