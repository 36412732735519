


//#########
const propertyForO = [ '','Rent', 'PG Accommodation'];
export const propertyFor = values => {
  return propertyForO.map(value => ({ key: value, text: value, value: value }));
};

//#######
const bedroomO = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const bedroom = values => {
  return bedroomO.map(value => ({ key: value, text: value, value: value }));
};
//############
const bathroomO = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const bathroom = values => {
  return bathroomO.map(value => ({ key: value, text: value, value: value }));
};
//###################
const entryO = [

  'East',
  'West',
  'North',
  'South',
  'North - East',
  'North - West',
  'South - East',
  'South -West'
];
export const entry = values => {
  return entryO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//#######
