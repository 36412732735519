import React from "react";
import { connect } from "react-redux";
import { closeModal } from "../../actions/modalAction";
import {  Button,  Header,  } from "semantic-ui-react";

 
const TermsAndConditions = props => {
  
  return (
    <div style={{maxWidth:'700px',margin:'auto'}} >
        <div  className ='border-bottom-form'  >
        <Header
                color="blue"
               content="Terms & Conditions "
              />
        </div>
        
        <p>
                These Terms of Use ("Agreement") form a legal agreement
                between Bharat ITSYS. having its offices at xxxxxxxxxx
                xxxxxxxxxxxxxxxx xxxxxxxxxx xxxxxxxxxxxxxxx xxxxxxx
                (“BITS”) and You. This Agreement will govern Your access
                 to and use of the Services offered by BITS via this
                 website, applications (hereinafter called as “apps”)
                 and services (collectively, “BITS Products”). 
                  </p>
                  <p>By mere use of the Site, You shall be contracting with BITS, 
                    the owner of the Platform. These terms and conditions including 
                    the policies constitute your binding obligations, with BITS. 
                    </p>
                  <p>When You use any of the services provided by Us you will be 
                    subject to the rules, guidelines, policies, terms, and conditions 
                    applicable to such service, and they shall be deemed to be incorporated
                     into this Terms and shall be considered as part and parcel of these
                      Terms. </p>
                  <p> If You are
                  entering into this Agreement on behalf of a company,
                  organization or any legal entity (an “Entity”), You are
                  agreeing to this Agreement for that Entity and
                  representing to BITS that You have the authority to
                  bind such Entity to this Agreement.</p>
                  <p>If You do not agree to this Agreement, You must not
                  sign up for an account and shall not make use of any of the
                  Services available through this Site. By accessing or using the
                  Services, You acknowledge that you have read, understood and
                  agree to be bound by the terms of this Agreement. If you do not
                  agree, please do not access or use the Services.

                </p>
                <h6>Definition
                </h6>
                <p>- “Confidential Information” shall mean any information that is marked or otherwise designated as “Confidential” or is clearly by its nature confidential and is disclosed to You by BITS in connection with this Agreement.
                </p>
                <p>- “Data” shall mean the content created, shared or stored by You with BITS using the Services.
                </p>
                <p>- “Service(s)” shall mean the IoT software solution and its functionalities as developed, introduced and described in this Site or Mobile App by BITS from time to time.
                </p>
                <p>- “Site” shall mean the website from which You will sign up/ sign in to use the Services.
                </p>
                <p>- “App” shall mean the mobile application from which You will sign up/ sign in to use the Services.
                </p>
                <p>- “Third Party” or “Third Parties” shall mean the website from which You will sign up/ sign in to use the Services.
                </p>
                <p>- "You” or “Your”shall mean a person, whether natural or legal, acting in the course of a business, and who agrees to be bound by the terms contained in this Agreement.
                </p>
                <p>-"Agreement" shall mean and include the completed application/registration form, its attachment(s) and the terms and conditions stated herein. It shall be deemed to have been executed at New Delhi. </p>
                <p>-"Company": is defined as Credible IT Systems ("the Company") an existing Company under the Companies Act, 1956 and having its corporate office at xxxx
                  xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx, along with its unit for the Company's website Magicbricks.com.
                 </p>
                <p>-"Findrealestate.com" is defined as the internet website of the Company at www. findrealestate.com </p>
                <p>-"My Subscriptions" contains time to time information and description of the Services for the User provided by the Company in writing or contained in the website Findrealestate.com. </p>
                <p>-"Registration Data" is the database of all the particulars and information supplied by the User on initial application and subscription, including but without limiting to the User's name, telephone number, mailing address, account and email address. </p>
                <p>-"User" is defined as an individual or corporate subscriber for the Services and the signatory, whose particulars are contained in the application form and includes his successors and permitted assignees. "User" or "You" also includes any person who access or avail this Site of the Company for the purpose of hosting, publishing, sharing, transacting, displaying or uploading information or views and includes other persons jointly participating in using the Site of the Company. </p>
                <p>-Words referring to masculine include the feminine and the singular include the plural and vice versa as the context admits or requires; and Words importing persons includes individuals, bodies corporate and unincorporated. </p>
                <p>The term RERDA shall mean and include the Real Estate (Regulation and Development) Act, 2016 (http://mhupa.gov.in/User_Panel/UserView.aspx?TypeID=1535) as amended read with any rules or regulations that might be framed thereunder. </p>
                <p>The term LMA shall mean and include the Legal Metrology Act, 2009 (https://consumeraffairs.nic.in/acts-and-rules/legal-metrology/the-legal-metrology-act-2009) as amended read with any rules or regulations that might be framed thereunder. </p>
                <h6>Description
                </h6>
                <p>BITS offers Findrealestate.com a software as a service (SaaS) is  IoT software solutions. Company provides a number of internet-based services through its platform and shall include:
                  1. Posting User profile or listing for the purpose of sale/rental of property, and related property services etc.
                  2. Find a property,project,dealer,requirement through Findrealestate.com and its internet links.
                  3. Post advertisements on Findrealestate.com.
                  4. Send advertisements and promotional messages through emails and messages or any other means.

                 </p>
                 <h6>Modifications to the Terms
                 </h6>
                 <p>BITS may modify the terms of this Agreement from time to time.
                  In case of any significant changes which may affect Your
                  rights under this Agreement, You will be provided with a
                  advance notice of such changes by email to the email address
                  associated with Your account. You agree that such notification
                  shall constitute an adequate notice in this regard.
                  </p>
                  <p>All new features that augment or enhance the current Service,
                   including the release of new tools and resources, shall be
                   subject to the Terms of Service. Continued use of the Service
                   after any such changes shall constitute your consent to said
                   changes.

                   </p>
                   <h6>License & Restrictions
                   </h6>
                   <p>BITS grants to You a limited, non-exclusive and non-assignable
                   license to use the Services, for Your internal business purposes,
                    in accordance with the terms of this Agreement. If You wish to
                    use the Services in any other manner, say for example to
                    provide managed services, You should obtain express written
                    consent from BITS
                    </p>
                    <p>In addition to the other terms & conditions of this
                    Agreement, You agree to:
                    </p>
                    <p>Do not resell, transfer, lease, sub-license, modify ,duplicate, reproduce, 
                      or exploit any part of the Service without the explicit written permission 
                      of BITS.

                    </p>
                    <p>Do not permit any third parties to use the Services except where such access
                       is required for You to effectively use a communication or collaboration 
                       mechanism that forms part of the Services;
                    </p>
                    <p>Do not interfere with the Services or disrupt the integrity of any data 
                      contained therein;
                    </p>
                    <p>Do not violate any applicable law. You are responsible for your actions and 
                      the consequences of your actions.
                    </p>
                    <p>Do not use the Services in violation of this Agreement unless such use is 
                      expressly permitted by BITS.

                    </p>
                    <p>BITS may communicate with you via email regarding your account, updates, 
                      news, and other issues related to your account. You automatically get subscribed
                       to our mailing lists and newsletters. You can choose to opt out from receiving
                        emails.
                    </p>
                    <p>You are responsible for all content posted and activity that occurs under your
                       account (even if the content is user generated) - be it in the live chat, help
                        desk, phone or social.
                    </p>
                    <p>We have a soft limit on all our plans and should we believe that you are
                       abusing the service, we may temporarily suspend your account and will seek 
                       your cooperation in this regard.
                    </p>
                    <p>We reserve the rights to accept or refuse to any potential client. We have the
                       sole discretion on whether to allow you to register, renew, change plan, or use
                        our services.
                    </p>
                    <p>Your use of the Service, including any content, information, or functionality
                       contained within it, is provided "as is" and "as available" with no 
                       representations or warranties of any kind, either expressed or implied, 
                       including but not limited to, the implied warranties of merchantability, 
                       fitness for a particular purpose, and non-infringement. You assume total 
                       responsibility and risk for your use of this Service.
                    </p>
                    <p>You understand that the Service can be used for transmission of your Content,
                       and that during processing your Content may be transferred unencrypted over 
                       the internet.
                    </p>
                    <p>You understand that BITS uses third party vendors and hosting partners 
                      to provide necessary hardware, software, information, networking, storage,
                       and related technology to run the service.
                    </p>
                    <p>You may not use the service to store, host, or send unsolicited email (spam),
                       chats or SMS messages. BITS products and services are Anti-Spam compliant and
                        does not authorize or permit spam to be sent out via the automation service 
                        by you. If there is evidence of spam, your services might be suspended without 
                        notice. Accidental spam must immediately be reported to BITS to prevent 
                        suspension.
                    </p>
                    <p>You may not use the service to transmit any viruses, worms, malicious content
                       or engaging in hate speech, advocating violence against others.
                    </p>
                    <p>You may not engage in activity that is false or misleading (e.g. attempting to 
                      ask for money under false pretenses or impersonating someone else).
                    </p>
                    <p>You may not infringe upon the legal rights of others (e.g. unauthorized 
                      sharing of copyrighted music, photographs and other content).
                    </p>
                    <p>You may not engage in activity that violates the privacy of others.
                    </p>
                    <p> You may not circumvent, disable or otherwise interfere with the security 
                      related features of the Software, 
                      Products or BITS Websites or features that prevent or restrict 
                      the use of any content.
                    </p>
                    <p>BITS makes no warranties regarding (i) your ability to use the Service, 
                      (ii) your satisfaction with the Service, (iii) that the Service will be 
                      available at all times, uninterrupted, and error-free (iv), the accuracy of 
                      mathematical calculations performed by the Service, and (v) that bugs or errors 
                      in the Service will be corrected.
                    </p>
                    <p>BITS, its affiliates and its sponsors are neither responsible
                       nor liable for any direct, indirect, incidental, consequential,
                        special, exemplary, punitive, or other damages arising out of 
                        or relating in any way to your use of the Service. Your sole 
                        remedy for dissatisfaction with the Service is to stop using 
                        the Service.
                    </p>
                    <p> 	You shall not print, download, duplicate or otherwise copy, delete, 
                      vary or amend or use any data or personal information posted by any User 
                      on Site except such data and information which is posted by yourself/itself.
                      </p>
                    <p>	You shall not use the Service for any unlawful and fraudulent purpose 
                      including without limitation criminal purposes. 
                      </p>
                    <p>	The Service shall not be used to send or receive any message, which is 
                      offensive on moral, religious, racial or political grounds or of abusive, 
                      indecent, obscene, defamatory or menacing nature. 
                      </p>
                    <p> You are prohibited from postings any information or content on Site, which 
                      directly or indirectly cause any threat or/and harassment or/and annoyance 
                      or/and anxiety or/and any other inconvenience of whatsoever nature.
                      </p>
                    <p> •	You shall not infringe intellectual property rights of any person/party 
                      and/or retain information in any computer system or otherwise with an intention 
                      to do so.
                      </p>
                    <p> •	You agrees not to make use of anyone else's information other than 
                      as necessary to complete any transactions in which you are involved.
                      </p>
                    <p> •	You shall not violate, or attempt to violate the security of the Site 
                      and/or any web sites linked to Findrealestate.com or gain un-authorized access
                       any information regarded as private by other User(s) or persons, including but
                        without limitation to accessing data and information not intended for them or
                         logging onto a server or account which the User is not authorized to access,
                          attempting to probe, scan or test the vulnerability of a system or network 
                          or attempting to breach security or authentication.
                          </p>
                    <p> •	In the event that You breaches any of the above mentioned covenants, the
                       Company shall have the right to delete any material relating to the violations. 
                       The Company reserves the unilateral right to suspend or/and deactivate the User's
                        access to the Site Service and/or any other related facility in case of
                         violation of terms of use. In addition to the right to indemnity available
                          to the Company, the Company shall have the right to recourse to any legal
                           remedy against the User to recover the loss suffered by the Company and 
                           the harm caused to the reputation of the Company, due to such violation 
                           by the User.
                           </p>
                    <p> •	You shall avail Services on Findrealestate.com or any other related site for
                       lawful purposes alone. Transmission or/and distribution or/and storage of 
                       material or/and conduct in violation of any applicable local or/and state 
                       or/and Central or/and foreign law or/and regulation is prohibited. This 
                       includes without limitation any unauthorized use of material protected by
                        patent or/and copyright or/and trademark or/and other intellectual property
                         right, material that is obscene or/and defamatory or/and libelous or/and 
                         constitutes an illegal threat, or/and violates rights of privacy or publicity,
                          or/and violates export control laws. The User may use the information on our
                           site only to the extent necessary to facilitate the related transactions.
                           </p>
                    <p> •	You shall not use any "deep-link", "page-scrape", "robot", "spider" or other
                       automatic device, program, algorithm or methodology, or any similar or 
                       equivalent manual process, to access, acquire, copy or monitor any portion 
                       of the Site or any Content, or in any way reproduce or circumvent the 
                       navigational structure or presentation of the Site or any Content, to 
                       obtain or attempt to obtain any materials, documents or information 
                       through any means not otherwise made available through the Platform. 
                       We reserve Our right to bar any such activity.
                       </p>
                    <p> •	You shall not attempt to gain unauthorized access to any portion or 
                      feature of the Platform, or any other systems or networks connected to 
                      the Platform or to any server, computer, network, or to any of the services
                       offered on or through the Platform, by hacking, password "mining" or any
                        other illegitimate means.
                        </p>
                    <p> •	You shall not engage in advertising to, or solicitation of, other Users of 
                      the Platform to buy or sell any services, including, but not limited to, 
                      services related to that being displayed on the Site or related to us.
                      </p>
                    <p> •	The Content posted does not necessarily reflect Company views. In no event
                       shall Company assume or have any responsibility or liability for any Content
                        posted or for any claims, damages or losses resulting from use of Content 
                        and/or appearance of Content on the Site. You hereby represent and warrant 
                        that You have all necessary rights in and to all Content which You provide 
                        and all information it contains and that such Content shall not infringe any
                         proprietary or other rights of third parties or contain any libelous, 
                         tortuous, or otherwise unlawful information.
                         </p>
                    <p>If any provision of the Terms of Service is held invalid or otherwise 
                      unenforceable, the enforceability of the remaining provisions shall not be 
                      impaired thereby.
                      </p>
                    <p>•	You agree that You shall not host, display, upload, modify, publish, 
                      transmit, update or share any information on the Site, that-Belongs to 
                      another person and to which you do not have any right . Is grossly harmful,
                       harassing, blasphemous defamatory, obscene, pornographic, paedophilic, 
                       libelous, invasive of another's privacy, hateful, or racially, ethnically 
                       objectionable, disparaging, relating or encouraging money laundering or 
                       gambling, or otherwise unlawful in any manner whatever. Harm minors in any 
                       way. Infringes any patent, trademark, copyright or other proprietary rights.
                        Violates any law for the time being in force. Deceives or misleads the 
                        addressee about the origin of such messages or communicates any information
                         which is grossly offensive or menacing in nature.  Impersonate another 
                         person. Contains software viruses or any other computer code, files or 
                         programs designed to interrupt, destroy or limit the functionality of any 
                         computer resource. Threatens the unity, integrity, defence, security or 
                         sovereignty of India, friendly relations with foreign states, or public 
                         order or causes incitement to the commission of any cognisable offence or 
                         prevents investigation of any offence or is 
                         insulting any other nation. </p>
                   
                    
                    <p>BITS may, but have no obligation to, remove Content and Accounts containing 
                      Content that we determine in our sole discretion are unlawful, offensive, 
                      threatening, libelous, defamatory, pornographic, obscene, or otherwise 
                      objectionable or violates any party's intellectual property or these Terms of 
                      Service.
                    </p>
                    <p> Any Content and/ or comment/information uploaded by User/You on the Site which is non-compliant 
                      with The Information Technology Act, 2000, Rules and regulations, guidelines 
                      made thereunder, user agreement and privacy policy, as amended/re-enacted from
                       time to time, for access or usage of Company's Services/Site/computer resource, 
                       the Company has the right to immediately terminate the access or usage rights of 
                       the User to the said Services and Site and remove/disable all information 
                       including the non-compliant information. Furthermore, the Company shall have the
                        right to take recourse to such remedies as would be available to the Company 
                        under the applicable laws.</p>
                    <p>•	If any provision of the Terms of Service is held invalid or
                       otherwise unenforceable, the enforceability of the remaining 
                       provisions shall not be impaired thereby.
                    </p>
                    <p>The Terms of Service sets forth the entire understanding between you and BITS as to the Service, 
                      and supersedes any prior agreements between you and BITS (including, but
                       not limited to, prior versions of the Terms of Service).
                    </p>
                    <h6>Your obligations
                    </h6>
                    <p>You hereby represent and warrant to the Company that you are at least eighteen (18) years of age or above and are capable of entering, performing and adhering to these Terms. While individuals under the age of 18 may utilize the Service of the site, they shall do so only with the involvement & guidance of their parents and / or legal guardians, under such Parent /Legal guardian's registered account. You agree to register prior to uploading any content and / or comment and any other use or services of this site and provide your details including but not limited to complete name, age, email address, residential address, and contact number. </p>
                    <p>You need to sign up for a user account by providing your
                name, official email address and/ or official phone number
                to use the Services. You agree to: a) provide Facilio with
                true, accurate, current and complete information about You
                while signing up for the Services; and b) maintain and
                promptly update this information during Your course of use
                of the Services.
                </p>
                <p>You are responsible for keeping and maintaining Your
                login credentials strictly confidential and maintaining the
                security of your account and password. BITS shall not be
                held responsible for any unauthorized access to Your account
                 arising from Your failure to keep Your login credentials safe
                 and secure and will not be liable for any loss or damage from
                 your failure to comply with this security obligation.
                </p>
                <p>You  agrees to immediately notify the Company of any unauthorized use / breach of his/its password or account and ensure to exit from the account at the end of each session. </p>
                <p>•	You agrees that any data entered on the Site will be subject to mandatory verification process by the Company. </p>
                <p>•	Any and all licenses, permits, consents, approvals and intellectual property or other rights as may be required for using the Service shall be obtained by you at your own cost. </p>
                <p>•	You ensure compliance with all notices or instructions given by the Company from time to time to enable the use of the Service. </p>
                <p>•	You understands and agrees that the you are responsible for all applicable taxes and for all costs that are incurred in using the Site service(s). </p>
                <p> •	You shall be solely responsible for all information retrieved, stored and transmitted by you.</p>
                <p> •	You shall promptly make the payment to the Company towards the Subscription Fees as and when it becomes payable.</p>
                <p> •	You shall be responsible for the set-up or configuration of your equipment for access to the Services.</p>
                <p>•	You hereby declares that you are fully aware that the online advertisements placed with Findrealestate.com shall be reflected after 48 hours and agrees to the stipulated 48 hours processing. </p>
                <p> •	You agrees that any data entered into herein can and shall be saved, used and commercially exploited by BITS as deemed fit by them.</p>
                <p>•	You shall give out to the Company a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable right (through multiple tiers) to exercise all copyright and publicity rights, in any existing or future media, known or unknown, over the material or User Data displayed in your listings. For the purpose of this Agreement, "User Data" shall mean all information (if any) submitted by the User on Findrealestate.com with the exception of trading data, credit card numbers, checking account numbers, etc. "Individually Identifiable User Data" shall mean that subset of "User Data" which can be reasonably used to identify a specific individual such as their name, address, phone number, etc. The User Data shall be deemed to be the property Findrealestate.com. The User shall take all reasonable efforts to ensure that it is accurate and complete and not misleading in any. </p>
                <p> •	You will indemnify BITS for any action or claim committed/made by any third party resulting from any information posted on the Site by the User or/and anybody else on his behalf and account.</p>
                <p> •	You are solely responsible for any data entered on the Site. The User who has entered the data is fully responsible for any wrong data entered and shall be liable for any and all action(s) taken by third party (whether civil/criminal). The Company however shall endeavour to use the best industry practice, of weeding out all wrong data/ false data entered by the User and undertakes to withdraw all such data within reasonable time after verifying the complaint.</p>
                <p>•	User of the website agrees to indemnify and keep BITS indemnified from any wrong/false data entered into and hereby warrants and covenants that all data entered into the website is true and correct and belongs exclusively to him/it and not to any other third party. </p>
                

                <h6>Discontinuation or Modification to Services
                </h6>
                <p>	BITS are constantly improving the Software and Products and may change them at any time. Additionally BITS may stop providing the Software and Products (or portions thereof) including (without limitation) that it is no longer feasible for us to provide it, the technology advances, customer feedback indicates a change is needed, external issues arise that make it imprudent or impractical to continue, or any reason, you may need to use an upgraded version of the Software. If you do not agree with any changes to Software and Products you may terminate your relationship with BITS. You may need to upgrade to a new version in order to enjoy the benefit of certain Products. In addition, you acknowledge and agree that certain Products may be subject to usage limits or geographical restrictions, which may vary from time to time. </p>
                <p> •	The Company reserves the unilateral right to add to /and/or change and/or delete and/or end the Service at any time with or without notice to the User.</p>
                <p> •	There shall be no liability on behalf of the Company to the User or any third party in case the Company exercises its unilateral right to modify or discontinue the Service.</p>
                <h6>Maintenance
                </h6>
                <p>The Company may at its sole discretion and without assigning any reason whatsoever at any time deactivate or/and suspend the Services and/or the User's access to Findrealestate.com (as the case may be) without notice to carry out system maintenance or/and upgrading or/and testing or/and repairs or/and other related work. Without prejudice to any other provisions of this Agreement, the Company shall not be liable for any loss or/and damage or/and costs or/and expense that the User may suffer or incur, and no fees or/and charges payable by the User to the Company shall be deducted or refunded or rebated, as a result of such deactivation or/and suspension. </p>
                <h6>Fees and Payment Terms
                </h6>
                <p>You agree to pay to BITS  any fees with  all applicable taxes
                and expenses determined by BITS at the time of signing up for the
                 Services Or after the free trial period ends if you want to
                 continues to use our services
                 </p>
                 <p>BITS reserve the right to modify the fees for the Services
                 periodically. Prices of all Services, including but not limited
                 to subscription plan fees of the Service, are subject to change
                 without notice from us. BITS shall not be liable to you or to
                 any third party for any modification, price change.
                 </p>
                 <h6>Proprietary Rights
                 </h6>
                 <p>All proprietary rights, including intellectual property rights in the Services and any documentation related thereto are the exclusive property of Facilio. Facilio does not grant to You or anyone acting for You any right or title to such proprietary rights, or the permission to use such proprietary rights, except for the limited purposes expressly provided for in this Agreement. No part of this Agreement shall be construed as transferring any element of such proprietary right to You.
                 </p>
                 <h6>Data Ownership and Use
                 </h6>
                 <p>You grant to BITS an irrevocable, perpetual, non-exclusive, transferable, and a worldwide license to copy, store, use, retain, transmit, scan, reformat, edit, translate, excerpt, adapt and distribute the content created by You, solely for the purpose of providing the Services to you. Except for the right expressly granted herein, you retain entire ownership on the data created or stored by You using BITS Services.
                 </p>
                 <p>You hereby agrees and irrevocably authorizes that the Company has the right to all copyright and/or know-how and/or any other related intellectual property rights to the Services of Magicbricks.com including listings, details of Users, and any information otherwise made available to User in the Service, shall become and remain the sole and exclusive property of the Company and the User shall have no claims to the same. In the event the User has contributed any content to Magicbricks.com in any manner whatsoever, all intellectual property rights to the same shall become the absolute property of the Company, including all intellectual property rights and the User shall have no right or claim over the same, in any manner whatsoever. In the event that the User during the term of his Agreement or any time thereafter, uses such intellectual property in any other website or related activity, the same shall be considered as an infringement of the intellectual property rights of the Company and the Company shall have the right to take recourse to whatever legal remedial action is required, in the given facts and circumstances, the costs and peril of which will lie at the end of the User. </p>
                 <p>You hereby agrees and irrevocably authorizes the Company to retain all data or/and information supplied by you while using the Service to remain at Magicbricks.com for the exclusive use of the Company in accordance with service agreement with you, notwithstanding any termination of the Agreement or suspension of the Service to you herein. Anything contrary to the above, unless specifically put down in writing, following the termination or suspension of the Service to you, all such data and information shall remain in the Company's property, records and databases as the exclusive property of the Company, for all times to come. </p>
                 <p>•	By entering mobile number on Findrealestate.com while registering for an account you gives consent to Company and its partners/vendors and sub-partners/sub-vendors to send alerts, contact details, promotional SMS and promotional calls to the mobile number entered by you, irrespective of whether the same is on the DNC list or any other such data base. In case you does not want to receive these messages on your mobile number, you shall not submit your mobile number with Magicbricks.com. </p>

                 <h6>  Confidentiality
                 </h6>
                 <p>You agree (i) to hold BITS’s Confidential Information in
                 strict confidence, using at least the same degree of care with
                 which You protect Your own confidential or proprietary
                 information; (ii) not to use such Confidential Information for
                 any purpose outside the scope of this Agreement; and (iii) not
                 to disclose such Confidential Information to third parties not a
                 uthorized by BITS to receive such Confidential Information.
                 </p>
                 <p>These obligations shall survive for a period of five (5)
                 years from the date of termination of this Agreement.
                 </p>
                 <p> •	For the purpose of this Agreement and attachments thereto and all renewals, "Confidential Information" means all financial, commercial, technical, operational, staff, management and other information, data and know-how relating to the Project/property or to a party (the "Disclosing Party" herein the Company ) or any other members of the Disclosing Party's group of companies ( including, without limitation, as to products and services, assets, customers, date and database, suppliers or employees), which may be supplied to or may otherwise come into the possession of the other (the " Receiving Party ", herein the User ), whether orally or in writing or in any other form, and which is confidential or proprietary in nature or otherwise expressed by the Disclosing Party or by any of its Associates to be confidential, and not generally available to the public.</p>
                 <p> •	The Receiving Party shall keep confidential and secret and not disclose to any third party the Confidential Information nor any part of it, except to any of the Receiving Party's Associates, if required and upon prior permission in writing from the Disclosing Party. The Receiving Party agrees to take all possible precautions with regard to protecting confidential information from any third party and shall ensure that all its Associates to whom such disclosure e is made will act in accordance with the terms of this Agreement as if each of them were a party to this Agreement, and if required obtain a written statement from each of its employees/associates having access to such Proprietary Information undertaking to abide by the confidentiality conditions. All Proprietary Information shall be kept separate and exclusive and at the usual place of business (or residence as the case may be) of the Receiving Party.</p>
                 <p>•	Further no use, reproduction, transformation or storage of the Proprietary Information shall be made by the User without the prior written permission of the Company, except where required to be disclosed pursuant to any applicable law or legal process issued by any court or the rules of any competent regulatory body. </p>
                 <p> •	On request of the Disclosing Party made at any time, including at the time of termination of this Agreement, the Receiving Party shall deliver back to the Disclosing Party all original documents, records, data and other material in the possession, custody or control of the Receiving Party that bear or incorporate any part of the Proprietary Information. The obligations of confidentiality set out in this Agreement shall continue to be in force notwithstanding termination of this Agreement for any reason whatsoever.</p>
                 <p> •	All information and data submitted by the User shall become the property of the Company. However all such information shall be kept strictly confidential and the Company shall not, subject to "Violation of Terms & Conditions" clause, release any such data and information to any third party without the prior consent of the User.</p>
                 <p> •	The User has access to only his/its own data and information stored in the database of Magicbricks.com (subject to prior confirmation of identity) and nothing more. The User may edit or amend such data and information from time to time.</p>
                 <p>•	All confidential information (including name, e-mail address etc.) voluntarily revealed by the User in chat and bulletin board areas, is done at the sole discretion and risk of the User. If such information, collected by a third party is misused or results in unsolicited messages from such third parties, then such actions are beyond the use beyond the control and liability of Company and the company accepts no responsibility or liability whatsoever for such actions. </p>
                 

                 <h6>Indemnity
                 </h6>
                 <p>You agree to indemnify BITS from and against all claims,
                 actions and proceedings arising out of or resulting from and
                 against any losses, damages, fines and expenses (including
                   attorney's fees and costs) arising out of or relating to any
                   claims that You have used the Services (i) in violation of a
                   third party's rights, (ii) in violation of any law, (iii) in
                   violations of any provisions of this Agreement, or (iv) any
                   other claim related to your use of the Services, except where
                   such use is authorized by BITS. This indemnification includes,
                    without limitation, liability relating to copyright
                    infringement, defamation, invasion of privacy, trade mark
                    infringement and breach of this Agreement.

                    </p>
                    <h6>Warranty & Disclaimer
                    </h6>
                    <p> BITS warrants that the Services will perform in accordance with the documentation provided for this Service. Except for the warranties explicitly provided under this Agreement, BITS shall offer the Services on an "as is" or/and on an "as available"  basis without warranties of any kind including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement of intellectual property.
                    </p>

                    <p> 
                    •	The Company makes no warranty that the Services shall meet User's requirements, that the Services shall be uninterrupted or/and timely or/and secure or/and error free.
                    </p>
                     <p>
•	This Site is vulnerable to data corruption, interception, tampering, viruses as well as delivery errors and we do not accept liability for any consequence that may arise therefrom. We may need to make the Site unavailable with or without notice to carry out maintenance or upgrade work. We accept no liability for any interruption or loss of Service. Further the Company does not warrant that any of the websites linked to Magicbricks.com be free of any operational hazards or error nor that it will be free of any virus or/and worm or/and any other harmful components.
</p>
                     <p>
•	There are no express representations and warranties, whether express or implied, made by the Company regarding the accuracy, reliability and/or quality of any information transmitted and/or obtained through the use of the Services of Magicbricks.com. The User understands and agrees that any information or/and material and/or goods or/and services obtained through this Site is done at User's own discretion and risk and that User shall be solely responsible for any damage/s or/and cost or/and any other consequence/s resulting from any transaction. No advice or or/any information, whether oral or/and written, obtained by the User from Magicbricks.com or/and through or/and from the Service shall create any warranty not expressly stated herein. In case the there is any loss of information, caused due to any reason, whether as a result of any disruption of service, suspension and/or termination of the Service, the Company shall not be liable in any way for the same. Cancellations and alterations shall be effected only on the receipt of application regarding the same in writing.
</p>
<p>                    
•	The Company shall not be liable for any disclosure of information concerning the User's account and/or particulars nor for any error and/or omissions and/or inaccuracy with regard to information so disclosed. In addition the Company shall further not be liable for any loss or damages sustained by reason of such disclosure, whether intentionally or inadvertently. All information are accepted in good faith and Company accept no responsibility whatsoever regarding the bonafide of the User, nor can any interviews be granted or/and correspondence entered into regarding any advertisement published.
</p>
<p>
•	Once a advertisement is displayed on Our Site, We do not guarantee or make warranties that there would be satisfactory response or any response at all.
</p>
<p>
•	The Company is not liable or responsible for the quality or any misrepresentation or any liability or issue arising out of the Services availed by the User from third party service providers on or beyond the platform of Magicbricks.com. For any third party product or service which customer/user will buy through Magicbricks.com, the Customers/Users of Services shall take full responsibility to deal with third parties respective vendors at their own risk, cost and liability.
</p>
<p>
•	Property descriptions and other information provided on Our Site are intended for information and marketing purposes and, whilst displayed in good faith, we will not in any circumstances accept responsibility for their accuracy. It is the responsibility of prospective buyers/tenants to satisfy themselves as to the accuracy of any property descriptions displayed and the responsibility of agents/sellers/brokers to ensure the accuracy and integrity of property descriptions provided on Our Site and in any property particulars.
</p>
<p>
•	Any value estimates provided on Our Site are intended for general interest and information purposes only and should not be relied upon for any commercial transaction or similar use. These estimates are based on publically available information which may be inaccurate or incomplete, and typical factors in certain locations. They will not take account of any factors which are unknown to us and should only be used as a general estimate. None of the information available on Our Site is intended to be a substitute for independent professional advice and users are recommended to seek advice from suitably qualified professionals such as surveyors and solicitors if relevant to their particular circumstances. We shall not be liable for any losses suffered as a result of relying on our value estimates.
</p>
<p>
•	Company will not be liable for any time difference arising between a message released through a gateway/ server (provided to Magicbricks.com) and such message finally reaching the User from the concerned service provider.
</p>
<p>
•	The User shall ensure that while using the Service, all prevailing and applicable laws, rules and regulations, directly or indirectly for the use of systems, service or equipment shall at all times, be strictly complied with by the User and the Company shall not be liable in any manner whatsoever for default of any nature regarding the same, by the User.
</p>
<p>
•	In case a visitor to the portal is desirous of conducting a project site visit of the property he/it is interested in, it shall be the sole liability and duty of the Advertiser to arrange for such project site visits. The Company shall not be liable in any manner whatsoever, towards any liability and/or costs, arising out of such site visits including but not limited to non-fulfilment by the Advertiser in this regard.
</p>
<p>
•	The Platinum Listing is listed for a period of two weeks only from the day the Order is activated. Company shall not be liable for any delay arising from the User's end in conversion of their property listing to Platinum Listing, resulting in the Platinum Ad listing not being showcased for the complete period of two weeks. This package cannot be transferred, adjusted or reimbursed.
</p>
<p>
•	Company has no intention of violating any intellectual property or ancillary rights. If there is any violation, we request that the same be promptly brought to our attention.
</p>
<p>

•	It is not the Company's policy to exercise any kind of supervisory or editorial control over and/or edit and/or amend any data and/or contents of any e-mails and/or posting of any information that may be inserted or/and made available or transmitted to a third party in or through Magicbricks.com and the User acknowledges the same. The User acknowledges and agrees that the Company has absolute discretion to refuse and/or suspend or/and terminate and/or delete and/or amend any artwork, materials and/or information and/or content of any data and/or information and/or posting so as, in the sole opinion of the Company, to comply with the prevailing legal framework and/or moral obligations as placed on the Company and in order to avoid infringing any third party's rights and/or any other rules and/or standards and/or codes of practices that may be applicable to the posting or Magicbricks.com and/or the internet.
</p>
<p>
•	The Company is not involved in any transaction between any parties who are using Our Site. There are risks, which the User assumes when dealing with people who might be acting under false pretences and the same shall be borne by the User. The Site is a venue only and do not screen or/and censor or/and otherwise control the listings offered to other Users, nor does Company screen or/and censor or/and otherwise control the Users of its service. Company cannot and does not control the behaviour of the participants on this site. We cannot control whether or not Users of Magicbricks.com will complete the transactions they describe on Our Site. It is extremely important that the User takes care throughout his/its dealings with third party(s) and/or Users on this Site. Company does not accept or/and assume responsibility for the content or/and context of the User comment.
</p>
<p>

•	THE COMPANY'S TOTAL LIABILITY UNDER THIS AGREEMENT (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE SHALL BE LIMITED TO THE TOTAL AMOUNT OF FEES OR/AND CHARGES PAID BY THE USER TO THE COMPANY'S SITE OR THE PERIOD IMMEDIATELY PRECEDING TWO (2) MONTHS PRIOR TO THE INCIDENT GIVING RISE TO THE RELEVANT CLAIM.
</p>
<p>
•	Company shall not be responsible for the advice, views and suggestions provided by the expert. The information contained herein should be used for reference only. Before relying on any such advice, please do an independent assessment at your end considering your specific requirements and the parameters you have in mind. Magicbricks.com will not be responsible for any claims arising out of the use of any information displayed herein.
</p>
<p>
•	Magicbricks Realty Services Limited is only an intermediary offering its platform to advertise properties of Seller for a Customer/Buyer/User coming on its Website and is not and cannot be a party to or privy to or control in any manner any transactions between the Seller and the Customer/Buyer/User. All the offers and discounts on this Website have been extended by various Builder(s)/Developer(s) who have advertised their products. Magicbricks is only communicating the offers and not selling or rendering any of those products or services. Company is not responsible for any non-performance or breach of any contract entered into between Customer/Buyer/User and Sellers. Company cannot and does not guarantee that the concerned Customer/Buyer/User and/or Sellers will perform any transaction. At no time shall Company hold any right, title or interest over the products/services nor shall Company have any obligations or liabilities in respect of such contract entered into between Customer/Buyer/User and Sellers. It neither warrants nor is it making any representations with respect to offer(s) made on the site. Magicbricks Realty Services Limited shall neither be responsible nor liable to mediate or resolve any disputes or disagreements between the Customer/Buyer/User and the Seller and both Seller and Customer/Buyer/User shall settle all such disputes without involving Magicbricks Realty Services Limited in any manner.
</p>
<p>
•	If there is any dispute between the Users/participants on this Site, it is agreed upon by the Users/participants that Company has no obligation whatsoever to be involved in any such dispute/s. In the event that the User has a dispute with one or more User/s or/and participant/s, the User hereby undertakes not to make any claims or/and demands or/and damages (actual or/and consequential) of every kind or/and nature or/and known or/and unknown or/and suspected or/and unsuspected or/and disclosed or/and undisclosed, arising out of or/and in any way related to such disputes and/or our service against the company or/and their officers or/and employees or/and agents or/and successors.
</p>
<p>
•	Magicbricks has endeavoured to ascertain the requirement of RERA registration. The advertiser is hereby obligated to provide RERA registration number for promoting a RERA registered project in terms of Real Estate (Regulation & Development) Act, 2016. Magicbricks is a platform for advertisement and does not vouch for the project or the details provided in the advertisement.
</p>



                    <h6>Limitation of Liability
                    </h6>
                    <p>In no event BITS including affiliates and their respective
                    owners, directors, officers, agents, shareholders and partners
                     shall be liable for any loss or damage whatsoever including
                     without limitation direct, indirect, punitive, incidental and
                      consequential damages, lost profits, or damages resulting from
                       the use or inability to use the Website whether based on
                       warranty, contract, tort or in any other means or action.
                BITS including affiliates and their respective owners, directors,
                 officers, agents, shareholders and partners aggregate and total
                  liability (whether in contract, tort, negligence, under an
                    indemnity, or by statute or otherwise) shall not exceed INR
                     5,000/- (Rupees Five Thousand) or the last payment made by
                     the user; whichever is lesser, subject to applicable law and
                      awarded by the competent court.
                      </p>
                      <h6>  No waiver for breaches
                      </h6>
                      <p>If we do not act in relation to a breach of the
                      Agreement by you, that should not be construed as a
                      waiver of any of our rights to act in relation to that
                      breach or any later breach by you.

                      </p>
                      <h6>Arbitration
                      </h6>
                      <p>Any controversy or claim arising out of or relating to
                      this Agreement shall be settled by binding arbitration in
                      accordance with the rules of the Judicial Arbitration and
                       Mediation Services. Any such controversy or claim shall
                       be arbitrated on an individual basis, and shall not be
                       consolidated in any arbitration with any claim or
                       controversy of any other party. The decision of the
                       arbitrator shall be final and un-appealable. The
                       arbitration shall be conducted in the NCT of DELHI,
                       India. If a dispute arises regarding these terms of use,
                        the laws of the Republic of India will apply and shall
                        be subject to the exclusive jurisdiction of courts in
                        NCT of DELHI, India.

                        </p>
                        <h6>Termination
                        </h6>
                        <p>You may terminate Your account, with or without cause,
                         by requesting closure of your User Account, ceasing to
                         use the Software, Products and/or REA-CONNECT Websites
                          and mobile app. You are solely responsible for properly
                           canceling your account. Please ensure that the
                           cancelation requests are made only through the email
                           associated with Your account.
                           </p>
                           <p>BITS does not offer any refund amount or payment in any circumstances
                           </p>
                           <p>BITS may terminate Your account  if You fail to make payment for the Services or if You are in breach of any  term in this Agreement and fail to cure within given time.
                           </p>
                           <p>BITS may immediately terminate Your account if You violate any applicable law or use the Services in violation of this Agreement unless such use is expressly permitted by BITS
                           </p>
                           <p>  BITS, in its sole discretion, has the right to suspend or terminate your account and refuse any and all current or future use of the Service, or any other BITS service, for any reason, at any time. Such termination of the Service will result in the deactivation or deletion of your Account or access to your Account, and the forfeiture and relinquishment of all content in your Account. BITS reserve the right to refuse service to anyone for any reason at any time.

                           </p>
                           <p>Once your account is canceled or terminated, all your account information will be permanently deleted.
                           </p>
                           <h6>  End of Terms
                           </h6>
                           <p>Please write to us at legal@bits.com if you have questions or clarifications regarding this Agreement.
                           </p>


                           <Button
              color="color5"

              type="button"
              onClick={()=>props.closeModal()}

            >
             Got it
            </Button>


            </div>
  );
};

export default connect(
 null,
   {closeModal}
)(TermsAndConditions)

