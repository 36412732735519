import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { openModal } from "../../actions/modalAction";
import {createCompany} from "../../actions/companysAction";
import { withRouter } from "react-router-dom";

import { Segment, Form, Button,  Header,  Checkbox,Popup, } from "semantic-ui-react";
import TextInput from "../form/TextInput";
import TextArea from "../form/TextArea";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";

import { city, state, locality,userGroupOption } from "./Data";
import {
  composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {isValidEmail,isValidPhone,isValidPassword} from "../validator/revalidator";
var passwordValidator = require('password-validator');

const validate = combineValidators({
//************user */

userId:composeValidators(
    isRequired('User Mobile as user ID'),
    isValidPhone({})

  )(),
  uemail:composeValidators(
    isRequired('User Email'),
    isValidEmail({})

  )(),
  password:composeValidators(
    isRequired('Password'),
    isValidPassword({})

  )(),
  uphone2:isValidPhone({}), 
  userGroup:isRequired('Register As') ,
  uname:isRequired('User Name') ,
 // password:isRequired('Password') ,
  password2:isRequired('Confirm Password') ,
  //*********company */
  mobileCP1:isValidPhone({}),
  mobileCP2:isValidPhone({}),
  cemail:isValidEmail({}),
  emailCP1:isValidEmail({}),
  emailCP2:isValidEmail({}),
 
  cid:isRequired('Company ID') ,
  cname:isRequired('Company Name') ,
  address:isRequired('Address') ,
  locality:isRequired('Locality') ,
  city:isRequired('City/District') ,
  
});



// Create a schema
var schema = new passwordValidator();

// Add properties to it
schema
.is().min(8)                                    // Minimum length 8
.is().max(16)                                  // Maximum length 16
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().digits()                               // Must have digits
.has().symbols()                                // Must have symbols 
.has().not().spaces()                           // Should not have spaces
.is().not().oneOf(['Passw0rd', 'Password123']); // Blacklist these values

  class CompanyCreate extends Component {
    state = {
      showP:false,
      modalOpen: false,
      activeSubmit:false,
    };
    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })
   onSubmit = values => {
    this.props.createCompany(values, this.props.history);
    this.props.history.goBack()
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  toggle = () => this.setState({ showP: !this.state.showP })
  toggleSubmit = () => this.setState({ activeSubmit: !this.state.activeSubmit })
  //****************** */
  handleTermsAndConditionsModal = () => {
    this.props.openModal("TermsAndConditionsModal");
  };
  //*************** */
  render(){
    const showPass = this.state.showP ? "text" : "password"

    const { handleSubmit, pristine, reset, submitting } = this.props;

    const { userGroup,password,password2} =
      this.props.state.form &&
      this.props.state.form.CreateCompanyForm !== undefined &&
      this.props.state.form.CreateCompanyForm.values !== undefined &&
      this.props.state.form.CreateCompanyForm.values;

      const renderMatchPassword =()=>{
        if(password && password2){
          
            if(password !== password2){
             return (<div style={{ color:'red'}} >
              <b>Password do not match</b> 
             </div>)
            }
          

        }
      }
//************************* */
// const renderRequire=()=>{
//   if(password){
//     return (<span 
//       style={{color:'red'}} >
      
//       <b>Require</b>
//       </span>)
//   }
// }
const renderPasswordError = () => {
 if(password) return schema.validate(password, { list: true }).map(item => {
  return (
    <span 
    style={{color:'red'}}
    key={item}>
    {" "}
    <b>{item} ,</b>
    </span>
  );
  });
};




     
//************************ */
const renderCompanyField = ()=>{
  
    return (
     < React.Fragment>
<Header  color="teal" content="Company's Details" />
            <label>Company's Name</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="cname"
              type="text"
              component={TextInput}
              placeholder="Company's Name"
            />
            <label>Company ID</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="cid"
              type="text"
              component={TextInput}
              placeholder="like @asd147# or @asd147 or asd147"
            />
            <label>Company's Phone1</label>
            <Field
              name="cmobile1"
              type="text"
              component={TextInput}
              placeholder="Company's Phone1"
            />
            <label>Company's Phone2</label>
            <Field
              name="cmobile2"
              type="text"
              component={TextInput}
              placeholder="Company's Phone2"
            />
            <label>Company's Email</label>
            <Field
              name="cemail"
              type="text"
              component={TextInput}
              placeholder="Company's Email"
            />
            <label>Company's Website</label>
            <Field
              name="website"
              type="text"
              component={TextInput}
              placeholder="Company's Website"
            />
            <label>Company's RERA No.</label>
            <Field
              name="reraNo"
              type="text"
              component={TextInput}
              placeholder="Enter RERA Number"
            />
            <label>Operating Since</label>
            <Field
              name="operatingSince"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Operating Since"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            
            
            <label>Address</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="address"
              type="text"
              component={TextInput}
              placeholder="Address"
            />
            <label >Locality</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="locality"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              component={SelectInput}
            />
            <label>City/District</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              //search={true}
              name="city"
              type="text"
              component={SelectInput}
              options={city()}
              placeholder="City/District"
            />
            <label>State</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              //search={true}
              name="state"
              type="text"
              component={SelectInput}
              options={state()}
              placeholder="State"
            />
            <label>Pincode</label>
            <Field
              name="pincode"
              type="text"
              component={TextInput}
              placeholder="Pincode"
            />
            <label >Location On Map</label>
            <Field
              name="locationOnMap"
              type="text"
              component={TextInput}
              placeholder="Location On Map"
            />
            <label>Tell us about your Company</label>
            <Field
              name="description"
              type="text"
              component={TextArea}
              rows={3}
              placeholder="Tell us about your Company"
            />
            <Header  color="teal" content="Contact Persons Details" />
            <label>First Contact person's Name</label>
            <Field
              name="nameCP1"
              type="text"
              component={TextInput}
              placeholder="First Contact person's Name"
            />
            <label>First Contact person's Mobile</label>
            <Field
              name="mobileCP1"
              type="text"
              component={TextInput}
              placeholder="Enter Ten Digits Mobile Number"
            />
            <label>First Contact person's Email</label>
            <Field
              name="emailCP1"
              type="text"
              component={TextInput}
              placeholder="First Contact person's Email"
            />
            <label>First Contact person's Designation</label>
            <Field
              name="designationCP1"
              type="text"
              component={TextInput}
              placeholder="First Contact person's Designation"
            />
            <label>Second Contact person's Name</label>
            <Field
              name="nameCP2"
              type="text"
              component={TextInput}
              placeholder="Second Contact person's Name"
            />
            <label>Second Contact person's Mobile</label>
            <Field
              name="mobileCP2"
              type="text"
              component={TextInput}
              placeholder="Enter Ten Digits Mobile Number"
            />
            <label>Second Contact person's Email</label>
            <Field
              name="emailCP2"
              type="text"
              component={TextInput}
              placeholder="Second Contact person's Email"
            />
            <label>Second Contact person's Designation</label>
            <Field
              name="designationCP2"
              type="text"
              component={TextInput}
              placeholder="Second Contact person's Designation"
            />

     </React.Fragment>
    )
  }
  




      //*************************** */
  return (

    <div style={{marginTop:'50px'}}>  
<div style={{maxWidth:'700px',margin:'auto'}}>

      <br/>

      <Segment
          textAlign="center"
          attached
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Register"
              />
        </Segment>
        <div className="card-form ">
          <div className=" label" >

          <Form style={{ padding: 25 }} onSubmit={handleSubmit(this.onSubmit)}>

          <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          <Button
            size="mini"
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <br /><br />
          <div>(All field marked with <span style={{color:'red'}}><b>*</b></span> are required)
          </div>
          {renderCompanyField()}
          <Header  color="teal" content="User's Details" />

          
            <label>Register As</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              //search={true}
              name="userGroup"
              type="text"
              component={SelectInput}
              options={userGroupOption()}
              placeholder="Register As"
            />
            <label>User's Name</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="uname"
              type="text"
              component={TextInput}
              placeholder="First Name Last Name"
            />
            <label>User's Mobile as user ID</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="userId"
              type="text"
              component={TextInput}
              placeholder="Enter Ten Digits Mobile Number"
            />
            <label>User's Mobile2</label>
            <Field
              name="uphone2"
              type="text"
              component={TextInput}
              placeholder="Enter Ten Digits Mobile Number"
            />
            <label>User's Email</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="uemail"
              type="email"
              component={TextInput}
              placeholder="User's Email"
            />
            <label>Password</label>{' '}<span style={{color:'red'}}><b>*</b></span>{' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Password Must have Minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
            </Popup>{' '} {renderPasswordError()}
            <Field
            //onBlur=
              name="password"
              type={showPass}
              component={TextInput}
              placeholder="Password"
            /> 
           
            
            <label>Confirm Password</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="password2"
              type={showPass}
              //type="password"
              component={TextInput}
              placeholder="Confirm Password"
            />
            <Checkbox
              //name="showP"
              //type="checkbox"
              label='Show Password'
              onChange={this.toggle}
              checked={this.state.showP}
              />
{renderMatchPassword()}

            

            <br />
     {/* <div style={{marginTop:'15px',marginBottom:'15px'}}>
     <b> By Signup you agree to
     <a
    className='format-term-a'
onClick={this.handleTermsAndConditionsModal}
>Terms & Conditions
</a> and allow FindProp & affiliates to contact you </b>

       </div>  */}
             {/*  */}
             <div style={{marginTop:'15px',marginBottom:'15px'}}>
     <b> By Signup you agree to
     <span
    className='format-term-a'
onClick={this.handleTermsAndConditionsModal}
>
  Terms & Conditions
</span> and allow FindProp & affiliates to contact you </b>
<div>
<Checkbox
//name="showP"
//type="checkbox"
label='I agree'
onChange={this.toggleSubmit}
checked={this.state.activeSubmit}
/>
</div>

       </div>
             {/*  */}

            <Button
              color="olive"
              type="submit"
              disabled={!this.state.activeSubmit}
            >
              Signup
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
          </Form>
          
              <br/> <br/> <br/>
            </div>
            </div>
            </div>
            {/*  */}
            </div>

  );
}
}
const mapStateToProps = state => {
  return {
    state,
  };
};
export default connect(
  mapStateToProps,
  {createCompany, openModal}
)(
  reduxForm({
    form: "CreateCompanyForm",   // a unique identifier for this form
    destroyOnUnmount: false,
     validate
  })(withRouter(CompanyCreate))
);
