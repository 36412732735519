import {
  SEARCH_BUILDERS,
  FETCH_BUILDERS,
  FETCH_BUILDER,
  FETCHING_BUILDERS,
} from '../actions/types';

const INITIAL_STATE = {
  city:'', 
  fetching:true,
  builders: [],
  buildersForData: [],
  builder: null,
  offset: 0,
  limit: 10,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_BUILDERS:
      
      return { ...state, ...action.payload };

    case FETCH_BUILDERS:
      
      return { ...state, ...action.payload };
    
    case FETCH_BUILDER:
      
      return { ...state, builder: action.payload };
    
        case   FETCHING_BUILDERS:
          return { ...state, fetching: action.payload };
    default:
      return state;
  }
};
