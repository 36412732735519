import axios from "axios";
import { toastr } from "react-redux-toastr";

import {openModal, } from "./modalAction";

import {
  FETCH_VIDEOS,
  FETCH_VIDEO,
  SEARCH_VIDEOS,
  FETCHING_VIDEOS,
} from "./types";



//fetch PROPERTY Videos

export const fetchPropertyVideos = ( id) => async dispatch => {

  try {
    const res = await axios.post("/api/FetchPropertyVideos", {id:id });

    dispatch({ type: SEARCH_VIDEOS, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetch PROPERTY Videos

export const fetchProjectVideos = ( id) => async dispatch => {

  try {
    const res = await axios.post("/api/FetchProjectVideos", {id:id });

  
    dispatch({ type: SEARCH_VIDEOS, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};


// create Video
export const createVideo = (values) => async () => {
  try {

    const res = await axios.post("/api/VideoCreate", {
      values
    });
    if (res.data.msg === 'success') {
  
      toastr.success('Created Successfully');
      
    }
     
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//update image
export const updateVideo = (id, values) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/VideoEdit/${id}`, values);
    
    if(res.data.msg==='success') {
      dispatch({ type: FETCH_VIDEO, payload: null });
      toastr.success('Video Updated Successfully');
      }
  } catch (error) {
    
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetch single Video
export const fetchVideo = id => async dispatch => {
  try {
    const res = await axios.get(`/api/VideoDetail/${id}`);
    
    dispatch({ type: FETCH_VIDEO, payload: res.data });
  } catch (error) {
    
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//reset video
export const resetVideo = () => async dispatch => {
  try {
    
    dispatch({ type: FETCH_VIDEO, payload: null });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};