import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import writtenNumber from 'written-number';

import {searchPropComRents} from '../../actions/propcomAction';
import { closeModal } from "../../actions/modalAction";

import {  Form, Button, } from 'semantic-ui-react';
import NumberInput from "../form/NumberInput";
import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';
//import { projectName } from './DataProjectName';
import {
  propertyType,
 // propertyFor,
  booleanOption,
  furnishedStatus,
  
  floorNo,
  // locality,
  sorting
} from './Data';
import {
  bedroom,
  bathroom,
  entry,
} from './DataS';
import {
  projectCom,  locality, 
} from "../functions/DataFunctions";

const PropComRentSearch = props => {
  const { handleSubmit,  reset, submitting } = props;
  const { maxRent, minRent } =
    props.state.form &&
    props.state.form.PropComRentSearch !== undefined &&
    props.state.form.PropComRentSearch.values !== undefined &&
    props.state.form.PropComRentSearch.values;
    const projectName = () => {
      return props.state.projectCs.projectCsForData.map(value => ({
        key: value.projectName,
        text: `${value.projectName} ( ${value._id} )` ,
        value: value.projectName,
      }));
    };
    const onSubmit = values => {
      const offsetCR = 0
      const {
      limitCR,
  } = props.state.propresis;
  props.searchPropComRents(values ,limitCR, offsetCR);

  };

  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Commercial Property Available for Rent
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form ">
          <div  >

          <Form 
          style={{ padding: '10px' }} 
          onSubmit={handleSubmit(onSubmit)}
          >
          <Button
              color="teal"
              size='mini'
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
            
            <Button
            size='mini'
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            size='mini'
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          <label >Sort By</label>
          <Field
            //search={true}
            name="sorting"
            type="text"
            component={SelectInput}
            options={sorting()}
            placeholder="Sort By"
          />


            <label >Property Type</label>
            <Field
              name="propertyTypeA"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Rooms</label>
            <Field
              name="bedroomA"
              placeholder="Rooms"
              type="text"
              options={bedroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Washrooms</label>
            <Field
              name="bathroomA"
              placeholder="Washrooms"
              type="text"
              options={bathroom()}
              //search={true}
              multiple={true}
              component={SelectInput} />

            <label >Furnished Status </label>
            <Field
              name="furnishedStatusA"
              placeholder="Furnished Status"
              type="text"
              options={furnishedStatus()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />



            <label >
              Minimum Rent{' '}
              <span>
                <b>
                  ({writtenNumber(minRent, {
                    lang: 'enIndian'
                  }).toUpperCase()}
                  ){' '}
                </b>
              </span>
            </label>
            <Field
              name="minRent"
              placeholder="Minimum Rent"
              type="number"
              component={NumberInput}
            />
            <label >
              Maximum Rent{' '}
              <span>
                <b>
                  ({writtenNumber(maxRent, {
                    lang: 'enIndian'
                  }).toUpperCase()}
                  ){' '}
                </b>
              </span>
            </label>
            <Field
              name="maxRent"
              placeholder="Maximum Rent"
              type="number"
              component={NumberInput}
            />
            <label >Minimum Carpet Area</label>
            <Field
              name="minArea"
              placeholder="Minimum Carpet Area"
              type="number"
              component={NumberInput}
            />
            <label >
              Maximum Carpet Area
            </label>
            <Field
              name="maxArea"
              placeholder="Maximum Carpet Area"
              type="number"
              component={NumberInput}
            />

            <label >Common Washroom</label>
            <Field
              name="washroomCommon"
              placeholder="Common Washroom"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Personal Washroom</label>
            <Field
              name="washroomPersonal"
              placeholder="Personal Washroom"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Personal Pantry</label>
            <Field
              name="pantryPersonal"
              placeholder="Personal Pantry"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Power BackUp</label>
            <Field
              name="powerBackUp"
              placeholder="Power BackUp"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Lifts In The Tower</label>
            <Field
              name="liftsInTheTower"
              placeholder="Lifts In The Tower"
              type="text"
              options={booleanOption()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Car Parking</label>
            <Field
              name="parking"
              placeholder=" Parking"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Reserve Car Parking</label>
            <Field
              name="reserveParking"
              placeholder="Reserve Car Parking"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />
            <label >Visitor Car Parking</label>
            <Field
              name="visitorParking"
              placeholder="Visitor Car Parking"
              type="text"
              options={booleanOption()}
              component={SelectInput}
            />


            <label >Entry</label>
            <Field
              name="entryA"
              placeholder="Entry"
              type="text"
              options={entry()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />


            <label >Floor No</label>
            <Field
              name="floorNoA"
              placeholder="Floor No"
              type="text"
              options={floorNo()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Complex/Project Name</label>
            <Field
              name="projectNameA"
              placeholder="Complex/Project Name"
              type="text"
              options={projectCom(props.state.projectCs.projectCsForData)}
              search={true}
              multiple={true}
              component={SelectInput}
            />

<label >Locality</label>
<Field
name="localityA"
placeholder="Locality"
type="text"
options={locality(props.state.appParameters.localitys)}
search={true}
multiple={true}
component={SelectInput}
/>
            <label >Property Created FROM</label>
            <Field
              name="from"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Property Created FROM"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Property Created TO</label>
            <Field
              name="to"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Property Created TO"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Property Inactive On</label>
            <Field
              name="inactiveOn"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Property InactiveOn Date"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
<label >Block Ad</label>
<Field
name="adBlocked"
placeholder="Yes or No"
type="text"
options={booleanOption()}
search={true}
component={SelectInput}
/>
            <br />

            <Button
              color="blue"
              type="submit"
              disabled={ submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/><br/><br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA,
  };
};
export default connect(
  mapStateToProps,
  {searchPropComRents,closeModal }
)(
  reduxForm({
    form: 'PropComRentSearch', // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(PropComRentSearch)
);
