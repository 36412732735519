import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon,Container,Sidebar,
Menu,  } from "semantic-ui-react";
import { Switch, Route,Link  } from "react-router-dom";
import { showBackButton,getUserForAuth, } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";

import ProjectNameResiCreate from "./ProjectNameResiCreate";
import ProjectNameResiList from "./ProjectNameResiList";

import ProjectNameComCreate from "./ProjectNameComCreate";
import ProjectNameComList from "./ProjectNameComList";

import LocalityCreate from "./LocalityCreate";
import LocalityList from "./LocalityList";

//###############################################################



import Layout from "../layout/Layout";

class AppParameterDashboard_2 extends Component {
  state = {
    openPop: false,
  };
  componentDidMount() {
    if (!this.props.auth.userA){
      this.props.getUserForAuth();
    }
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
  }
    }
  
  //****************************** */
  handleSidebarShow = () => this.setState({ menuVisible:true })
  handleSidebarHide = () => this.setState({ menuVisible:false })
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
 renderShowHideSidebarButton =()=>{
  return this.state.menuVisible=== true ? (
    <button className='icon-button-accNameFin'  onClick={this.handleSidebarHide}

    ><Icon name="close" /></button>
  ) : (
    <button  className='icon-button-accNameFin' onClick={this.handleSidebarShow }

    ><Icon name="sidebar" /></button>
    )}
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
routeHandler = ()=>{
const className = 'sidebar-border-divider sidebar-accNameFin '
  return (
    <React.Fragment>

<div style={{marginTop:'13px'}}>
<div className='sidebar-border-divider sidebar-padding-head-1' >
Project Name Residential</div>
  
<div className={className} >
  <Link to="/appParameter_2/ProjectNameResiCreate" >
  <b>Create Project Names</b>
</Link>
  </div>

  <div className={className} >
  <Link to="/appParameter_2/ProjectNameResiList" >
  <b>All Project Names</b>
</Link>
  </div>

  

  <div className='sidebar-border-divider sidebar-padding-head-1' >
  Project Name Commercial</div>
    
  <div className={className} >
  <Link to="/appParameter_2/ProjectNameComCreate" >
  <b>Create Project Names</b>
</Link>
  </div>

    <div className={className} >
  <Link to="/appParameter_2/ProjectNameComList" >
  <b>All Project Names</b>
</Link>
  </div>

  <div className='sidebar-border-divider sidebar-padding-head-1' >
  Localities</div>
    
  <div className={className} >
  <Link to="/appParameter_2/LocalityCreate" >
  <b>Create Localities</b>
</Link>
  </div>

    <div className={className} >
  <Link to="/appParameter_2/LocalityList" >
  <b>All Localities</b>
</Link>
  </div>



      </div>

    </React.Fragment>
        )
}

    //********************** */
  render() {
    const { isAuthenticated,  } = this.props.auth;

    const authLinks = (
      <div>

                <div>
                <div className='maindiv'>

      <Layout onLogoutClick={this.onLogoutClick}>

          <Container text >
            <div className='margin-auto' >
            {/**/}
            <div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
            <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              App Parameters
           </div>
           </div>
  {/**/}
           <div
           style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
           >
           <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px',marginRight:'10px'}}  >
           {this.renderShowHideSidebarButton()}
           </div>
                </div>
  {/**/}


           </div>
           </Container>
           {/**/}
           <Sidebar.Pushable >
           <Sidebar
           as={Menu}
           animation="overlay"
           icon="labeled"
           color='blue'
            inverted
           vertical
           visible={this.state.menuVisible}
           onClick={this.handleSidebarHide  }

             >
             {this.routeHandler()}
             </Sidebar>
             <Sidebar.Pusher>

             <Switch>
               
               <Route
                 exact
                 path="/appParameter_2/ProjectNameResiCreate"
                 component={ProjectNameResiCreate}
               />
               
               <Route
                 exact
                 path="/appParameter_2/ProjectNameResiList"
                 component={ProjectNameResiList}
               />

              
               {/* #################################### */}
            <Route
            exact
            path="/appParameter_2/ProjectNameComCreate"
            component={ProjectNameComCreate}
            />

            <Route
            exact
            path="/appParameter_2/ProjectNameComList"
            component={ProjectNameComList}
            />
               {/* #################################### */}
            <Route
            exact
            path="/appParameter_2/LocalityCreate"
            component={LocalityCreate}
            />

            <Route
            exact
            path="/appParameter_2/LocalityList"
            component={LocalityList}
            />
               
               
             </Switch>
           {this.props.children}
            </Sidebar.Pusher>
               </Sidebar.Pushable>
          </Layout >
          </div>

  <div >
  <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
  </div>

  </div>
      </div>
    );

  return (
    <div>
      {isAuthenticated ? (
        authLinks
      ) : (
        <div></div>
      )}
    </div>
  );
}
}
const mapStateToProps = state => ({
auth: state.auth
});


export default connect(
  mapStateToProps,
  { showBackButton,getUserForAuth,  }
)(AppParameterDashboard_2);
