import {
  CREATE_CONTACTREQS,
  FETCH_CONTACTREQS,
  FETCH_CONTACTREQ,
  //DELETE_CONTACTREQ,
  UPDATE_CONTACTREQ,
  SEARCH_CONTACTREQS,
  // DESELECT_CONTACTREQ,
  // SELECT_CONTACTREQ,
  FETCHING_CONTACTREQS,
} from '../actions/types';

const INITIAL_STATE = {
  fetching:true,
  contactReqs: [],
  contactReq: null,
  offset: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONTACTREQS:
      return { ...state, ...action.payload };
    case FETCH_CONTACTREQ:
      return { ...state, contactReq: action.payload };
    case CREATE_CONTACTREQS:
      return {
        ...state,
        contactReqs: [...state.contactReqs, ...action.payload]
      };
    case UPDATE_CONTACTREQ:
      return {
        ...state,
        contactReq: { ...state.contactReq, ...action.payload }
      };
    case SEARCH_CONTACTREQS:
     
      return { ...state, ...action.payload };
      case FETCHING_CONTACTREQS:
        return { ...state, fetching: action.payload };

    default:
      return state;
  }
};
