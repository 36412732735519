import React, {Component} from 'react';
import { connect } from "react-redux";
import './css/style.css';
import './css/loader.css';
import './css/border.css';
import './css/button.css';
import './css/card.css';
import './css/ribbon.css';
import './css/ribbon2.css';
import './css/common.css';
import './css/color.css';
import './css/container.css';
import './css/display.css';
import './css/grid.css';
import './css/home.css';
import './css/mis.css';
import './css/nav.css';
import './css/sidebar.css';
import './css/image.css';
import './css/dropdown.css';
import './css/summary.css';


import Layout from "../layout/Layout";
import { hideBackButton } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import { 
  Image
  } from 'semantic-ui-react'


class Landing extends Component {
  
  componentDidMount() {
    
    if(this.props.history.location.pathname ==='/'){
      this.props.hideBackButton()
    }
    
    window.scroll(0,0)

  }

  handleLoginModal = () => {
    this.props.openModal('LoginModal');
  };
    
    //########################################
// handleShowHideBB=()=>{
//   if(this.props.history.location.pathname ==='/'){
//     this.props.hideBackButton()
//   }

// }

render() {

 // {this.handleShowHideBB()}
  return (
    <div >
<Layout onLogoutClick={this.onLogoutClick}>

      <div id="mainP" style={{marginTop:'-20px'}} >
{/* Header: Showcase */}

<section id="showcase" style={{marginTop:'0px'}}  >
    <div className="showcase-content" style={{marginTop:'0px'}} >
    


      { this.props.auth !== undefined && !this.props.auth.isAuthenticated &&  (
               
               
               <div className='disflex-paginato'>
               <div
         
         
                 onClick={this.handleLoginModal}
                 className="btnlogin"
               >
               login
                 <i className="right arrow icon" />
               </div>
               
               </div>
               
               )
               }
      {/*  */}
    </div>

  </section>


  









</div>

{/* Footer */}
<footer id="main-footer" className="bg-dark text-center py-1">
<div className="containerP">
<div className="row" style={{border:'none'}}>
<div className="disflex-nav-footer" style={{ flex: '50%',padding: 20,flexDirection:'column'}}>

<div className = ' border-logo '><Image size='mini' src='/logo.png'  /></div>
  {/*  */}
  <div className='' style={{fontSize:'1.2rem',fontWeight:'bold'}}>
  <span style={{color:'dodgerblue'}}>ADMIN.</span>
  <span style={{color:'skyblue'}}>FIND</span>
  <span style={{color:'dodgerblue'}}>PROP</span>
  <span style={{color:'orange'}}>.IN</span>
  </div>
  
{/*  */}

{/*  */}
<p ><span id="contact"> 85 Rajeev Nagar, New Delhi-86, INDIA </span></p>

{/*  */}
</div>
<div className="main " style={{ flex: '50%'}}>
<nav id="navbarfoot" className="disflex-nav-footer"> 




    {/* <ul className='disflex-nav-footer-ul'>


    
    <li onClick={this.handleOpenAbout}
    className='btnnavAbout'

    >About</li>

      <li>
      <a      
      onClick={this.handlePrivacyPolicyModal}
      >Privacy Policy
      </a>
      </li>

      <li>
      <a
      onClick={this.handleCookiesPolicyModal}
      >Cookies Policy
      </a>
      </li>

      <li>
      <a
      onClick={this.handleTermsAndConditionsModal}
      >Terms & Conditions
      </a>
      </li>

    </ul> */}
    </nav>

    </div>



</div>
<div className='font-16B'> 
  {/*  */}
        <p className='disflex-paginator'>
        
        <img className= 'yimg ' src='/phoneIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle',marginRight:'10px'}} />
       
        +91-9810659136
        </p>
{/*  */}
<p className='disflex-paginator '>
      
      <img className= 'yimg ' src='/whatsappIcon.jpg' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle',marginRight:'10px'}} />
       +91-9810659136
      </p>
      {/*  */}
      <p className='disflex-paginator'> 
      
      <img className= 'yimg ' src='/emailIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle',marginRight:'10px'}} />
        support@citsys.in 

      </p>
    {/*  */}
     </div>
<p > © Copyright 2020 CITSYS, All Rights Reserved</p>
<br/>
</div>
</footer>
</Layout>
    </div>
  );
}
}

const mapStateToProps = ({ auth,  }) => {

  return {
    auth,
    userA: auth.userA,
    
  };
};

export default connect(
   mapStateToProps,

 {hideBackButton,openModal }
)(Landing);
