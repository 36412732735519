import React, { Component } from "react";
import { connect } from "react-redux";
//import { Grid,Button } from "semantic-ui-react";
import { Switch, Route } from "react-router-dom";

import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";

import DealerSearchList from "./DealerSearchList";
import FinancerSearchList from "./FinancerSearchList";

import OtherCompanyDetail from "./OtherCompanyDetail";
import CompanyEdit from "./CompanyEdit";
import AddImage from "./AddImage";
import UserDetail from "./UserDetail";
import UserEdit from "./UserEdit";
import UserList from "./UserList";
import Layout from "../layout/Layout";
//#####################



  class CompanyDashboard extends Component {
    componentDidMount() {
      this.props.getUserForAuth();
      if(this.props.history.location.pathname !=='/'){
        this.props.showBackButton()
      }
    }
    onLogoutClick = () => {
   
      this.props.logoutUser();
    };
  render(){
    return (
    <div>
    <div className='maindiv'>

    <Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
    <Route exact path="/company/editcompany/:id" component={CompanyEdit} />
    <Route exact path="/company/addimage/:id" component={AddImage} />
    <Route
      exact
      path="/company/company/:id"
      component={OtherCompanyDetail}
    />

    
    <Route
      exact
      path="/company/DealerSearchList"
      component={DealerSearchList}
    />
    <Route
      exact
      path="/company/FinancerSearchList"
      component={FinancerSearchList}
    />
    <Route exact path="/company/UserList" component={UserList} />
    <Route exact path="/company/UserDetail/:id" component={UserDetail} />
    <Route exact path="/company/UserEdit/:id" component={UserEdit} />



    </Switch>
        </Layout >
        </div>

    <div >
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>
      );


}
}
// const mapStateToProps = state => ({
// auth: state.auth
// });

export default connect(
// mapStateToProps,
null,
  {showBackButton, getUserForAuth, logoutUser  }
)(CompanyDashboard);
