import React from 'react';
import { Form, Label } from 'semantic-ui-react';

const TextInput = ({
 //field,
  input,
  //value,
  //onChange,
  width,
  type,
  placeholder,
  meta: { touched, error },
  ...props
}) => {
  const val = Math.abs(parseInt (input.value))
  return (
    <Form.Field error={touched && !!error} width={width}>
      <input
        {...input}
        {...props}  // required for consolidated entry
        placeholder={placeholder}
        type={type}
        className='input'
        value={val || ''}
         // onChange={onChange}
         onWheel={(e) => e.target.blur()}  //this Disable Scrolling on HTML Number Input in React e.i. number does not increase/decrease on scrolling(wheel)
      />
      {touched &&
        error && (
          <Label basic color="red">
            {error}
          </Label>
        )}
    </Form.Field>
  );
};

export default TextInput;
