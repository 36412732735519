import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import format from "date-fns/format";
import LoginForModal from "../auth/LoginForModal";
import Paginator from "../paginator/Paginator";
import { openModal } from "../../actions/modalAction";
import { searchFinancerCompanys,updateCompanyRanking } from "../../actions/companysAction";
import { shortlistAddRemoveDealer } from "../../actions/authAction";
import {imageURL} from "../configC/keys";
import {
  Header,
  // Container,
  // Divider,
  Segment, 
 // Image,
  Icon,
  Button,
  Modal,
} from "semantic-ui-react";



class FinancerSearchList extends Component {
  state = {
    id:'',
    modalOpenL: false,
    open: false,
    result: "show the modal to capture a result"
  };
  componentDidMount() {
    //const values = {};const values = {localityA:[]};
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    this.props.searchFinancerCompanys(values, this.props.history);
    //this.props.fetchUsers();
  }
  handleSearchComp = () => {
    this.props.openModal("SearchCompanyModal");
  }
  shortlistAddRemoveHandler = (id) => {
      if (!this.props.userA) {
        this.setState({ modalOpenL: true })

      }else{
        this.props.shortlistAddRemoveDealer(id);
      }

    };

    handleCloseL = () => this.setState({ modalOpenL: false })  //login modal


  handleUpdateRanking = (id) => {
  this.props.updateCompanyRanking(id)
  }

  back = () => {
    const {
      offset,
      limit,
      limitStatic,
      offsetStatic
      // form: {
      //   CompanySearch: { values }
      // }
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    if (offset === 0) {
      return;
    }

    this.props.searchFinancerCompanys(values, limit, (offset - limit),limitStatic, (offsetStatic - limitStatic));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
     limit,
     count,
     limitStatic,
     offsetStatic
      // form: {
      //   CompanySearch: { values }
      // }
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    if (offset + limit > count) {
      return;
    }

    this.props.searchFinancerCompanys(values, limit, (offset + limit), limitStatic, (offsetStatic + limitStatic));
    window.scroll(0,0)
  };

  renderPaginator() {
    if (this.props.companys.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
          //values={this.props.values}
        />
      );
    }
  }

  //************************
  renderList=(company)=> {
    const {
      nameClass,
      cname,
      // cmobile1,
      // cmobile2,
      // cemail,
      // website,
      cimage,
      // addLine1,
      // addLine2,
      locality,
      city,
      // state,
      // pincode,
      // operatingSince,
      nameCP1,
      mobileCP1,
     // emailCP1,
      designationCP1,
     // photoCP1,
      nameCP2,
      mobileCP2,
     // emailCP2,
      designationCP2,
     // photoCP2,
      typeOfDealings,
      typeOfPropertyDealsIn,
      // areaOfOperation,
      // description,
      _id
    } = company;
    const rendertypeOfDealings = () => {
      return typeOfDealings.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };
    const rendertypeOfPropertyDealsIn = () => {
      return typeOfPropertyDealsIn.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };


    const renderButton = () =>
      company._id !== this.props.user.companyId ? (

        <React.Fragment>
        <div className='nodisplay'><Button
        // basic
        // inverted
        floated="right"
        size="mini"
        color="teal"
        type="button"
        as={Link}
       // target='_blank'
        to={`/company/company/${_id}`}
      >
        Detail
      </Button></div>
      <div className='nodisplay-tab'><Button
        // basic
        // inverted
        floated="right"
        size="mini"
        color="teal"
        type="button"
        as={Link}
        //target='_blank'
        to={`/company/company/${_id}`}
      >
        Detail
      </Button></div>
      </React.Fragment>

      ) : (
        <div></div>
      );


      const checkID = this.props.userA !== null && this.props.userA.shortlistDealer !==undefined && this.props.userA.shortlistDealer.filter(val => {

      return val.toString() === _id;
    });
    const renderShortlistButton = () =>
          checkID.length > 0 ? (
        <Button

          size="mini"
          floated="right"
          color="black"
          onClick={() => this.shortlistAddRemoveHandler(_id)}
        >
          Remove From Shortlist
        </Button>
      ) : (
        <Button
          size="mini"
          floated="right"
          color="black"
          onClick={() => this.shortlistAddRemoveHandler(_id)}
        >
          Add To Shortlist
        </Button>
      );

  //******************
  const classN = nameClass ==='static'? 'cardStatic':'card'
//*******************************************
return (
  <div key={_id} >
        <div className={classN} style={{padding:'5px'}}>{/* 1 */}
{/*
        <span className="yribbon1">
        <span style={{ fontSize:'16px',color:'white'}}>

                Premium Dealer
        </span>
        </span> */}

        <div className='disflex-dsl border-bottom nodisplay' >{/* 2 */}

        <img className= 'yimg imgDimention' src={`${imageURL}/${cimage}`} alt="Company Logo"  />

        <div style={{flex:'80%',  marginLeft:'10px',padding:'0px'}}>
          <div className='font-dsl-head'> {cname}</div >
          <div className='font-dsl-loc'>{locality}{' '}{city} New Delhi </div>
          </div>

        </div>{/* 2 */}
        {/* for mobile start */}
<div className='nodisplay-tab'>
<div className='disflex-dsl border-bottom' >{/* 2 */}

                <img className= 'yimg imgDimention-ad' src={`${imageURL}/${cimage}`} alt="Company Logo"  />

                <div style={{flex:'80%',  marginLeft:'10px',padding:'0px'}}>
                <div className='font-dsl-head-main'> {cname}</div >

                </div>

                </div>{/* 2 */}
                <div className='font-dsl-loc-main'>{locality}{' '}{city} New Delhi </div>

</div>
        {/* for mobile end */}
        <div> {/* 3 */}
        <div style={{padding:'5px'}}>{/* 4 */}
        <div>
        {renderButton()}
        { renderShortlistButton()}
    <Button
          size="mini"
          floated="right"
          color="orange"
          onClick={() => this.handleUpdateRanking(_id)}
        >
          Update Ranking
        </Button>
        <Button
          // basic
          //inverted
          size="mini"
          floated="right"
          color="teal"
          type="button"
          as={Link}
         // target='_blank'
          to={`/admin/CreditAddCreate/${company._id}`}
        >
          Add Credit
         </Button>
         </div>
        <div >
 {nameCP1 && (
   <div className='row'>
     <div className='side'>
       <b>First Contact Person</b>
     </div>
     <div className='main'>
       <b>{nameCP1}{' '}<span style={{color:'dodgerblue'}}>{designationCP1}</span>{' '}{mobileCP1} </b>
     </div>

   </div>
 )}
</div>
<div >
{nameCP2 && (
<div className='row'>
<div className='side'>
<b>Second Contact Person</b>
</div>
<div className='main'>
<b>{nameCP2}{' '}<span style={{color:'dodgerblue'}}>{designationCP2}</span>{' '}{mobileCP2} </b>
</div>

</div>
)}
</div>
 <div >
  {typeOfDealings != null &&
               typeOfDealings.length >= 1 && (
   <div className='row'>
     <div className='side'>
       <b>Type of Dealings</b>
     </div>
     <div className='main'>
       {rendertypeOfDealings()}
     </div>

   </div>
 )}
</div>
<div >
{typeOfPropertyDealsIn != null &&
             typeOfPropertyDealsIn.length >= 1 && (
   <div className='row'>
     <div className='side'>
       <b>Type Of Property deals in</b>
     </div>
     <div className='main'>
        {rendertypeOfPropertyDealsIn()}
     </div>

   </div>
 )}
</div>
</div>{/* 4 */}


        </div>{/* 3 */}

        </div>{/*1  */}
  </div>



);


  }

  //###########################################renderListStatic end
  renderLoading() {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }


  render() {
    //********************** */
    return (

      <div>
      <div className='maindiv'>
      

      <div>
      <Segment
          textAlign="center"
          //attached="bottom"
          inverted
          color="teal"
          style={{ border: "none" }}
        >

        <div>
        <Modal

          open={this.state.modalOpenL}
          onClose={this.handleCloseL}
          size='tiny'
          // size='small'
        >
            <Modal.Content >
    <div><h3>You Are Not Logged In. Please Logged In</h3> </div>
          <LoginForModal handleCloseL={this.handleCloseL}/>
          </Modal.Content>
          <Modal.Actions>


            <Button color='blue' onClick={this.handleCloseL} >
              <Icon name='close' />Cancel
            </Button>

          </Modal.Actions>
        </Modal>
        </div>

          <Button
            inverted
            //size="mini"
            onClick={this.handleSearchComp}
            color="teal"
          >
            <Icon name="search" /> Advance Search
          </Button>
          <Header>{this.props.count}{' '}Dealers</Header>
        </Segment>
        {this.renderLoading()}


        {this.props.staticTop1.map((val)=>{ return {...val,nameClass:'static'}}).map(this.renderList)}
        {this.props.staticTop2.map((val)=>{ return {...val,nameClass:'static'}}).map(this.renderList)}

        {this.props.companys.map(this.renderList)}

        {this.props.staticBottom1.map((val)=>{ return {...val,nameClass:'static'}}).map(this.renderList)}
        {this.props.staticBottom2.map((val)=>{ return {...val,nameClass:'static'}}).map(this.renderList)}

        {this.renderPaginator()}
      <br/><br/><br/>

      </div>
      


      </div>
      </div>

        );

    //************************ */


  }
}

const mapStateToProps = state => {

  return {

    fetching: state.companys.fetching,
    offset: state.companys.offset,
    limit: state.companys.limit,
    count: state.companys.count,
    offsetStatic: state.companys.offsetStatic,
    limitStatic: state.companys.limitStatic,
    countStatic: state.companys.countStatic,
    staticTop1: state.companys.staticTop1,
    staticTop2: state.companys.staticTop2,
    staticBottom1: state.companys.staticBottom1,
    staticBottom2: state.companys.staticBottom2,
    companys: state.companys.companys,
    users: state.auth.usersA,
    userA: state.auth.userA,
    user: state.auth.user,
    form: state.form.CompanySearch
  };
};
export default connect(
  mapStateToProps,
  { openModal, searchFinancerCompanys ,updateCompanyRanking,shortlistAddRemoveDealer}
)(FinancerSearchList);
