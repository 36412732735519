import {
  CREATE_CONTACTUSS,
  FETCH_CONTACTUSS,
  FETCH_CONTACTUS,
 // DELETE_CONTACTUS,
  UPDATE_CONTACTUS,
  SEARCH_CONTACTUSS,
  // DESELECT_CONTACTUS,
  // SELECT_CONTACTUS,
  FETCHING_CONTACTUSS,
} from '../actions/types';

const INITIAL_STATE = {
  fetching:true,
  contactUss: [],
  contactUs: null,
  offset: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONTACTUSS:
      return { ...state, ...action.payload };
    case FETCH_CONTACTUS:
      return { ...state, contactUs: action.payload };
    case CREATE_CONTACTUSS:
      return {
        ...state,
        contactUss: [...state.contactUss, ...action.payload]
      };
    case UPDATE_CONTACTUS:
      return {
        ...state,
        contactUs: { ...state.contactUs, ...action.payload }
      };
    case SEARCH_CONTACTUSS:
     
      return { ...state, ...action.payload };
      case FETCHING_CONTACTUSS:
        return { ...state, fetching: action.payload };

    default:
      return state;
  }
};
