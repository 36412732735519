import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { toastr } from "react-redux-toastr";

import { closeModal,openModal } from "./modalAction";

import {
  SET_CURRENT_USER,
  SEARCH_USERS,
  FETCH_USERS,
  FETCH_USER,
  FETCH_USERDETAIL,
  //**** */
  SHOW_SIDEBAR,
  HIDE_SIDEBAR,
  SHOW_BACKBUTTON ,
  HIDE_BACKBUTTON ,

} from "./types";


//Update password
export const updatePassword = (userData , history) => async dispatch => {
  try {
    const res = await axios.post(`/api/users/updatePassword`, userData);

      
const message = res.data.msg
history.goBack() 
 toastr.success(message)
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};



// Login - Get User Token
export const loginUser = (userData) => async dispatch => {
  try {
    const res = await axios.post("/api/users/login", userData);

  switch (res.data.msg) {
  case 'errorPass':
  toastr.error('Password Incorrect');
  break;
  case 'errorLogedin':
  toastr.error('You are Loged In on another Device. Please Logged Out from that and than try again');
  break;
  case 'errorNoUser':
    toastr.error('Either your Id is Incorrect or there is No User with this Id');
    break;
  default:
    toastr.success('Loged In Successfully');
}


if(res.data.msg ==='success'){
      const { token } = res.data;
     
      // Set token to ls
      localStorage.setItem("jwtToken", token);
     
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
     
      // Set current user
      dispatch(setCurrentUser(decoded));

      dispatch({ type: FETCH_USER, payload: res.data });

      dispatch(closeModal());
}


  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//##############################
//Form PassReset
export const formPassReset = (userData, history) => async dispatch => {
  try {
    const res = await axios.post('/api/users/resetPasswordToken', userData);

    if(res.data.msg === 'error'){
      toastr.error("Please check you have entered a Wrong Email. Enter your Registered Email")
    }

      if(res.data.msg ==='success'){
        history.goBack() 
        toastr.success('Password Reset link is send at your Registered Email.')
      }
    } catch (error) {
      if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
    }
  };
//****************************
//RESET PASSWORD
export const resetPassLogin = (token, userData, history) => async dispatch => {
  try {
    const res = await axios.put(`/api/users/resetPassword/${token}`, userData);

    if(res.data.msg === 'error'){
      toastr.error("Password Reset link have expired Or Invalid. Please Get Another .")
    }
      if(res.data.msg ==='success'){
        history.goBack() 
        dispatch(openModal('LoginModal'));
        toastr.success('Password Changed.You can Login with New Password ')
      }
    

  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => async dispatch => {
  try {
    
       
      // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));

     dispatch({ type: FETCH_USER, payload: {userA:null,user:null} });
   // window.location.href = '/login';
   toastr.success('Logout Successfully')  

} catch (error) {
  if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  

};
}
//*************************************************
export const clearStorage = () =>dispatch => {

  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.location.href = '/login';

};
//####################################################################
//search  Users

export const searchUsers = (
  values,
  limit,
  offset,
 
) => async dispatch => {

  try {
    const res = await axios.post("/api/UserSearch", {
      values,
      limit,
      offset
    });


    dispatch({ type: SEARCH_USERS, payload: res.data });
    dispatch({ type: FETCH_USERDETAIL, payload: null });
    dispatch(closeModal());
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//fetch all Users
export const fetchUsers = () => async dispatch => {
  try {
    const res = await axios.post("/api/UserList");
    dispatch({ type: FETCH_USERS, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//fetch single User
export const fetchUser = id => async dispatch => {
  try {
    const res = await axios.get(`/api/UserDetail/${id}`);
    dispatch({ type: FETCH_USERDETAIL, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//Get user Id
export const getUserId = id => async dispatch => {
  try {
    const res = await axios.post(`/api/GetUserId`,{id});

    dispatch({ type: FETCH_USERDETAIL, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
   
  }
};
//*************************************************
export const getUserForAuth = () => async dispatch => {
  try {
    const res = await axios.get(`/api/GetUserForAuth`);
   
    dispatch({ type: FETCH_USER, payload: res.data });
  } catch (error) {
    // if(error.response.status==401){
    //   dispatch(openModal('LoginModal')); 
    //   toastr.error(`${JSON.stringify(error.response.data) }`);
    // }else{
    //   toastr.error("Oops", "Something went wrong.Please Try Again");
    // }
  
  }
};
//********************************************
//delete User
export const deleteUser = (id, history) => async dispatch => {
  try {
    const res = await axios.delete(`/api/UserDelete/${id}`);

    history.push("/admin/UserList");
    dispatch({ type: DELETE_USER, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//****************************
////update User by admin user
export const updateUser = (id, values, history) => async dispatch => {
  try {
    const res = await axios.put(`/api/UserEdit/${id}`, values);

    switch (res.data.msg) {

      case 'success':
      toastr.success('User Updated Successfully');
      break;
      default:
        toastr.success(' ');
    }
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//#####################################################
//update User to INACTIVE
export const updateUserToInactive = (id, history) => async dispatch => {
  try {
    const res = await axios.put(`/api/UserInactive/${id}`);

    history.push(`/admin/UserListA`);
    dispatch({ type: UPDATE_USER, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};


// create User
export const createSUser = (id, values, history) => async dispatch => {
  try {
    const res = await axios.post("/api/SUserCreate", { id, values });

    if(res.data.msg === 'error'){
      toastr.error("Email Already Exist .")
    }else {
      if(res.data.msg ==='success'){
        toastr.success('User Created')
      }
    }
    //  history.push('/admin/UserListA');
    //dispatch({ type: CREATE_USERS, payload: res.data });

  } catch (error) {
  
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//################################################# SHORTLIST

//add / remove property to residential shortlist
export const shortlistAddRemoveResi = (id) => async dispatch => {
 
try {
  const res = await axios.post("/api/ShortlistAddRemoveResi",{
    id:id,

  } );
  if(res.data.msg === 'removed'){
    toastr.error("Property Removed from Shortlist.")
  }else {
    if(res.data.msg ==='added'){
      toastr.success('Property Added to Shortlist')
    }
  }
  
  dispatch({ type: FETCH_USER, payload: res.data });
} catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again shortlistAddRemoveResi");
}
};

//add / remove property to residential shortlist
export const shortlistAddRemoveCom = (id) => async dispatch => {
 
try {
  const res = await axios.post("/api/ShortlistAddRemoveCom",{
    id:id,

  } );
  if(res.data.msg === 'removed'){
    toastr.error("Property Removed from Shortlist.")
  }else {
    if(res.data.msg ==='added'){
      toastr.success('Property Added to Shortlist')
    }
  }
  
  dispatch({ type: FETCH_USER, payload: res.data });
} catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again shortlistAddRemoveResi");
}
};

//add / remove Project to Project shortlist
export const shortlistAddRemoveProject = (id) => async dispatch => {
 
try {
  const res = await axios.post("/api/ShortlistAddRemoveProject",{
    id:id,

  } );
  if(res.data.msg === 'removed'){
    toastr.error("Project Removed from Shortlist.")
  }else {
    if(res.data.msg ==='added'){
      toastr.success('Project Added to Shortlist')
    }
  }
  
  dispatch({ type: FETCH_USER, payload: res.data });
} catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again shortlistAddRemoveResi");
}
};

//add / remove Dealer to Dealer shortlist
export const shortlistAddRemoveDealer = (id) => async dispatch => {
  
try {
  const res = await axios.post("/api/ShortlistAddRemoveDealer",{
    id:id,

  } );
  if(res.data.msg === 'removed'){
    toastr.error("Dealer Removed from Shortlist.")
  }else {
    if(res.data.msg ==='added'){
      toastr.success('Dealer Added to Shortlist')
    }
  }
  
  dispatch({ type: FETCH_USER, payload: res.data });
} catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again shortlistAddRemoveResi");
}
};




//********************************** */
export const showSidebar = () => async dispatch => {
  try {

    dispatch({ type: SHOW_SIDEBAR, payload: true });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

export const hideSidebar = () => async dispatch => {
  try {

    dispatch({ type: HIDE_SIDEBAR, payload: false });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//********************************** */

export const showBackButton = () => async dispatch => {
  try {

    dispatch({ type: SHOW_BACKBUTTON, payload: true });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

export const hideBackButton = () => async dispatch => {
  try {

    dispatch({ type: HIDE_BACKBUTTON, payload: false });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
