import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";

import Paginator from "../paginator/Paginator";
import { openModal } from "../../actions/modalAction";
import { searchCompanys,updateCompanyRanking } from "../../actions/companysAction";

import {
  Form,
 // Image,
  Icon,
  Button,
} from "semantic-ui-react";


class DealerSearchList extends Component {
  state = {
    pageO:0,
    id:'',
    modalOpenL: false,
    open: false,
    result: "show the modal to capture a result"
  };
  componentDidMount() {
    const {offset, limit} = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    this.props.searchCompanys(values, limit,offset);
  }
  handleSearchComp = () => {
    this.props.openModal("SearchCompanyModal");
  }
  
  handleUpdateRanking = (id) => {
    this.props.updateCompanyRanking(id)
    }
  //^^^^^^^^^^^^^^^^^^^^^^^^^
    onChange=(e) => {
      this.setState({ [e.target.name]: e.target.value });
    }
  //*******************************************************
  back = () => {
    const {
      offset,
      limit,
      limitStatic,
      offsetStatic
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    if (offset === 0) {
      return;
    }

    this.props.searchCompanys(values, limit, (offset - limit),limitStatic, (offsetStatic - limitStatic));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
     limit,
     count,
     limitStatic,
     offsetStatic
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    if (offset + limit > count) {
      return;
    }

    this.props.searchCompanys(values, limit, (offset + limit), limitStatic, (offsetStatic + limitStatic));
    window.scroll(0,0)
  };
//***************** */
handleGoToPage = () => {
  const {
   limit,
   count,
   limitStatic,
  
  } = this.props;
  const values =
    (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    // use Math.abs for strict equality ===  to work and safe guard against negative value from user
   if (Math.abs(this.state.pageO) === 0) {
    return; 
  }
    

  const page=(this.state.pageO*limit > count)?(Math.ceil(count/limit-1)):(this.state.pageO-1)


  this.props.searchCompanys(values, limit, (page * limit), limitStatic, (page * limitStatic));
  window.scroll(0,0)

};

//****************** */
  renderPaginator() {
    if (this.props.companys.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }

  //************************
  renderList=(company)=> {
    const {
      nameClass,
      cname,
      // cmobile1,
      // cmobile2,
      // cemail,
      // website,
      cimage,
      // addLine1,
      // addLine2,
      locality,
      city,
      // state,
      // pincode,
      // operatingSince,
      nameCP1,
      mobileCP1,
     // emailCP1,
      designationCP1,
     // photoCP1,
      nameCP2,
      mobileCP2,
     // emailCP2,
      designationCP2,
     // photoCP2,
      // typeOfDealings,
      // typeOfPropertyDealsIn,
      companySubscriptionStartOn,
      companySubscriptionExpireOn,

      //********** */
      _id
    } = company;
    // const rendertypeOfDealings = () => {
    //   return typeOfDealings.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };
    // const rendertypeOfPropertyDealsIn = () => {
    //   return typeOfPropertyDealsIn.map(item => {
    //     return (
    //       <span key={item}>
    //         {" "}
    //         <b>{item} |</b>
    //       </span>
    //     );
    //   });
    // };


    const renderButton = () =>
       (

        <React.Fragment>
        <div ><Button
        // basic
        // inverted
        floated="right"
        size="mini"
        color="teal"
        type="button"
        as={Link}
        //target='_blank'
        to={`/company/company/${_id}`}
      >
        Detail
      </Button></div>
      
      </React.Fragment>

      ) 


      

  //******************
  const classN = nameClass ==='static'? 'cardStatic':'card'
//*******************************************
return (
  <div key={_id} >
        <div className={classN} style={{padding:'5px'}}>{/* 1 */}


        <div className='disflex-dsl border-bottom nodisplay' >{/* 2 */}

        <img className= 'yimg imgDimention' src={`${cimage}`} alt="Company Logo"  />

        <div style={{flex:'80%',  marginLeft:'10px',padding:'0px'}}>
          <div className='font-dsl-head'> {cname}</div >
          <div className='font-dsl-loc'>{locality}{' '}{city} New Delhi </div>
          </div>

        </div>{/* 2 */}
        {/* for mobile start */}
<div className='nodisplay-tab'>
<div className='disflex-dsl border-bottom' >{/* 2 */}

                <img className= 'yimg imgDimention-ad' src={`${cimage}`} alt="Company Logo"  />

                <div style={{flex:'80%',  marginLeft:'10px',padding:'0px'}}>
                <div className='font-dsl-head-main'> {cname}</div >

                </div>

                </div>{/* 2 */}
                <div className='font-dsl-loc-main'>{locality}{' '}{city} New Delhi </div>

</div>
        {/* for mobile end */}
        <div> {/* 3 */}
        <div style={{padding:'5px'}}>{/* 4 */}
        <div style={{display:'flex'}} >
        {renderButton()}
        
    <Button
          size="mini"
          floated="right"
          color="orange"
          onClick={() => this.handleUpdateRanking(_id)}
        >
          Update Ranking
        </Button>
        <Button
          // basic
          //inverted
          size="mini"
          floated="right"
          color="teal"
          type="button"
          as={Link}
         // target='_blank'
          to={`/activity/CreditAddCreate/${company._id}`}
        >
          Add Credit
         </Button>
         </div>
         <br/>
        <div >
 {nameCP1 && (
   <div className='row'>
     <div className='side'>
       <b>First Contact Person</b>
     </div>
     <div className='main'>
       <b>{nameCP1}{' '}<span style={{color:'dodgerblue'}}>{designationCP1}</span>{' '}{mobileCP1} </b>
     </div>

   </div>
 )}
</div>
<div >
{nameCP2 && (
<div className='row'>
<div className='side'>
<b>Second Contact Person</b>
</div>
<div className='main'>
<b>{nameCP2}{' '}<span style={{color:'dodgerblue'}}>{designationCP2}</span>{' '}{mobileCP2} </b>
</div>

</div>
)}
</div>

                  <div > 
                  {companySubscriptionStartOn && (
                  <div className='row'>
                  <div className='side'>
                  <b>Subscription Start On</b>
                  </div>
                  <div className='main'>

                  <b>{ format(new Date(companySubscriptionStartOn), "dd-MM-yyyy")}</b>{' '}
                  
                  </div>

                  </div>
                  )}
                  </div>

                  <div >
                  {companySubscriptionExpireOn && (
                  <div className='row'>
                  <div className='side'>
                  <b>Subscription Expire On</b>
                  </div>
                  <div className='main'>

                  <b>{ format(new Date(companySubscriptionExpireOn), "dd-MM-yyyy")}</b>{' '}
                  
                  </div>

                  </div>
                  )}
                  </div>

  <div >
  {_id  && (
   <div className='row'>
     <div className='side'>
       <b>_id</b>
     </div>
     <div className='main'>
      <b>{_id}</b> 
     </div>

   </div>
 )}
</div>
{/* <div >
{typeOfPropertyDealsIn != null &&
             typeOfPropertyDealsIn.length >= 1 && (
   <div className='row'>
     <div className='side'>
       <b>Type Of Property deals in</b>
     </div>
     <div className='main'>
        {rendertypeOfPropertyDealsIn()}
     </div>

   </div>
 )}
</div> */}


</div>{/* 4 */}


        </div>{/* 3 */}

        </div>{/*1  */}
  </div>



);


  }

  //###########################################renderListStatic end
  renderLoading() {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }


  render() {
    //********************** */
    return (

      <div>
      <div className='maindiv-820'>
{/**/}
<div className='cashbook-H box-shadow'>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchComp}>
      <span className='icon-button border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.count}{' '}Dealers
</div>
</div>


</div>
{/**/}
      <div>
      
        {this.renderLoading()}


        {this.props.companys.map(this.renderList)}

        
        <div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()}
<div className="disflex-paginator mb-0">
  <div className= 'font-paginator'>Go To Page</div>
<Form size="mini" >

            <Form.Input

                placeholder="Enter page number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChange}

              />
            </Form>
            <Button size='mini' color='blue' onClick={this.handleGoToPage} >
          Submit
        </Button>
</div>
</div>

      <br/><br/><br/>

      </div>
      


      </div>
      </div>

        );

    //************************ */


  }
}

const mapStateToProps = state => {

  return {

    fetching: state.companys.fetching,
    offset: state.companys.offset,
    limit: state.companys.limit,
    count: state.companys.count,
    companys: state.companys.companys,
    users: state.auth.usersA,
    userA: state.auth.userA,
    user: state.auth.user,
    form: state.form.CompanySearch
  };
};
export default connect(
  mapStateToProps,
  { openModal, searchCompanys ,updateCompanyRanking}
)(DealerSearchList);
