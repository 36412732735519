import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
//import writtenNumber from "written-number";

import {searchDealerSuggesteds} from "../../actions/dealerSuggestedAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,  Header, } from "semantic-ui-react";
// import TextInput from "../form/TextInput";
// import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";


  


const AdByDealersSearch = props => {
 
  const { handleSubmit, pristine, reset, submitting } = props;

  const onSubmit = values => {
    props.searchDealerSuggesteds(values);
  };
  return (
    
    <div style={{maxWidth:'700px',margin:'auto'}}>

{/**/}
<div className='margin-auto-H' >
    <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
      {/*  */}
      Search Dynamic Advertisements
  {/*  */}
  </div>
  </div>
  {/**/}

  <div className="card-form mt-10">
    <div  >
    <br/>
    <Form
    onSubmit={handleSubmit(onSubmit)}
    >
      <Button
      color="teal"
      type="button"
      onClick={()=>props.closeModal()}

      >
      Cancel
      </Button>
      
      <Button
        color="orange"
        type="button"
        disabled={ submitting}
        onClick={reset}
      >
        Clear Values
      </Button>
      <Button
      style={{color:'black'}}
        color="blue"
        type="submit"
        disabled={submitting}
      >
        Search
      </Button>
      <br/><br/>

    {/* <label htmlFor=""> Ad For </label>
      <Field
       // search={true}
        name="adFor"
        type="text"
        component={SelectInput}
       // multiple={true}
        options={adFor()}
        placeholder="Ad For  "
      />  */}
      {/* <label htmlFor=""> Project/Property Type </label>
      <Field
      //  search={true}
        name="projectType"
        type="text"
        component={SelectInput}
       // multiple={true}
        options={projectType()}
        placeholder="Project/Property Type"
      />  */}
      {/* <label htmlFor=""> Property For </label>
      <Field
       // search={true}
        name="propertyFor"
        type="text"
        component={SelectInput}
       // multiple={true}
        options={propertyFor()}
        placeholder="Property For"
      />  */}
      {/* <label htmlFor=""> Locality </label>
      <Field
       // search={true}
        name="locality"
        type="text"
        component={SelectInput}
       // multiple={true}
        options={locality()}
        placeholder="Locality"
      />  */}
      {/*  */}
      <label htmlFor="">Lower Started On Date</label>
            <Field
              name="startL"
              type="text"
              component={DateInput}
              placeholder= "In DD-MM-YYYY format Lower Started On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Higher Started On Date</label>
            <Field
              name="startH"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Started On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Lower Expired On Date</label>
            <Field
              name="endL"
              type="text"
              component={DateInput}
              placeholder= " In DD-MM-YYYY format Lower Expired On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Higher Expired On Date</label>
            <Field
              name="endH"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Expired On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />



            <br />

      <Button
        color="blue"
        type="submit"
        disabled={submitting}
      >
        Search
      </Button>
      <Button
        color="orange"
        type="button"
        disabled={submitting}
        onClick={reset}
      >
        Clear Values
      </Button>
      <Button
        color="teal"
        type="button"
        onClick={()=>props.closeModal()}

      >
        Cancel
      </Button>
    </Form>
    <br/>
      </div>
      </div>
      </div>
);
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {searchDealerSuggesteds,closeModal}
)(
  reduxForm({
    form: "AdByDealersSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(AdByDealersSearch)
);
