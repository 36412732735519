import React, { Component } from 'react';
import {  Link } from "react-router-dom";
import { Icon,
  Image, Confirm
 } from 'semantic-ui-react';
 import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { connect } from 'react-redux';
// import ImageGallery from 'react-image-gallery';
import { fetchAppParameter } from "../../actions/commonAction";
import { closeModal } from '../../actions/modalAction';
import {fetchProjectImages ,fetchPropertyImages} from "../../actions/imageAction";
import {updateProjectCForAddImagePath,} from "../../actions/projectCAction";
import {updateProjectRForAddImagePath} from "../../actions/projectRAction";
import {updatePropComForAddImagePath} from "../../actions/propcomAction";
import {updatePropResiForAddImagePath} from "../../actions/propresiAction";


class ShowImages extends Component {
  state = {
    path:'',
    open: false,
    rotation: 0,
};
  componentDidMount = () =>{
    const id = this.props.id
    if (!this.props.parameter){
      this.props.fetchAppParameter()
        }
      
        if((this.props.imageOf === 'ProjectC') || (this.props.imageOf === 'ProjectR')){
          this.props.fetchProjectImages(id)
        }
        if((this.props.imageOf === 'PropertyC') || (this.props.imageOf === 'PropertyR')){
          this.props.fetchPropertyImages(id)
          
        }
    

  } 
//**********************

show = (id,path) => {

  this.setState({ open: true, id:id, path:path });
};
//**********************

  handleConfirm = async () => {
    this.setState({ open: false});
    const  value = {path:this.state.path}
    const imageURL_F=this.state.path.substr(0, 23)
console.log('imageURL_F',imageURL_F)
     try {

        const resImage = await axios.post(`${imageURL_F}/api/ImageDelete`, value);

        if(resImage.data.msg ==='success'){
           const id=this.state.id ;

        const res= await axios.put(`/api/ImageDelete`, {id:id});
        if(res.data.msg ==='success'){
          const Id=this.props.id
          if((this.props.imageOf === 'ProjectC') || (this.props.imageOf === 'ProjectR')){
            this.props.fetchProjectImages(Id)
          }
          if((this.props.imageOf === 'PropertyC') || (this.props.imageOf === 'PropertyR')){
            this.props.fetchPropertyImages(Id)
            
          }
        toastr.success('Image Deleted Successfully')
        }
      }


    } catch (error) {
      console.log('error_deleteImage',error)
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }


  };

  handleCancel = () => this.setState({ result: "cancelled", open: false });
  //*********************

  rotate=()=>{
    let newRotation = this.state.rotation + 90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState({
      rotation: newRotation,
    })
  }

  rotateleft=()=>{
    let newRotation = this.state.rotation - 90;
    if(newRotation >= 360){
      newRotation =- 360;
    }
    this.setState({
      rotation: newRotation,
    })
  }
//**************** */
  imagePathAddHandler = (path) => {
    const id = this.props.id
    if(this.props.imageOf === 'ProjectC'){
      this.props.updateProjectCForAddImagePath(id, path);
    }
    if(this.props.imageOf === 'ProjectR'){
      this.props.updateProjectRForAddImagePath(id, path);
    }
    if(this.props.imageOf === 'PropertyC'){
      this.props.updatePropComForAddImagePath(id, path)
    }
    if(this.props.imageOf === 'PropertyR'){
      this.props.updatePropResiForAddImagePath(id, path)
    }
    
    
    };
//******************* */

renderList=(item)=> {

    return (
      <div key={item.path}>
  {/**/}
  <div className='disflex-paginator'>

  <br/>

  <Image
  cache='reload'
  alt="image_"
  style={{transform: `rotate(${this.state.rotation}deg)`}}
  src={`${item.path}`}
  />

  </div>

  {/**/}
  <div className='disflex-paginator' style={{color:'white'}}>

  <span className='icon2-button border1'
  onClick={() => this.imagePathAddHandler(item.path)}
  >
    {`${item.path.substr(64)}`}{' '}
    <Icon name='add' />
  </span>
  <span className='icon2-button border1' 
  onClick={this.props.closeModal}
  > <Icon name='close' />
  </span >
  <span
  onClick={() => this.show(item._id, item.path)}
  className="icon-button"
  >
  <img className= 'yimg ' src='/deleteIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
  </span>
  <span
  onClick={this.props.closeModal}
  className="icon-button"
  >
    <Link to={`/activity/ImageEdit/${item._id}`} >
    <img className= 'yimg ' src='/editIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </Link>
  
  </span>

  <div className='nodisplay'>
  <span
  onClick={this.rotateleft}
  className="icon-button"
  >
  <img className= 'yimg ' src='/rotateLeftIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
  </span>
  <span
  onClick={this.rotate}
  className="icon-button"
  >
  <img className= 'yimg ' src='/rotateRightIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
  </span>

  </div>

  </div>
  <br />



  {/**/}


      </div>
      )
    }
    //********* */
    images = () => {
      if((this.props.imageOf === 'ProjectC') || (this.props.imageOf === 'ProjectR')){
        return this.props.imagesPRT
      }
      if((this.props.imageOf === 'PropertyC') || (this.props.imageOf === 'PropertyR')){
        return this.props.imagesPRY
        
      }
      
      };
//^^^^^^^^^^^^^^^^^^^^^^^^^^^
  render() {
    const { open,  } = this.state;

    
const renderImages = ()=>{
  if(this.props.imagesPRY.length>0 || this.props.imagesPRT.length>0){
    return (
       this.images().map(this.renderList)

    )
  }else{
    return (
      <div className='card-noShow' >
       Oops ! No Image Available
      </div>
    )
  }
}
      //***** */
    return (
      <div className='main-div' >
              <div style={{padding:'15px'}}>
      {/**/}
      <Confirm
         header='You Want To Delete !'
        size="mini"
        open={open}
        onCancel={this.handleCancel}
        onConfirm={() => this.handleConfirm()}
      />
      {/**/}
<div >

      {/**/}
      <div className='margin-auto-H smalldiv border-radius-3'  >

       <div  style={{display: 'flex',flex: '70%',justifyContent: 'center',}} >
       <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
       All Images 
       </div>
       </div>

       <div
       style={{display: 'flex',flex: '30%',justifyContent: 'center',alignItems: 'center',}}
       onClick={this.props.closeModal} >
       <span className='icon-button border1' >Close</span >
       </div>

       </div>
       {/**/}
{/**/}
{renderImages()}
        {/* {this.props.images !== null && this.props.images.imagePaths.sort().map(this.renderList)} */}


        {/**/}

        </div>
        </div>
              </div>
    );

    
    //@@@
    
    
  }
}

const mapState = state => ({
  state,
  imagesPRT:state.images.imagesPRT,
  imagesPRY:state.images.imagesPRY,
});
export default connect(
  mapState,
  {closeModal,fetchProjectImages ,fetchPropertyImages,
    updateProjectCForAddImagePath,updateProjectRForAddImagePath,
    updatePropComForAddImagePath,updatePropResiForAddImagePath,
    fetchAppParameter
  }
)(ShowImages);
