import React, { Component } from "react";
import { connect } from "react-redux";
import {fetchFinancerPropComs} from "../../actions/propcomAction";
import PropComSummary from './PropComSummary';



class FinancerPropComList extends Component {
  

  componentDidMount() {
   const id = this.props.id;
    this.props.fetchFinancerPropComs(id);
  }
  
    

  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }
  renderNoProperty=()=> {
    if (this.props.propcomsFIN.length===0) {
      return (
        <div>
          <span style={{fontSize: "18px",color: "teal"}}>
            <b>NO PROPERTY AVAILABLE </b>
            </span>

        </div>
      );
    }
  }


  render() {

    return (


<div>


<div className='card' style={{textAlign:'center',padding:'0px',marginTop:'0px',backgroundColor:'dodgerblue',color:'white'}} >

<div className="heading-1"   >
<span>{this.props.countFIN} Commercial Properties Owned / Financed</span>
</div>
  
</div>

<div className = 'pxy-10'>
{this.renderNoProperty()}
{this.props.propcomsFIN.map((item)=> (<PropComSummary key={item._id} propcom={item}/>))}
</div>

</div>

    );
  }
}



const mapStateToProps = ({ propcoms,auth, }) => {
  const { fetching, countFIN,} = propcoms

  return {
    fetching,
    countFIN,
    //form: form.MyPropResiSaleSearch,
    propcomsFIN: propcoms.propcomsFIN,
    userA: auth.userA,

  };
};
export default connect(
  mapStateToProps,
 {fetchFinancerPropComs,}
)(FinancerPropComList);
