import axios from "axios";
import { toastr } from "react-redux-toastr";
import {openModal, closeModal } from "./modalAction";

import {
  FETCH_PROPCOMS,
  FETCH_PROPCOM,
  SEARCH_PROPCOMS,
  FETCHING_PROPCOMS,
} from "./types";





//search  PropComs SALE

export const searchPropComSales = (
  values,
  limitCS,
  offsetCS,
  
) => async dispatch => {

  try {
    const res = await axios.post("/api/PropComSaleSearch", {
    values,
    limitCS,
    offsetCS,
      
    });


    dispatch({ type: SEARCH_PROPCOMS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetchingCS:false} });
    dispatch(closeModal());
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//search  PropComs RENT

export const searchPropComRents = (
  values,
  limitCR,
  offsetCR,
    
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/PropComRentSearch", {
      values,
      limitCR,
  offsetCR,
       
    });

    
    dispatch({ type: SEARCH_PROPCOMS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetchingCR:false} });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again searchMyPropComs");
  }
};
//fetch individual  FinancerPropCom
export const fetchFinancerPropComs = (id) => async dispatch => {
  try {
    const res = await axios.post(`/api/FinancerPropComs`,{id:id});
    
    dispatch({ type: FETCH_PROPCOMS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetching:false} });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetch individual  FinancerPropCom for  ad book
export const fetchFinancerPropComsAd = (id) => async dispatch => {
  try {
    const res = await axios.post(`/api/FinancerPropComsAd`,{id:id});
    
    dispatch({ type: FETCH_PROPCOMS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetching:false} });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetch single PropCom
export const fetchPropCom = id => async dispatch => {
  try {
    const res = await axios.get(`/api/PropComDetail/${id}`);
   
    dispatch({ type: FETCH_PROPCOM, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
//#####################################################

//delete PropCom
export const deletePropCom = (id, ) => async () => {
  try {
    const res = await axios.delete(`/api/PropComDelete/${id}`);
    if (res.data.msg ==='success') {
      toastr.success('PropCom Deleted Successfully');
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//update PropCom
export const updatePropCom = (id, values) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/PropComEdit/${id}`, values);
    

    if (res.data.msg ==='success') {
      toastr.success('PropCom Updated Successfully');
    }
    dispatch({ type: FETCH_PROPCOM, payload: null });
  } catch (error) {
  
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropCom");
    }
  
  }
};
//************************

//update updatePropComForAddImagePath
export const updatePropComForAddImagePath = (id, path) => async () => {
  try {
    const res = await axios.put(`/api/PropComEditForAddImagePath`, {id:id, path:path});

    if(res.data.msg === 'success'){
      toastr.success('Image Path Added');
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//#####################################################
//update PropCom to INACTIVE
export const updatePropComToInactive = (id) => async dispatch => {
  try {
    const res = await axios.put(`/api/PropComInactive/${id}`);
  
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};


// create PropCom
export const createPropCom = (values) => async dispatch => {
  try {

    const res = await axios.post("/api/PropComCreate", {
      values
    });
    if (res.data.msg ==='success') {
      toastr.success('PropCom Created Successfully');
    }
      

  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
