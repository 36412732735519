import React, { Component } from "react";

import { connect } from "react-redux";
import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import {fetchStaticDealerAdByDealer} from "../../actions/staticAction";

import { openModal } from "../../actions/modalAction";
import {
  Icon,
} from "semantic-ui-react";



class StaticDealerAdByDealer extends Component {
  state = {
    pageO:'',
      };
      componentDidMount() {
        const { id } = this.props.match.params;
    
          const values={dealer:id}
        
        if (this.props.auth.isAuthenticated) {
         
          this.props.fetchStaticDealerAdByDealer(values);
        }else{
          
          this.props.openModal('LoginModalBySetInt')
        }
      }

  handleSearchStaticDealer = () => {
    this.props.openModal("SearchStaticDealerModal");
  };
  
    //***************************
    //^^^^^^^^^^^^^^^^^^^^^^^^^
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//***************************
  renderList=(item)=> {
    const {
      creditUsed,
      start,
       expire,
       adFor,
       adCategory,
       adLocation,
       adSubLocation,
       position,
       //dealerName,
       // dealer,   //id of dealer
      //****************** */
           
      _id
  
    } = item;


    //************************** render active/inactive/expired
const startOn= Date.parse(start)
const expireOn = (Date.parse(expire))
const now = Date.now()
//**************************


const renderStatus=() => {
  if ((now<startOn)){
    return (<span >
      Inactive
     </span>)
  }

  if( (now>startOn && now<expireOn )){
    return (<span >
       Active
     </span>)
  }
  if ((now>expireOn)){
  return (<span className='color-red'>
      Expired
     </span>)
  }
  return (<span >
    No Status
   </span>)
  }




    //const daysAgo = Math.round((Date.now()-Date.parse()/(24*60*60*1000));

    return (
<div key={_id}>

<div className='card' style={{padding:'10px',marginTop:'0px',border:'solid 2px dodgerblue',}} >
{/*ad detqils start*/}
<div ><h6 className='border-bottom'style={{marginTop:'5px',marginBottom:'5px'}}>{(adFor !== 'Dealer') && adCategory}{' '} {' '} {adFor}{' '}
At {adLocation}
<span style={{color:'dodgerblue',marginLeft:'20px'}}>{ renderStatus()}</span>
</h6></div>

<div style={{fontSize:'1rem'}}>
<div >
{start && (
<div className='row'>
<div className='side'>
<b>Duration</b>
</div>
<div className='main'>
					  <b>{ format(new Date(start), "dd-MM-yyyy")}{' '}{format(new Date(start), "HH:mm a")}{' '}To{' '}{ format(new Date(expire), "dd-MM-yyyy")}{' '}{format(new Date(expire), "HH:mm a")}{' '}   </b>
					</div>

</div>
)}
</div>

<div >
{creditUsed && (
<div className='row'>
<div className='side'>
<b>Credit Used</b>
</div>
<div className='main'>
<b>{ creditUsed}   </b>
</div>

</div>
)}
</div>



{/* {renderDealer()} */}
{/* static ad location start */}

<div >
{adFor && (
<div className='row'>
<div className='side'>
<b>Ad Location</b>
</div>
<div className='main'>
<b>

<div>
{(adFor !== 'Dealer') && adCategory && (

<span >
{adCategory}
</span>)

}{' '}

{adFor && (

<span >
{adFor}
</span>)

}{' '}

{adLocation && (

<span >
At {adLocation}
</span>)

}{' '}
{/*  */}
{((adLocation==='STATIC ALL')||(adLocation==='STATIC SECTOR')) 
&& adSubLocation && (
<span >
{adSubLocation}
</span>)

}{' '}
{position && (

<span >
On Position  {position}
</span>)

}{' '}
{/*  */}



</div>

</b>
</div>

</div>
)}
</div>
    {/* static ad location end */}


    </div>


    </div>
    </div>


    );
    }

    back = () => {
    const {
    offset,
    limit

    } = this.props;
    //const values = this.props.form.StaticSearch.values;
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
    return;
    }

    this.props.fetchStaticDealerAdByDealer(values, limit, (offset - limit));
    window.scroll(0,0)
    };

    advance = () => {
    const {
    offset,
    limit,
    count

    } = this.props;
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
    return;
    }

    this.props.fetchStaticDealerAdByDealer(values, limit, (offset + limit));
    window.scroll(0,0)
    };


    handleGoToPage = () => {
    const {

    limit,
    count

    } = this.props;

    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    // use Math.abs for strict equality ===  to work and safe guard against negative value from user
    if (Math.abs(this.state.pageO) === 0) {
    return; 
    }

    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)


    this.props.fetchStaticDealerAdByDealer(values, limit, (page * limit));
    window.scroll(0,0)

    }

    //****************** */
    renderPaginator=()=> {
    if (this.props.statics.length) {
    return (
    <Paginator
    advance={this.advance}
    back={this.back}
    offset={this.props.offset}
    limit={this.props.limit}
    count={this.props.count}
    //values={this.props.values}
    />
    );
    }
    }


    renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
    }


    render() {

    return (

    <div className='maindiv-820'>
    <div >
    {/*<div className='nodisplay'>aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
    aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa
    aaaaaaaaaaaa
    aaaaaaaaaaaaaaaaaaa</div>*/}

    <div>
      {/**/}
<div className='cashbook-H box-shadow'>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchStaticDealer}>
      <span className='icon-button border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
All Static Dealer Advertisement ({this.props.count})
</div>
</div>


</div>
{/**/}
    

    {this.renderLoading()}
    {this.props.statics.map(this.renderList)}
    {/*  */}
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
{/*  */}

    <br/><br/><br/><br/>

    <div></div>
    <div></div>
    </div>
    <div className='nodisplay'>
    {/* side grid space*/}
    </div>


    </div>
    </div>

    );
    }
    }


    const mapStateToProps = ({ statics, selection,auth, form }) => {
    const { fetching,limitD, offsetD, countD,} = statics;
 
    return {
    auth,
    limit:limitD,
    offset:offsetD,
    count:countD,
    fetching,
    form: form.StaticSearchDealer,
    selection,
    statics: statics.staticsDealer
    };
    };
    export default connect(
    mapStateToProps,
    {fetchStaticDealerAdByDealer,openModal}
    )(StaticDealerAdByDealer);
