import React, { Component } from "react";
import { connect } from "react-redux";
import {fetchFinancerPropResis} from "../../actions/propresiAction";
import PropResiSummary from './PropResiSummary';


class FinancerPropResiList extends Component {
  state = {
    id:'',
  };

  componentDidMount() {
   const id = this.props.id;
    this.props.fetchFinancerPropResis(id);
  }
  
    
  

  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }
  renderNoProperty=()=> {
    if (this.props.propresisFIN.length===0) {
      return (
        <div>
          <span style={{fontSize: "18px",color: "teal"}}>
            <b>NO PROPERTY AVAILABLE </b>
            </span>

        </div>
      );
    }
  }


  render() {

    return (


<div>


<div className='card' style={{textAlign:'center',padding:'0px',marginTop:'0px',backgroundColor:'dodgerblue',color:'white'}} >

<div className="heading-1"   >
<span>{this.props.countFIN} Residential Properties Owned / Financed</span>
</div>
  
</div>

<div className = 'pxy-05'>
{this.renderNoProperty()}
{this.props.propresisFIN.map((item)=> (<PropResiSummary key={item._id} propresi={item}/>))}
</div>

</div>

    );
  }
}



const mapStateToProps = ({ propresis,auth, }) => {
  const { fetching, countFIN,} = propresis

  return {
    fetching,
    countFIN,
    //form: form.MyPropResiSaleSearch,
    propresisFIN: propresis.propresisFIN,
    userA: auth.userA,

  };
};
export default connect(
  mapStateToProps,
 {fetchFinancerPropResis, }
)(FinancerPropResiList);
