	import React, { Component } from "react";
	import { connect } from "react-redux";
	import format from "date-fns/format";
	import { Link, withRouter } from "react-router-dom";
	import writtenNumber from "written-number";
	import {fetchReqCom,} from "../../actions/reqcomAction";
	import { getUserForAuth } from "../../actions/authAction";
	import Loading from "../common/Loading"; 
	import {
	Icon,
	Button,
	Confirm,
	} from "semantic-ui-react";
	

	class ReqComDetail extends Component {
	state = {
	id:'',
	open: false,
	result: "show the modal to capture a result"
	};
	componentDidMount() {
	const { id } = this.props.match.params;
	this.props.fetchReqCom(id);
	}
	show = () => {
	this.setState({ open: true });
	};
	handleConfirm = () => {
	
	this.setState({ result: "confirmed", open: false });
	
	const { id } = this.props.match.params;

	this.props.updateReqComToInactive(id, this.props.history);
	};
	handleCancel = () => this.setState({ result: "cancelled", open: false });

	
	
	//***************************BACK BUTTON */



	capitalizeFirst=(input)=> {
	var words = input.split(' ');
	var CapitalizedWords = [];
	words.forEach(element => {
	CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
	});
	return CapitalizedWords.join(' ');
	}

	//********************************* */
	render() {
	const { open,  } = this.state;
	if (!this.props.reqcom) {
		return (<Loading/>)
	}
	const {

	creditUsed,
	veiwLimit,
	dealers,
	requiredFor,
	propertyType,
	furnishedStatus,
	entry,
	floorNo,
	projectName,
	locality,
	city,
	budgetMin,
	budgetMax,
	areaCarpetMin,
	areaCarpetMax,
	washroomCommon,
	washroomPersonal,
	pantryPersonal ,
	liftsInTheTower,
	powerBackUp,
	parking,
	reserveParking,
	visitorParking,
	note,
	createdAt,
	reqcomStatus,
	_id
	} =
	this.props.reqcom !== null && this.props.reqcom;

	
	
	//**************************

	const renderpropertyType = () => {
	return propertyType.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};



	const renderfurnishedStatus = () => {
	return furnishedStatus.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderentry = () => {
	return entry.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderfloorNo = () => {
	return floorNo.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderprojectName = () => {
	return projectName.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};

	const renderlocality = () => {
	return locality.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} |</b>
	</span>
	);
	});
	};
	const renderButton = () =>
	  this.props.userA !== null &&
	  (this.props.userA.propRSDelete === "Yes" ||
		this.props.userA.userType === "admin") ? (
		<Button
		  // basic

		  inverted
		  floated="right"
		  size="mini"
		  color="red"
		  type="button"
		  onClick={() => this.show(_id)}
		>
		  Delete
		</Button>
	  ) : (
		<div></div>
	  );
	//******************


	return (
	<div>
	<div className='maindiv-820'>


	<div>


	<Confirm
	size="mini"
	open={open}
	onCancel={this.handleCancel}
	onConfirm={() => this.handleConfirm()}
	/>

	
	{/* */}
	
	{/*  */}
	
	
	
	<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Requirement For Commercial Property For {requiredFor}</span>
	</div>

	</div>

	<div className='card-attached border-sb border-db-bottom pxy-20'  >
	{/*  */}
	<div>
		{/*  */}
	
		<Button.Group size="mini" floated="right">

				  <Button
					// basic
					inverted
					floated="right"
					size="mini"
					color="green"
					type="button"
					as={Link}
					to={`/reqcom/ReqComEdit/${_id}`}
				  >
					Edit
				  </Button>
				  {renderButton()}
				</Button.Group>
 {/*  */}
	
	 
	<br />
	</div>
	{/*  */}

	<div>
	</div>

	{/*  */}
	<div className='border-bottom'  style= {{marginTop:'10px',marginBottom:'10px',}}>
	<div  className="heading" style= {{marginBottom:'10px'}} >
	<span >
	Requirement Detail 
	</span>
	</div>
	</div>
	{/*  */}
	<div >
	{requiredFor && (
	<div className='row'>
	<div className='side'>
	<b>Require For</b>
	</div>
	<div className='main'>
	<b>{requiredFor}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{propertyType != null &&
	propertyType.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Type Of Property</b>
	</div>
	<div className='main'>
	{renderpropertyType()}
	</div>

	</div>
	)}
	</div>

	<div >
	{furnishedStatus != null &&
	furnishedStatus.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Furnished Status</b>
	</div>
	<div className='main'>
	{renderfurnishedStatus()}
	</div>

	</div>
	)}
	</div>
	{/**/}
	<div >
	{washroomCommon && (
	<div className='row'>
	<div className='side'>
	<b>Common Washroom</b>
	</div>
	<div className='main'>
	<b>{washroomCommon}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{washroomPersonal && (
	<div className='row'>
	<div className='side'>
	<b>Personal Washroom</b>
	</div>
	<div className='main'>
	<b>{washroomPersonal}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{pantryPersonal && (
	<div className='row'>
	<div className='side'>
	<b>Personal Pantry</b>
	</div>
	<div className='main'>
	<b>{pantryPersonal}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{powerBackUp && (
	<div className='row'>
	<div className='side'>
	<b>Power BackUp</b>
	</div>
	<div className='main'>
	<b>{powerBackUp}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{liftsInTheTower && (
	<div className='row'>
	<div className='side'>
	<b>Lifts In The Tower</b>
	</div>
	<div className='main'>
	<b>{liftsInTheTower}</b>
	</div>

	</div>
	)}
	</div>



	<div >
	{parking && (
	<div className='row'>
	<div className='side'>
	<b>Car Parking</b>
	</div>
	<div className='main'>
	<b>{parking}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{reserveParking && (
	<div className='row'>
	<div className='side'>
	<b>Reserve Car Parking</b>
	</div>
	<div className='main'>
	<b>{reserveParking}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{visitorParking && (
	<div className='row'>
	<div className='side'>
	<b>Visitor Parking</b>
	</div>
	<div className='main'>
	<b>{visitorParking}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{floorNo != null &&
	floorNo.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Floor No</b>
	</div>
	<div className='main'>
	{renderfloorNo()}
	</div>

	</div>
	)}
	</div>

	<div >
	{entry != null &&
	entry.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Entry</b>
	</div>
	<div className='main'>
	{renderentry()}
	</div>

	</div>
	)}
	</div>

	{/**/}


	<div >
	{budgetMin > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Minimum Budget</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {budgetMin}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(budgetMin, {
	lang: "enIndian"
	}))}
	){" "}
	</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{budgetMax > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Maximum Budget</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {budgetMax}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(budgetMax, {
	lang: "enIndian"
	}))}
	){" "}
	</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{areaCarpetMin > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Minimum Carpet Area</b>
	</div>
	<div className='main'>

	<b> {areaCarpetMin}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMin, {
	lang: "enIndian"
	}))}
	){" "} sq ft
	</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{areaCarpetMax > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Maximum Carpet Area</b>
	</div>
	<div className='main'>

	<b> {areaCarpetMax}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMax, {
	lang: "enIndian"
	}))}
	){" "} sq ft
	</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{projectName  != null &&
	projectName.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Complex/Project Name</b>
	</div>
	<div className='main'>
	{renderprojectName ()}
	</div>

	</div>
	)}
	</div>

	<div >
	{locality != null &&
	locality.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Locality</b>
	</div>
	<div className='main'>
	{renderlocality()}
	</div>
	</div>
	)}
	</div>

	<div >
     {city && (
       <div className='row'>
         <div className='side'>
           <b>City/District</b>
         </div>
         <div className='main'>
           <b>{city}</b>
         </div>

       </div>
     )}
   </div>

	<div >
	{createdAt && (
	<div className='row'>
	<div className='side'>
	<b>Created On</b>
	</div>
	<div className='main'>

	<b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{reqcomStatus && (
	<div className='row'>
	<div className='side'>
	<b>reqcomStatus</b>
	</div>
	<div className='main'>
	<b>{reqcomStatus}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{note && (
	<div className='row'>
	<div className='side'>
	<b>Note/Comments</b>
	</div>
	<div className='main'>
	<b>{note}</b>
	</div>

	</div>
	)}
	</div>


	{/* other rent details  END*/}



	</div>


	</div>
	


	</div>




	</div>

	//**********************************



	);
	}
	}
	function mapStateToProps(state) {

	return {
	reqcom: state.reqcoms.reqcom,
	userA: state.auth.userA
	};
	}

	export default connect(
	mapStateToProps,
	{fetchReqCom,getUserForAuth}
	)(withRouter(ReqComDetail));
