import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formPassReset } from '../../actions/authAction';
import {
  Button,
  Form,
  Grid,
  Header,
  Segment
} from 'semantic-ui-react';

class FormPassReset extends Component {
  state = {
    uemail: '',
    error: false
  };
  

onSubmit =  async(e) =>  {
 
    e.preventDefault();
    //this.ValidateEmail(this.state.uemail)
    const mailformat = /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const match= mailformat.test(this.state.uemail)
   
    
   //const err= await this.setState({error : !match });
    
    const userData = {
      uemail: this.state.uemail,
     };
     if(this.state.error===false){
   
      this.props.formPassReset(userData)
    
    this.setState({uemail: '' });
    }
    

};



  onChange = e => {
  
    this.setState({ [e.target.name]: e.target.value });
     this.setState({error : false });
    
  };

  render() {
    
    const { error } = this.state;
 
    return (
      <Grid
        textAlign="center"
        style={{ height: '100%' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450, margin: 100 }}>
        <Segment
          textAlign="center"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
          <Header> Reset Your Password </Header>
        </Segment>
        <Segment
          textAlign="center"
          //inverted
         // color="teal"
          style={{ border: "none" }}
        >
          <Header> Please enter your Registered Email and A Password Reset link will be sent to
          your email.</Header>
        </Segment>
         
          
          <Form size="large" onSubmit={this.onSubmit}>
          <Segment stacked style={{ textAlign:"left" }}>
              
              <label>Enter Your Registered Email</label>
                <Form.Input
                  name="uemail"
                  type="email"
                  value={this.state.uemail}
                  onChange={this.onChange}
                  //error={errors.email}
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder="Enter Your Registered Email"
                />
                <div>
                {
                  error === true && (
                    
                      <span style={{color:'red',fontSize:'16px'}}>
                        <b>Please enter a valid email address!</b>
                      </span>
                     
                    
                  )}
              </div>
                <Button color="teal" fluid size="large">
                  Reset Password
                </Button>
              
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}


export default connect(
  null,
  { formPassReset }
)(FormPassReset);

//export default FormPassReset;
