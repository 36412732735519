module.exports  ={
  //api:"https://floating-sands-33943.herokuapp.com/api",
  api:"http://192.168.1.7:5014/api",
  imageURL:"http://localhost:5014",
  //imageURL:"http://192.168.1.7:5018",
  //imageURL:"http://192.168.1.106:5014"
}

//module.exports = require('./keys_dev');

// if (process.env.NODE_ENV === 'production') {
//   module.exports = require('./keys_prod');
// } else {
//   module.exports = require('./keys_dev');
// }
   