import React, { Component } from "react";
import { connect } from "react-redux";
//import { Grid ,Button,Icon } from "semantic-ui-react";
import { Switch, Route, } from "react-router-dom";
import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import ProjectCCreate from "./ProjectCCreate";
import ProjectCEdit from "./ProjectCEdit";
import ProjectCDetail from "./ProjectCDetail";
//import AddImage from "./AddImage";

import ProjectCSearchList from "./ProjectCSearchList";

import Layout from "../layout/Layout";

 

class ProjectCDashboard extends Component {
  componentDidMount() {
    this.props.getUserForAuth();
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };

  render() {

    return (
    <div>
    <div className='maindiv'>

    <Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
    
      <Route
        exact
        path="/projectC/ProjectCCreate"
        component={ProjectCCreate}
      />
      <Route
        exact
        path="/projectC/ProjectCDetail/:id"
        component={ProjectCDetail}
      />

      <Route
        exact
        path="/projectC/ProjectCEdit/:id"
        component={ProjectCEdit}
      />


      <Route
        exact
        path="/projectC/ProjectCSearchList"
        component={ProjectCSearchList}
      />

    </Switch>
        </Layout >
        </div>

    <div >
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>
      );


}
}
// const mapStateToProps = state => ({
// auth: state.auth
// });


export default connect(
  // mapStateToProps,
  null,
  {showBackButton, getUserForAuth, logoutUser  }
)(ProjectCDashboard);
