import {
  FETCH_PROJECTCS,
  FETCH_PROJECTC,
  SEARCH_PROJECTCS,
  FETCHING_PROJECTCS,
} from '../actions/types';

const INITIAL_STATE = {
  city:'', 
  fetching:true,
  projectCs: [],
  projectCsForData :[],
  projectC: null,
  offset: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case FETCH_PROJECTCS:
      return { ...state, ...action.payload };
    case FETCH_PROJECTC:
      return { ...state, projectC: action.payload };
    
    case SEARCH_PROJECTCS:
     
      return { ...state, ...action.payload };
   
    case FETCHING_PROJECTCS:
      return { ...state, fetching: action.payload };
    default:
      return state;
  }
};
