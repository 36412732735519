import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import {fetchContactReqsByParty,} from "../../actions/contactReqAction";


import { openModal } from "../../actions/modalAction";
import {
  Icon,
  Button,
} from "semantic-ui-react";



class ContactReqByPartyList extends Component {
  state = {
    id: "",
  };

  componentDidMount() {
    const {offset, limit} = this.props;
    const {id} = this.props.match.params;
    const val =
      (this.props.form !== undefined && this.props.form.values) || {};
      const values ={...val,id}
      
      if (this.props.auth.isAuthenticated) {
     
        this.props.fetchContactReqsByParty(values, limit,offset);
      }else{
        
        this.props.openModal('LoginModal')
      } 
  
    
  }


//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  handleSearchContactReqsByParty = () => {
    this.props.openModal("SearchContactReqsByPartyModal");
  };

  renderList=(contactReq)=> {
    const {
      requestFor,
      dealer,
      dealerName,
      propOrProjId,
      propertyFor,
      propertyType,
      bedroom,
      additionalRooms,
      expectedPrice,
      expectedRent,
      carpetArea,
      area,
      areaUnit,
      projectType,
      projectName,
      //	projectId,
      locality,
      createdAt,

      _id
    } = contactReq;
//**************************

const renderDetailButton=() => {
  if(requestFor==="Property" && projectType==='Residential' ){
    return (<Button
      inverted
     //floated="right"
     size="mini"
     color="blue"
       type="button"
       as={Link}
       to={`/propresi/PropResiDetail/${propOrProjId}`}
     >
      Detail
     </Button>)
  }
  if(requestFor==="Property" && projectType==='Commercial' ){
    return (<Button
      inverted
     //floated="right"
     size="mini"
     color="blue"
       type="button"
       as={Link}
       to={`/propcom/PropComDetail/${propOrProjId}`}
     >
      Detail
     </Button>)
  }
  if(requestFor==="Project" && projectType==='Residential' ){
    return (<Button
      inverted
     //floated="right"
     size="mini"
     color="blue"
       type="button"
       as={Link}
       to={`/projectR/ProjectRDetail/${propOrProjId}`}
     >
      Detail
     </Button>)
  }
  if(requestFor==="Project" && projectType==='Commercial' ){
    return (<Button
      inverted
     //floated="right"
     size="mini"
     color="blue"
       type="button"
       as={Link}
       to={`/projectC/ProjectCDetail/${propOrProjId}`}
     >
      Detail
     </Button>)
  }
          


        }


    //**************************
      const renderadditionalRooms = () => {
      return additionalRooms.map(item => {
      return (
      <span key={item}>
      {" "}
      {item}|
      </span>
      );
      });
      };
      const price = (value) => {
      var val = Math.abs(value)
      if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
      } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' Lac';
      }
      /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
      return val;
      }

    //const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));

    return (
      <div key={_id}>
            <div className='card' style={{padding:'10px',marginTop:'0px'}} >
{/*requester detqils start*/}
<div><div className='border-bottom'><b>Contact Request Details
<span style={{color:'dodgerblue',marginLeft:'20px'}}>{ format(new Date(createdAt), "dd-MM-yyyy")}</span></b>


</div></div>

        <div >
        {dealerName && (
        <div className='row'>
        <div className='side'>
        <b>DEALER NAME</b>
        </div>
        <div className='main'>
        <b>{dealerName}{'  '}
        <Button
        inverted
        //floated="right"
        size="mini"
        color="blue"
        type="button"
        as={Link}
        to={`/company/company/${dealer}`}
        >
        Detail
        </Button>
        </b>
        </div>

        </div>
        )}
        </div>


        <div >
        {projectName && (
        <div className='row'>
        <div className='side'>
        <b>REQUEST SENT FOR</b>
        </div>
        <div className='main'>
        <b>

        <div>
        {bedroom && (

        <span >
        {bedroom} BHK
        </span>)

        }{' '}
        {additionalRooms.length >0 && (

        <span >
        {renderadditionalRooms()}
        </span>)

        }{' '}
        {carpetArea && (

        <span >
        {carpetArea} sq ft
        </span>)

        }{' '}
        {area && (

        <span >
        {area}
        </span>)

        }{' '}
        {areaUnit && (

        <span >
        {areaUnit}
        </span>)

        }{' '}
        {propertyType && (

        <span >
        {propertyType}
        </span>)

        }{' '}
        {propertyFor && (

        <span >
        For  {propertyFor}
        </span>)

        }{' '}
        {expectedPrice>0 && (

        <span >
        For  <Icon className="rupee" ></Icon>
        {price(expectedPrice)}{' '}In
        </span>)

        }{' '}
        {expectedRent>0 && (

        <span >
        For  <Icon className="rupee" ></Icon>
        {expectedRent}{' '}In
        </span>)

        }{' '}
        {projectName && (

        <span >
        {' '}  {projectName}
        </span>)

        }{' '}
        {locality && (

        <span >
        {locality} Dwarka, New Delhi
        </span>)

        }{' '}
        {renderDetailButton()}
        </div>

        </b>
        </div>

        </div>
        )}
        </div>

    
    

            </div>
      </div>


    );
  }

  back = () => {
    const {
      offset,
      limit
      
    } = this.props;
    //const values = this.props.form.ProjectSearch.values;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
      return;
    }

    this.props.fetchContactReqsByParty(values, limit, (offset - limit));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
      limit,
      count
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
      return;
    }

    this.props.fetchContactReqsByParty(values, limit, (offset + limit));
    window.scroll(0,0)
  };

  renderPaginator() {
    if (this.props.contactReqs.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
          //values={this.props.values}
        />
      );
    }
  }


  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }


  render() {
    const { open,  } = this.state;
    return (

      <div className='maindiv-820'>
      
  <div >
  

  <div>
  
{/**/}
<div className='cashbook-H box-shadow'>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchContactReqsByParty}>
      <span className='icon-button border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.count} {''}Contact Requests Sent
</div>
</div>


</div>
{/**/}
  {this.renderLoading()}
  {this.props.contactReqs.map(this.renderList)}

  {this.renderPaginator()}
  <br/><br/>
  <div></div>
  <div></div>
  </div>
  <div className='nodisplay'>
  {/* side grid space*/}
  </div>


  </div>
  </div>

    );
  }
}


const mapStateToProps = ({auth, contactReqs, selection, form }) => {
  const { fetching,limit, offset, count,} = contactReqs;

  return {
    auth,
    limit,
    offset,
    count,
    fetching,
    form: form.SearchContactReqsByParty,
    selection,
    contactReqs: contactReqs.contactReqs
  };
};
export default connect(
  mapStateToProps,
  {fetchContactReqsByParty,openModal}
)(ContactReqByPartyList);
