import {
  SEARCH_COMPANYS,
  FETCH_COMPANYS,
  FETCH_COMPANY,
  FETCHING_COMPANYS,
} from '../actions/types';

const INITIAL_STATE = {
  city:'', 
  fetching:true,
  companys: [],
  companysForData: [],
  staticTop1:[],
  staticTop2:[],
  staticBottom1:[],
  staticBottom2:[],
  company: null,
  offset: 0,
  offseC: 0,
  offsetStatic: 0,
  limit: 10,
  limitC: 10,
  limitStatic: 10,
  propreqCount:0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_COMPANYS:
      return { ...state, ...action.payload };

    case FETCH_COMPANYS:
      return { ...state, ...action.payload };
    case FETCH_COMPANY:
      return { ...state, company: action.payload };
        case   FETCHING_COMPANYS:
          return { ...state, fetching: action.payload };
    default:
      return state;
  }
};
