import React, { Component } from 'react';
import { Icon,
  //Image
 } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import { closeModal } from '../../actions/modalAction';
import {fetchProjectImages ,fetchPropertyImages} from "../../actions/imageAction";


class ShowImages extends Component {
  
  componentDidMount = () =>{
    const id = this.props.id
    if(this.props.imageOf === 'Project'){
      this.props.fetchProjectImages(id)
    }
    if(this.props.imageOf === 'Property'){
      this.props.fetchPropertyImages(id)
    }

  } 

  render() {
    
    const  images = () => {
      if(this.props.imageOf === 'Project'){
        return this.props.imagesPRT.map(value => ({
          original: `${value.path}`,
          //thumbnail:`${value.path}`,
          description: value.imageOf,
          }));
      }
      if(this.props.imageOf === 'Property'){
        return this.props.imagesPRY.map(value => ({
          original: `${value.path}`,
          //thumbnail:`${value.path}`,
          description: value.imageOf,
          }));
      }
      
      };
const renderImages = ()=>{
  if(this.props.imagesPRY.length>0 || this.props.imagesPRT.length>0){
    return (
<ImageGallery items={images()} showPlayButton={false} showThumbnails = {false}  />
    )
  }else{
    return (
      <div className='card-noShow' >
       Oops ! No Image Available
      </div>
    )
  }
}
      //***** */
    return (
      <div
      style = {{
        borderRadius: 3,
        fontSize:"16px",
        fontWeight:"bold",
        //background:'transparent',
         backgroundColor:'black',
        margin:'0px',
        padding: '0px',
      }}
      >
        <div className='disflex-paginator'>
        <span className='icon-button border1 '
        onClick={this.props.closeModal}
        style={{color:'white'}}
        > 
        <Icon name='close' />
        </span >
        </div>
        {renderImages()}
        </div>    
    );
  }
}

const mapState = state => ({
  state,
  imagesPRT:state.images.imagesPRT,
  imagesPRY:state.images.imagesPRY,
});
export default connect(
  mapState,
  {closeModal,fetchProjectImages ,fetchPropertyImages}
)(ShowImages);
