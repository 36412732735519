import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
//import { shortlistAddRemoveCom } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import {
  Icon,
  Button,
} from "semantic-ui-react";



class PropComSummary extends Component {
  state = {
    id:'',
  };
  
  // shortlistAddRemoveHandler = (id) => {
  //   if (!this.props.userA) {
  //     this.props.openModal('LoginModal')

  //   }else{
  //     this.props.shortlistAddRemoveCom(id);
  //   }

  // };
  handleOpenModalImage = (id,imageOf) => {   
    this.props.openModal("ShowImagesModal" , {id:id,imageOf:imageOf});
  };
  // handleOpenModalContact = (id,projectType) => {   
  //   this.props.openModal("ContactDetailForPropListModal" , {id:id,projectType:projectType});
  // };
  

//*******************************************************

  renderPropertySummary=()=> {
    const {
      imagePath,
   
    propertyFor,
    category,
    propertyType,
   
    carpetArea,
    furnishedStatus,
    expectedPrice,
    expectedRent,
    floorNo,
  status,
   // projectType,
    projectName,
    locality,
    locationOnMap,
    createdAt,
    _id,
    propcomStatus,
    //********* */
    // createdAtProp,
    // propOrProjId,
    } = this.props.propcom;

const id = this.props.propcom.propOrProjId || this.props.propcom._id;
//const createdOn = propcom.createdAtProp || propcom.createdAt

    

   // const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));
    // const checkID = this.props.userA !== null && this.props.userA.shortlistCom !==undefined &&  this.props.userA.shortlistCom.filter(val => {

    //   return val.toString() === id;
    // });
    // const renderShortlistButton = () =>
    //       checkID.length > 0 ? (
    //     <Button

    //       size="mini"
    //       floated="right"
    //       color="black"
    //       onClick={() => this.shortlistAddRemoveHandler(id)}
    //     >
    //       Remove From Shortlist
    //     </Button>
    //   ) : (
    //     <Button
    //       size="mini"
    //       floated="right"
    //       color="black"
    //       onClick={() => this.shortlistAddRemoveHandler(id)}
    //     >
    //       Add To Shortlist
    //     </Button>
    //   );

  //******************
  const setInactiveBgColor= () =>{
    
    if(propcomStatus === 'inactive'){
  return {backgroundColor:'#ffb3b3'}
    }
}
//**************** */

const price = (value) => {
   var val = Math.abs(value)
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
  }
  /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
  return val;
}

const classN = (this.props.nameClass ==='static'? 'cardStatic-1':'card-1')
const classBG = (this.props.nameClass ==='static'? 'card border cardBackground':'card border ')

    return (
<div key={_id}  style={{margin:'5px'}}>




                <div className={classBG}  style={{padding:'0px',borderRadius:'5px',...setInactiveBgColor()}} >

                  <span className="yribbon1">
                    <span style={{ fontSize:'14px',color:'white'}}>
                    <Icon className="rupee" ></Icon>
                  <b>{(expectedPrice >0 && price(expectedPrice)) ||
                  (expectedRent >0 && price(expectedRent))
                  }</b>
                    </span>
                  </span>

                {/* property summary section + image section */}
                <div className={classN} style={{padding:'10px',borderRadius:'5px',...setInactiveBgColor()}} >

                  {/* property summary section  */}
            <div className='flex-value-B 'style={{paddingRight:'10px',margin: 'auto'}}>


            <div>{carpetArea >0 && (
                <span  className='bedroom'>
                  {carpetArea} sq ft
                </span>
            )}

            {furnishedStatus && (
                <span className='furnishedStatus'>
                 { ' '} {furnishedStatus}{ ' '}
                </span>
            )}{ ' '}
            {floorNo && (
                <span className='floorNo'>
                 { ' '} {floorNo} Floor{ ' '}
                </span>
            )}{ ' '}
            {status && (
                <span className='status'>
                 { ' '} {status}{ ' '}
                </span>
            )}{ ' '}
              
              {propertyType && (
                <span>
                  <span className='propertyType'>
                  {' '} {propertyType}{' '}
                  </span>
                  {' '}<span className='bathroom'>in</span>
                  <span >
                    { ' '}
                  </span></span>
              )} { ' '}
            {category && (
                <span className='category'>
                  {' '}{category}<span className='bathroom' > for</span> {' '}
                </span>
            )}{ ' '}

            {propertyFor && (
              <span>
                <span className='propertyFor'>
                {' '}{propertyFor}{' '}
                </span>
                </span>
            )} { ' '}

            </div>  {/* for property type*/}

{/* project and location section for desktop */}
<div className=' nodisplay'  >
           <div>
             {projectName && (
                <div className='projectName'>
                  {projectName}</div>
            )}
            </div>  {/* for project name*/}

            <div className='locality'>{locality && (
              <span>
              <a target='_blank' href={`${locationOnMap}`}>
                <span >
                  {locality} Dwarka New Delhi{' '}
                </span>

                  <span style={{fontSize:'1.0rem',color:'blue' }}>
                  {' '}<img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"16px", height:"16px"}} />
                  </span>
                  </a>
                  </span>

            )}
            </div>  {/* for location and city etc.*/}
          
            </div>


            <div className='display-flex nodisplay'  >

                <Button
               // inverted
                floated="right"
                size="mini"
                color="teal"
                  // basic
                  // inverted

                  type="button"
                  as={Link}
                //  target='_blank'
                  to={`/propcom/PropComDetail/${id}`}
                >
                Property Detail
                </Button>
                {/* <Button
                  // basic

                 // inverted
                  floated="right"
                  size="mini"
                  color="blue"
                  type="button"
                  onClick={()=>this.handleOpenModalContact(id, 'Com')}
                >
                  Contact Detail
                </Button> */}
               {/* { renderShortlistButton()} */}

              </div>

                  </div>
                  {/* end property summary section  */}
                  {/* image section */}
                  <div className='flex-value-A' style={{display:'flex',flexDirection:'column'}}>

                  <div onClick={()=>this.handleOpenModalImage(id,'Property')}>
                  <img className= 'yimg image-respo' src={`${imagePath}`} alt="Display_Image" width="170" height="128" />
            </div>
            <div style={{display:'flex',fontSize:'.75rem',fontWeight: 'normal'}}>
            <div>{createdAt && (

                <span>  Advertised On
                   <span> { format(new Date(createdAt), "dd-MM-yyyy")}</span>
            </span>

            )}
            </div>
            </div>
                  </div>
                  {/*end image section  */}

                  </div>
{/* project and location section for mobile */}
                  <div className='nodisplay-tab pxy-10'>

                  <div>
             {projectName && (
                <div className='projectName'>
                  {projectName}</div>
            )}
            </div>  {/* for project name*/}

            <div className='locality'>{locality && (
              <span>
              <a target='_blank' href={`${locationOnMap}`}>
                <span >
                  {locality} Dwarka New Delhi{' '}
                </span>

                  <span style={{fontSize:'1.0rem',color:'blue' }}>
                  {' '}<img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"16px", height:"16px"}} />
                  </span>
                  </a>
                  </span>

            )}
            </div>  {/* for location and city etc.*/}
                  </div>
                  {/*  */}
                  <div className='display-flex nodisplay-tab' style={{marginBottom:'5px'}}  >

                <Button
               // inverted
                floated="right"
                size="mini"
                color="teal"
                  // basic
                  // inverted

                  type="button"
                  as={Link}

                  to={`/propcom/PropComDetail/${id}`}
                >
                Property Detail
                </Button>
                {/* <Button
                  // basic

                 // inverted
                  floated="right"
                  size="mini"
                  color="blue"
                  type="button"
                  onClick={()=>this.handleOpenModalContact(id,'Com')}
                >
                  Contact Detail
                </Button> */}
                {/* { renderShortlistButton()} */}

              </div>
                  </div>
</div>
    );
  }

  // ********** 

  render() {
  
    return (

<div >


{this.renderPropertySummary()}

</div>



    );
  }
}


const mapStateToProps = ({ auth }) => {
 
  return {  
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {openModal,}
)(PropComSummary);



