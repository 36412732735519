import React, { Component } from "react";
import { Link, } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import Loading from "../common/Loading"; 
import {searchCreditAdds} from "../../actions/creditAddAction";
import writtenNumber from "written-number";
import { openModal } from "../../actions/modalAction";
import {
  Icon,Button
} from "semantic-ui-react";


class CreditAddSearchList extends Component { 

  componentDidMount() {
    const {offset, limit} = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
  
    this.props.searchCreditAdds(values, limit,offset);
  }

  handleSearchCreditAdd = () => {
    this.props.openModal("SearchCreditAddModal");
  };
  capitalizeFirst=(input)=> {
    var words = input.split(' ');
    var CapitalizedWords = [];
    words.forEach(element => {
      CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    });
    return CapitalizedWords.join(' ');
  }
  renderList=(item)=> {
          const {
          creditType,
          creditAdd,
          amountPaid,
          paymentDetail,
          expiredOn,
          createdAt,

          _id
          } = item;

    
    return (
        <div key={_id}>
        <div className='card' style={{padding:'10px',marginTop:'0px',border:'solid 2px dodgerblue',}} >
        <Button
  // basic
  inverted
  floated="right"
  size="mini"
  color="green"
  type="button"
  as={Link}
  to={`/activity/CreditAddEdit/${_id}`}
>
  Edit
</Button>
        <div ><h6 className='border-bottom'style={{marginTop:'5px',marginBottom:'5px'}}>Credit Details
        <span style={{color:'dodgerblue',marginLeft:'20px'}}></span>
        </h6></div>
        <div style={{fontSize:'1rem'}}>
        <div >

        {creditType && (
        <div className='row'>
        <div className='side'>
        <b>CREDIT FOR</b>
        </div>
        <div className='main'>
        <b>{creditType}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {creditAdd && (
        <div className='row'>
        <div className='side'>
        <b>CREDIT ADDED</b>
        </div>
        <div className='main'>
        
					  <b> {creditAdd}</b>
					  <b>
					   {' '} ({this.capitalizeFirst(writtenNumber(creditAdd, {
						  lang: "enIndian"
						}))}
						){" "}
					  </b>
        
        </div>

        </div>
        )}
        </div>

        <div >
        {amountPaid && (
        <div className='row'>
        <div className='side'>
        <b>AMOUNT PAID</b>
        </div>
        <div className='main'>
        <Icon className="rupee" ></Icon>
					  <b> {amountPaid}</b>
					  <b>
					   {' '} ({this.capitalizeFirst(writtenNumber(amountPaid, {
						  lang: "enIndian"
						}))}
						){" "}
					  </b>
       
        </div>

        </div>
        )}
        </div>

        <div >
        {paymentDetail && (
        <div className='row'>
        <div className='side'>
        <b>PAYMENT DETAIL</b>
        </div>
        <div className='main'>
        <b>{paymentDetail}</b>
        </div>

        </div>
        )}
        </div>

        <div >
        {expiredOn && (
        <div className='row'>
        <div className='side'>
        <b>EXPIRED ON</b>
        </div>
        <div className='main'>
        <b>{format(new Date(expiredOn), "dd-MM-yyyy")}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {createdAt && (
        <div className='row'>
        <div className='side'>
        <b>ADDED ON</b>
        </div>
        <div className='main'>
        <b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
        </div>

        </div>
        )}
        </div>


        </div>


        </div>
        </div>


        );
        }

  

  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }


  render() {

    return (

      <div className='maindiv-820'>
  <div >
  

  <div>

  <div className='card-attached border-radius-top border-sb' style={{textAlign:'center',backgroundColor:'dodgerblue'}} >
          {/**/}
          <div style={{display:'flex',color:'white',padding:'5px'}}>
      
      <div
      style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
      onClick={this.handleSearchCreditAdd}>
            <span className='icon-button border1' > <Icon name='search' /></span >
          </div>
      
      
      
      <div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
      <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
      {this.props.count} All Credit Added
      </div>
      </div>
      
      
      </div>
      {/**/}
      </div>
      

  {/*  */} 
  

  {this.renderLoading()}
  {this.props.creditAdds.map(this.renderList)}

  
  <br/><br/>
  
  </div>
 


  </div>
  </div>

    );
  }
}


const mapStateToProps = ({ creditAdds, selection, form,auth, }) => {
  const { fetching,limit, offset, count,} = creditAdds;

  return {
    
    limit,
    offset,
    count,
    fetching,
    form: form.CreditAddSearch,
    selection,
    creditAdds: creditAdds.creditAdds,
    userA:auth.userA
  };
};
export default connect(
  mapStateToProps,
  {searchCreditAdds,openModal}
)(CreditAddSearchList);
