import React, { Component } from 'react';
import { withRouter} from "react-router-dom";
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {updateImage,fetchImage,resetImage} from '../../actions/imageAction';

import {  Form, Button } from 'semantic-ui-react';
//import TextInput from '../form/TextInput';
import TextArea from '../form/TextArea';
import SelectInput from '../form/SelectInput';
import { imageOfProperty,imageOfProject, belongToOption, } from "./DataImg";



class ImageEdit extends Component {

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchImage(id);
  }


   onSubmit = values => {
    const { id } = this.props.match.params;
    this.props.updateImage(id, values);
    this.props.history.goBack()
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
    this.props.resetImage()
  }
  render(){
    const { handleSubmit, pristine, reset, submitting } = this.props;
    const { belongTo } =
      this.props.state.form &&
      this.props.state.form.ImageEdit !== undefined &&
      this.props.state.form.ImageEdit.values !== undefined &&
      this.props.state.form.ImageEdit.values;
    
     const imageOfOption=()=>{
       if(belongTo==='Property'){
         return imageOfProperty()
       }
       if(belongTo==='Project'){
        return imageOfProject()
      }
      
      }
      
      return (
      <div className='marginBottom' >
      <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
      <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
        {/*  */}
        Edit Image
     {/*  */}
     </div>
     </div>
     {/**/}
      <div className="card border">
        <div className=" label" >

            <Form
              style={{ padding: 25 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >
              <Button
          color="teal"
          size="mini"
          type="button"
          onClick={()=>this.backButtonHandler()}

        >
          Cancel
        </Button>
            <Button
              size="mini"
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <br /><br />
            
          <br/>
              <label >Image Belongs To</label>
              <Field
                search={true}
                name="belongTo"
                type="text"
                component={SelectInput}
                options={belongToOption()}
                placeholder="Image Belongs To"
              />
              
              <label >Image Of</label>
              <Field
              search={true}
                name="imageOf"
                type="text"
                component={SelectInput}
                options={imageOfOption()}
                placeholder="Image Of"
              />
              <label >Description</label>
              <Field
                name="imageOf"
                type="text"
                component={TextArea}
                rows={3}
                placeholder="Description"
              />
              
          

              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>
            <br/><br/><br/>
          </div>
          </div>
          </div>
          </div >
    );
}
};

export default connect(
  state => {
    return {
      state,
      initialValues: state.images.image
     }; // pull initial values from account reducer
  },
  {updateImage,fetchImage,resetImage}
)(
  reduxForm({
    form: 'ImageEdit' // a unique identifier for this form
    // validate
  })(withRouter(ImageEdit))
);
