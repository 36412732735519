import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
// import { getUserForAuth} from "../../actions/authAction";
// import { fetchAppParameter } from "../../actions/optionAction";
import Confirm_2 from "../common/Confirm_2"

import {
  // Input,
  // Form,
  Container, 
  Segment,
  Button,
  Divider,
} from "semantic-ui-react"; 



class AdminActivity extends Component {
  state = { 
    open:false,
    module:'',
    color:''
   };

  // componentDidMount() {
  //   this.props.getUserForAuth()
  //   this.props.fetchAppParameter()
  // }
  
  

  
  //@@@@
  updateBuilderRanking = async () => {
    
    try{

      const res = await axios.post(`/api/UpdateBuilderRankingBulk` );
      if(res.data.msg === 'success'){
        toastr.success('Updated Successfully')
      }
    }catch (error) {
      toastr.error("Oops", "Something went wrong.Please Try Again");
     }
    
    
  };
  //*** */
  updateDealerRanking = async () => {
    
    try{

      const res = await axios.post(`/api/UpdateDealerRankingBulk` );
      if(res.data.msg === 'success'){
        toastr.success('Updated Successfully')
      }
    }catch (error) {
      toastr.error("Oops", "Something went wrong.Please Try Again");
     }
    
    
  };
  //** */ 
  deleteDealerSuggested = async () => {
    
try{
  
  const res = await axios.delete(`/api/DealerSuggestedDeleteBulk` );
  console.log('res.data.msg',res.data.msg)
  if(res.data.msg === 'noEntry'){
    toastr.success('No Entry To Delete')
  }
  if(res.data.msg === 'success'){
    toastr.success('Deleted Successfully')
  }
  

}catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again");
 }
    
    
  };

   //** */
   deleteStatic = async () => {
    
    try{
      
      const res = await axios.delete(`/api/StaticDeleteBulk` );
      if(res.data.msg === 'success'){
        toastr.success('Deleted Successfully')
      }
    
    }catch (error) {
      toastr.error("Oops", "Something went wrong.Please Try Again");
     }
        
        
      };

   //** */ 
   
//@@@@
show = (val) => {

  this.setState({ open: true, module:val.module,color:val.color  });
};

//******************* */
handleConfirm = async () => {
  
  if(this.state.module === 'Update Builder Ranking'){
    this.updateBuilderRanking();
  }
  if(this.state.module === 'Update Dealer Ranking'){
    this.updateDealerRanking();
  }
  if(this.state.module === 'Delete DealerSuggested'){
    this.deleteDealerSuggested();
  }
  if(this.state.module === 'Delete Static'){
    this.deleteStatic();
  }
  
  
//**********
this.setState({  open: false });
}

handleCancel = () => this.setState({ open: false });
//**********************************
  
  render() {
    const { open,module,color  } = this.state;
    const header =`You Want To ${module} !`
   
    if (
      this.props.userA !== null &&
      (
        this.props.userA.userType === "admin")
    ) {
    return (
      <Container text> 
      <Confirm_2
        header={header}
        action={module}
        color={color}
        size="mini"
        open={open}
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
      />

        {/**/}
        <div className='margin-auto-H' >
        <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
          {/*  */}
          Delete Entries
       {/*  */}
       </div>
       </div>
       
       <Segment >
       <Divider fitted />

        <div className="disflex-paginator" >
        
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="orange" onClick={() => this.show({module:'Update Builder Ranking',color:'orange'})}>
          Update Builder Ranking
        </Button>
        
        </div>
        {/*  */}
        <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="orange" onClick={() => this.show({module:'Update Dealer Ranking',color:'orange'}) }>
          Update Dealer Ranking
        </Button>
      
        </div>
        {/*  */}
        <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show({module:'Delete DealerSuggested',color:'red'}) }>
          Delete DealerSuggested
        </Button>
      
        </div>
        {/*  */}
        <Divider fitted />

        <div className="disflex-paginator" >
                
        <Button 
        className='width200'
        style={{marginLeft:'10px'}}
        size="mini" color="red" onClick={() => this.show({module:'Delete Static',color:'red'})  }>
          Delete Static
        </Button>
      
        </div>
      
        </Segment>
        {/*  */}
         
      </Container>
    );
  }
  return (
    <Container text>
      <Segment>
        <div style={{ color: "red", textAlign: "center" }}>
          <h2>You Are Not Authorised To Bulk Update and Delete </h2>
        </div>
      </Segment>
    </Container>
  );
  }
  }



const mapStateToProps = (state) => {

  return {
    userA: state.auth.userA,
  };
};

export default connect(
  mapStateToProps,
  null
)(AdminActivity);
