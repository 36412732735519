import axios from "axios";
import { toastr } from "react-redux-toastr";
import {openModal, closeModal } from "./modalAction";

import {
  FETCH_PROJECTRS,
  FETCH_PROJECTR,
  SEARCH_PROJECTRS,
  FETCHING_PROJECTRS,
} from "./types";


//search  ProjectRs

export const searchProjectRs = (
  values,
  limit,
  offset
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/ProjectRSearch", {
      values,
      limit,
      offset
    });
    
    dispatch({ type: SEARCH_PROJECTRS, payload: res.data });
    dispatch({ type: FETCHING_PROJECTRS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetch All ProjectRs for Data use

export const fetchAllProjectRs = () => async dispatch => {

  try {
    const res = await axios.post("/api/AllProjectRList");

    dispatch({ type: FETCH_PROJECTRS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again searchMyProjectRs");
  }
};

//fetch all ProjectR of Builder
export const fetchProjectRForBuilder = (value) => async dispatch => {
  try {
    const res = await axios.post(`/api/ProjectRForBuilder`,value);
   
    dispatch({ type: SEARCH_PROJECTRS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//#####################################################
//fetch single ProjectR
export const fetchProjectR = id => async dispatch => {
  try {
    const res = await axios.get(`/api/ProjectRDetail/${id}`);
  
    dispatch({ type: FETCH_PROJECTR, payload: res.data });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//delete ProjectR
export const deleteProjectR = (id) => async dispatch => {
  try {
    const res = await axios.delete(`/api/ProjectRDelete/${id}`);
    if (res.data.msg ==='success') {
      toastr.success('ProjectC Deleted Successfully');
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//update ProjectR
export const updateProjectR = (id, values) => async dispatch => {
  try {
    const res = await axios.put(`/api/ProjectREdit/${id}`, values);

    if (res.data.msg ==='success') {
      toastr.success('ProjectC Updated Successfully');
    }
    dispatch({ type: FETCH_PROJECTR, payload: null });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//update ProjectR
export const updateProjectRForAddImagePath = (id, path) => async () => {
  try {
    const res = await axios.put(`/api/ProjectREditForAddImagePath`, {id:id, path:path});
    if(res.data.msg === 'success'){
      toastr.success('Image Path Added');
    }
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again AddImagePath");
  }
};
//#####################################################
//update ProjectR to INACTIVE  updateProjectRForAddImagePath
export const updateProjectRToInactive = (id) => async dispatch => {
  try {
    const res = await axios.put(`/api/ProjectRInactive/${id}`);
  
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};


// create ProjectR
export const createProjectR = (values) => async dispatch => {
  try {
   
    const res = await axios.post("/api/ProjectRCreate", {
      values
    });
 
    if (res.data.msg ==='success') {
      toastr.success('ProjectR Created Successfully');
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};


