import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modalAction';
import ReqResiSearchUnlocked from '../requireResi/ReqResiSearchUnlocked';
//import * as actions from '../../actions/companysAction';
const actions = { closeModal };

class SearchReqResiUnlockedModal extends Component {
  render() {
    return (
      <Modal open={true} onClose={this.props.closeModal}>

        <Modal.Content className='border'>
          <Modal.Description>
            <ReqResiSearchUnlocked />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(SearchReqResiUnlockedModal);
