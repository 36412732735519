import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import {createURL} from "../../actions/optionAction";
import { withRouter } from "react-router-dom";

import {
  Container,
  Segment,
  Form,
  Button,
  //Header,
} from "semantic-ui-react"; 
import TextInput from "../form/TextInput";


  class URLCreate extends Component {

   onSubmit = values => {
    this.props.createURL(values, this.props.history);
  };

render(){
  const { handleSubmit, pristine, reset, submitting } = this.props;
  
  return (
    <Container text>
    <div style={{maxWidth:'700px',margin:'auto'}}>

        {/**/}
        <div className='margin-auto-H' >
        <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
          {/*  */}
          Create URL
       {/*  */}
       </div>
       </div>
       {/**/}
        <div className="card border">
          <div className=" label" >

        <Form style={{ padding: 25 }} onSubmit={handleSubmit(this.onSubmit)}>
          <label >URL</label>
          <Field
            name="url"
            type="text"
            component={TextInput}
            placeholder="enter url"
          />

          <br />

          <Button color="olive" type="submit" disabled={pristine || submitting}>
            Submit
          </Button>
          <Button
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
        </Form>
        <br/><br/><br/>
            </div>
            </div>
            </div>
              </Container>
  );
}

    
}
  const mapStateToProps = state => {
    return {
      state,
      userA: state.auth.userA

    };
  };
export default connect(
   mapStateToProps,
{createURL}
)(
  reduxForm({
    form: "URLCreate" // a unique identifier for this form
    // validate
  })(withRouter(URLCreate))
);
