import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
//import Script from 'react-load-script';

import { searchBuilders } from '../../actions/builderAction';
import { closeModal } from '../../actions/modalAction';
import { withRouter } from 'react-router-dom';

import {
  Divider,
  
  Form,
  Button,
  
  Header
} from 'semantic-ui-react';
import TextInput from '../form/TextInput';

import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';

import { locality, } from './Data';
const BuilderSearch = props => {
  const { handleSubmit,  reset, submitting,  } = props;

  const onSubmit = values => {
    const offset = 0
const { limit} = props.builders;
    props.searchBuilders(values, limit,offset);
    //props.closeModal();
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>
        {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Builders
          {/*  */}
          </div>
          </div>
          {/**/}
        <div className="card-form ">
          <div  >

          <Form 
          style={{ padding: '10px' }} 
          onSubmit={handleSubmit(onSubmit)}
          >

<Button
              color="teal"
              size='mini'
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
            
            <Button
            size='mini'
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            size='mini'
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
            <Header sub color="teal" content="Search Comanies By Name" />
            <Divider fitted />
            <br />
            <label htmlFor="">Name Of Builder/Contact Person </label>
            <Field
              name="name"
              type="text"
              component={TextInput}
              placeholder="Name Of Builder/Contact Person"
            />
            <Header sub color="teal" content="Or Search Comanies By Phone" />
            <Divider fitted />
            <br />
            <label htmlFor="">Phone Of Builder/Contact Person</label>
            <Field
              name="mobile"
              type="text"
              component={TextInput}
              placeholder="Phone Of Builder/Contact Person"
            />
            <Header sub color="teal" content="Or Search Comanies By Email" />
            <Divider fitted />
            <br />
            <label htmlFor="">Email Of Builder/Contact Person</label>
            <Field
              name="email"
              type="text"
              component={TextInput}
              placeholder="Email Of Builder/Contact Person"
            />
            <Header sub color="teal" content="Or Search Comanies By " />
            <Divider fitted />
            <br />
            <label htmlFor="">Builder's Website</label>
            <Field
              name="website"
              type="text"
              component={TextInput}
              placeholder="Builder's Website"
            />
            <label htmlFor="">Operating Since</label>
            <Field
              name="operatingSince"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Operating Since"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            
            
            <label htmlFor="">Locality</label>
            <Field
              name="localityA"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label>Lower Subscription Expired On Date</label>
            <Field
              name="endL"
              type="text"
              component={DateInput}
              placeholder= " In DD-MM-YYYY format Lower Subscription Expired On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label>Higher Subscription Expired On Date</label>
            <Field
              name="endH"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Subscription Expired On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />


            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};

const mapStateToProps = state => {
  return {
    builders: state.builders,
    form: state.form.CompanySearch
  };
};
export default connect(
  mapStateToProps,
  { searchBuilders, closeModal }
)(
  reduxForm({
    form: 'BuilderSearch', // a unique identifier for this form
    destroyOnUnmount: false

    //validate
  })(withRouter(BuilderSearch))
);
