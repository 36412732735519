import React, { Component } from "react";
import { connect } from "react-redux";
//import { Grid ,Button,Icon } from "semantic-ui-react";
import { Switch, Route, } from "react-router-dom";
import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import ProjectRCreate from "./ProjectRCreate";
import ProjectREdit from "./ProjectREdit";
import ProjectRDetail from "./ProjectRDetail";

import ProjectRSearchList from "./ProjectRSearchList";


import Layout from "../layout/Layout";


 
class ProjectRDashboard extends Component {
  componentDidMount() {
    this.props.getUserForAuth();
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };

  render() {

    return (
    <div>
    <div className='maindiv'>

    <Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
    
      <Route
        exact
        path="/projectR/ProjectRCreate"
        component={ProjectRCreate}
      />
      <Route
        exact
        path="/projectR/ProjectRDetail/:id"
        component={ProjectRDetail}
      />

      <Route
        exact
        path="/projectR/ProjectREdit/:id"
        component={ProjectREdit}
      />


      <Route 
        exact
        path="/projectR/ProjectRSearchList"
        component={ProjectRSearchList}
      />
      
    </Switch>
        </Layout >
        </div>

    <div >
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>
      );


}
}
// const mapStateToProps = state => ({
// auth: state.auth
// });


export default connect(
  // mapStateToProps,
  null,
  {showBackButton, getUserForAuth, logoutUser  }
)(ProjectRDashboard);
