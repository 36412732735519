import React from 'react';
import { Modal, } from 'semantic-ui-react';
import { connect } from "react-redux";

const Confirm = ({userA,  open, onConfirm,onConfirm1, onCancel }) => {
  
  return (
   
      <Modal size="mini" 
      open={open} 
      //onClose={onCancel}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      >
       
        <Modal.Content className='border'>
        <div> <h6>Do You Want To Delete ?</h6></div>
          <div className= 'display-center'>
          {(userA !== null && userA.userSubscriptionType === "Regular") && (
                 <span
                 className="btntask" style={{background:'#FA8072'}}
                   onClick={onConfirm}
     
               >
                 Create Contact & Delete
               </span>
                )}

          
          <span
            className="btntask" style={{background:'#cc3333'}}
              onClick={onConfirm1}

          >
            Delete
          </span>
          <span
            className="btntask" 
              onClick={onCancel}

          >
            Cancel
          </span>
          </div>
        </Modal.Content>
      </Modal>
    
  );
}


function mapStateToProps(state,) {
 
  return { userA: state.auth.userA };
}

export default connect(
  mapStateToProps,
  null
)(Confirm);

//export default Confirm;
