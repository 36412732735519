


//#########
const propertyForO = [ '','RENT', 'PG ACCOMMODATION'];
export const propertyFor = values => {
  return propertyForO.map(value => ({ key: value, text: value, value: value }));
};

//############
const carParkingCoveredO = ['1', '2', '3', '4', '5', '6', '6+'];
export const carParkingCovered = values => {
  return carParkingCoveredO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const carParkingOpenO = ['1', '2', '3', '4', '5', '6', '6+'];
export const carParkingOpen = values => {
  return carParkingOpenO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//###################
const entryO = [

  'East',
  'West',
  'North',
  'South',
  'North - East',
  'North - West',
  'South - East',
  'South -West'
];
export const entry = values => {
  return entryO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//#######
