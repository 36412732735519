import React, { Component } from "react";
import includes from "lodash/includes";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";

import axios from "axios";
import { toastr } from "react-redux-toastr";

import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import * as actionDS from "../../actions/dealerSuggestedAction";

//import Confirm from "../common/Confirm";
import Confirm_1 from "../common/Confirm_1";
import { openModal } from "../../actions/modalAction";
import {
  Form,
  Icon,
  Button,
  Checkbox,
  Segment,
  Header
} from "semantic-ui-react";



class StaticSearchList extends Component {
  state = {
    pageO:'',
    id: "",
    openB: false,
    result: "show the modal to capture a result"
  };
  componentDidMount() {
    const {offset, limit} = this.props;
      
      const values =
        (this.props.form !== undefined && this.props.form.values) || {};
    
    this.props.searchDealerSuggesteds(values, limit,offset);
     
  }

  handleSearchAdByDealers = () => {
    this.props.openModal("SearchAdByDealersModal");
  };
  handleRefresh = ()=>{
    const {offset, limit} = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
  
    this.props.searchDealerSuggesteds(values, limit,offset);
  }

 //****************** */ 
 
showBulk = () => {

  this.setState({ openB: true,  });
};


//******************* */
handleConfirmBulk = async () => {
  const ids = this.props.selections;

//************
  try {
      
    //^^^^^^^^^^^^^^^^^
    const res = await axios.put(`/api/DynamicBulkDelete` , {ids});

      if(res.data.msg ==='success'){
        this.handleRefresh()
        this.setState({ openB: false });
        this.handleClearSelections()
        toastr.success('Bulk Deleted Successfully')
        }
        
    }catch (error) {
      if(error && error.response && error.response.status==401){
        this.props.openModal('LoginModal')
        toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
        toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
//**********
}

handleCancelBulk = () => this.setState({ openB: false });

//**********************************

handleClearSelections = () => {
  this.props.resetSelections();
};

onChange(_id) {
    if (includes(this.props.selections, _id)) {
      this.props.deselectDynamic(_id);
    } else {
      this.props.selectDynamic(_id);
    }
  }
onChangePage=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//***************************
renderList=(item)=> {
  const {
  startedOn,
  expiredOn,
  creditUsed,
  //****************** */
  adFor,
  projectType,
  propOrProjId,
  propertyFor,
  propertyType,
  bedroom,
  additionalRooms,
  expectedPrice,
  expectedRent,
  carpetArea,
  area,
  areaUnit,
  projectName,
  //projectId,
  locality,
  //	createdAt,
dealer,
  _id
  } = item;

  const {balanceCreditProp}=this.props.userA !== null && this.props.userA
  //************************** render active/inactive/expired
const valueRenew = {
balanceCreditProp,
expiredOn,
creditUsed,
id:_id
}
  const renderStatus=() => {


  if (Date.now()<Date.parse(startedOn)){
  return (<span >
  Inactive
  </span>)
  }
  if (Date.now()>Date.parse(startedOn) && Date.now()<Date.parse(expiredOn) ){
  return (<span >
  Active
  </span>)
  }
  if (Date.now()>Date.parse(expiredOn)){
  return (<span className='color-red'>
  Expired
  </span>)
  }
  return (<span >
  No Status
  </span>)
  }


  //**************************
  const roomOrBHK = (projectType==='Residential') ? 'BHK' :'Room'
  
  


  //**************************
  const renderDetailButton=() => {
  if(adFor==="Property" && projectType==='Residential' ){
  return (<Button
  inverted
  //floated="right"
  size="mini"
  color="blue"
  type="button"
  as={Link}
  to={`/propresi/PropResiDetail/${propOrProjId}`}
  >
  Detail
  </Button>)
  }
  if(adFor==="Property" && projectType==='Commercial' ){
  return (<Button
  inverted
  //floated="right"
  size="mini"
  color="blue"
  type="button"
  as={Link}
  to={`/propcom/PropComDetail/${propOrProjId}`}
  >
  Detail
  </Button>)
  }
  if(adFor==="Project" && projectType==='Residential' ){
  return (<Button
  inverted
  //floated="right"
  size="mini"
  color="blue"
  type="button"
  as={Link}
  to={`/projectR/ProjectRDetail/${propOrProjId}`}
  >
  Detail
  </Button>)
  }
  if(adFor==="Project" && projectType==='Commercial' ){
  return (<Button
  inverted
  //floated="right"
  size="mini"
  color="blue"
  type="button"
  as={Link}
  to={`/projectC/ProjectCDetail/${propOrProjId}`}
  >
  Detail
  </Button>)
  }



  }

  //**************************
  const renderadditionalRooms = () => {
  return additionalRooms.map(item => {
  return (
  <span key={item}>
  {" "}
  {item}|
  </span>
  );
  });
  };
  const price = (value) => {
  var val = Math.abs(value)
  if (val >= 10000000) {
  val = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
  val = (val / 100000).toFixed(2) + ' Lac';
  }
  /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
  return val;
  }

  //const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));
  const renderCheckbox = () =>
  this.props.userA !== null &&
  (
    this.props.userA.userType === "admin") ? (
     <span className='border-checkbox'
     style={{backgroundColor:'skyblue'}}
     > 
     <Checkbox
        checked={includes(this.props.selections, _id)}
        onChange={() => this.onChange(_id)}
        style={{paddingTop:'2px',paddingRight:'5px',}}
      />
      </span>
  ) : (
    <div></div>
  );
  //********** */
  return (
  <div key={_id}>
  <div className='card' style={{padding:'10px',marginTop:'0px',border:'solid 2px dodgerblue',}} >
  {/*requester detqils start*/}
  { renderCheckbox()}
  {/*  */}
<div className='border-bottom'  style= {{marginTop:'5px',marginBottom:'5px',}}>
<div  className="heading" style= {{marginBottom:'10px'}} >
<span >
{projectType}{' '}{adFor}{' '}{propertyFor} 
</span>
</div>
</div>
{/*  */}

  <div style={{fontSize:'1rem'}}>
  <div >
  {startedOn && (
  <div className='row'>
  <div className='side'>
  <b>Duration</b>
  </div>
  <div className='main'>
  <b>{ format(new Date(startedOn), "dd-MM-yyyy")}{' '}{format(new Date(startedOn), "HH:mm a")}{' '}To{' '}{ format(new Date(expiredOn), "dd-MM-yyyy")}{' '}{format(new Date(expiredOn), "HH:mm a")}{' '}
  <span style={{color:'dodgerblue',fontSize:'18px'}}>{ renderStatus()}</span>{' '}
  </b>
  </div>

  </div>
  )}
  </div>

  <div >
  {dealer  && (
  <div className='row'>
  <div className='side'>
  <b>Dealer</b>
  </div>
  <div className='main'>
  <b>{dealer.cname}{' '}</b>
  </div>

  </div>
  )}
  </div>

  <div >
  {creditUsed>0 && (
  <div className='row'>
  <div className='side'>
  <b>Credit Used</b>
  </div>
  <div className='main'>
  <b>{creditUsed}{' '}</b>
  </div>

  </div>
  )}
  </div>


  <div >
  {projectName && (
  <div className='row'>
  <div className='side'>
  <b>Location</b>
  </div>
  <div className='main'>
  <b>

  <div>
  {bedroom && (

  <span >
  {bedroom} {' '}{roomOrBHK}
  </span>)

  }{' '}
  {additionalRooms.length >0 && (

  <span >
  {renderadditionalRooms()}
  </span>)

  }{' '}
  {carpetArea >0 && (

  <span >
  {carpetArea} sq ft
  </span>)

  }{' '}
  {area && (

  <span >
  {area}
  </span>)

  }{' '}
  {areaUnit && (

  <span >
  {areaUnit}
  </span>)

  }{' '}
  {propertyType && (

  <span >
  {propertyType}
  </span>)

  }{' '}
  {propertyFor && (

  <span >
  For  {propertyFor}
  </span>)

  }{' '}
  {expectedPrice>0 && (

  <span >
  For  <Icon className="rupee" ></Icon>
  {price(expectedPrice)}{' '}In
  </span>)

  }{' '}
  {expectedRent>0 && (

  <span >
  For  <Icon className="rupee" ></Icon>
  {expectedRent}{' '}In
  </span>)

  }{' '}
  {projectName && (

  <span >
  {' '}{projectName}
  </span>)

  }{' '}
  {locality && (

  <span >
  {locality} Dwarka, New Delhi
  </span>)

  }{' '}
  {renderDetailButton()}
  </div>

  </b>
  </div>

  </div>
  )}
  </div>

  </div>


  </div>
  </div>


  );
  }


//@@@@@@@@@@@@@@@@@@@


  back = () => {
    const {
      offset,
      limit
     
    } = this.props;
   
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
      return;
    }

    this.props.searchDealerSuggesteds(values, limit, (offset - limit));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
      limit,
      count
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
      return;
    }

    this.props.searchDealerSuggesteds(values, limit, (offset + limit));
    window.scroll(0,0)
  };

  handleGoToPage = () => {
    const {
      
      limit,
      count
      
    } = this.props;
  
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
      // use Math.abs for strict equality ===  to work and safe guard against negative value from user
      if (Math.abs(this.state.pageO) === 0) {
        return; 
      }
  
    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)
  
  
    this.props.searchDealerSuggesteds(values, limit, (page * limit));
      window.scroll(0,0)
  
  }
  
  //****************** */
  renderPaginator=()=> {
    if (this.props.dealerSuggesteds.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }


  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }

  //******************
  renderDeleteButtonBulk = () =>
  this.props.userA !== null &&
  (this.props.userA.leadDelete === "Yes" ||
    this.props.userA.userType === "admin") ? (
    <span
    onClick={() => this.showBulk()}
    className="icon-button"
    >
    <img className= 'yimg ' src='/deleteIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
    </span>

  ) : (
    <div></div>
  );
  //^^^^^^^^^^^
  renderButton() {
    if (this.props.dealerSuggesteds && this.props.dealerSuggesteds.length > 0) {
      return (
        this.props.userA !== null &&
        (
          this.props.userA.userType === "admin") ? (
            <Segment>
              <Header color="teal" content="Select Dynamic Ad To Bulk Delete" />
              {this.props.selections && this.props.selections.length > 0 && 
              (
                <>
               { this.renderDeleteButtonBulk()}
              <Button
            onClick={this.handleClearSelections}
            inverted
            color="blue"
            size = 'mini'
          >
            Clear Selections From List
            </Button>
            </>
            )
              }

            </Segment>
        ) : (
          <div></div>
        )

      );
    }
  }

  render() {
    const { openB } = this.state;
    return (

      <div className='maindiv-820'>
        {/* <Confirm
         open={open}
         onCancel={this.handleCancel}
         onConfirm={()=>this.handleConfirm(true) }
         onConfirm1={()=>this.handleConfirm(false) }
      /> */}
      <Confirm_1
         openB={openB}
         onCancelBulk={this.handleCancelBulk}
         onConfirmBulk={()=>this.handleConfirmBulk(true) }
      />
        {/* <Confirm
            size="mini"
            open={open}
            onCancel={this.handleCancel}
            onConfirm={() => this.handleConfirm()}
            style={{padding:'50px'}}
          /> */}
      <div >


      <div>
  {/**/}
  <div className='cashbook-H box-shadow'>
  
  <div
  style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
  onClick={this.handleSearchAdByDealers}>
        <span className='icon-button border1' > <Icon name='search' /></span >
      </div>
  
  
  
  <div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
  <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
  All Dynamic Advertisement ({this.props.count})
  </div>
  </div>
  
  
  </div>
  {/**/}
  
  { this.renderButton()}
  {this.renderLoading()}
  {this.props.dealerSuggesteds.map(this.renderList)}

  
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()} 
<div className="disflex-paginator mb-0">
  <div className= 'font-paginator'>Go To Page</div>
<Form size="mini" >

            <Form.Input

                placeholder="Enter page number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChangePage}

              />
            </Form>
            <Button size='mini' color='blue' onClick={this.handleGoToPage} >
          Submit
        </Button>
</div>
</div>

<br/><br/><br/><br/>
  <div></div>
  <div></div>
  </div>
  <div className='nodisplay'>
  {/* side grid space*/}
  </div>


  </div>
  </div>

    );
  }
}


const mapStateToProps = ({ dealerSuggesteds, auth,form }) => {
  console.log('dealerSuggesteds@',dealerSuggesteds)
  const { fetching,limit, offset, count } = dealerSuggesteds;
  return {
    userA: auth.userA,
    fetching,
    limit,
    offset,
    count,
    form:form.AdByDealersSearch,
    dealerSuggesteds: dealerSuggesteds.dealerSuggesteds,
    selections: dealerSuggesteds.selections,

  };
};

export default connect(
  mapStateToProps,
  {...actionDS,openModal}
)(StaticSearchList);
