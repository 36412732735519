import React, { Component } from "react";
import { connect } from "react-redux";
//import { Grid } from "semantic-ui-react";
import { Switch, Route,  } from "react-router-dom";
import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import ReqResiCreate from "./ReqResiCreate";

import ReqResiDetail from "./ReqResiDetail";
import ReqResiEdit from "./ReqResiEdit";

import ReqResiSearchList from "./ReqResiSearchList";
import ReqResiUnlockedList from "./ReqResiUnlockedList";
import MyReqResiList from "./MyReqResiList";

import Layout from "../layout/Layout";
 
class ReqResiDashboard extends Component {
  componentDidMount() {
    this.props.getUserForAuth();
    if(this.props.history.location.pathname !=='/'){
          this.props.showBackButton()
        }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };
  render() {
    return (
    <div>
    <div className='maindiv'>

      <Layout onLogoutClick={this.onLogoutClick}>

          <Switch>
            <Route
              exact
              path="/reqresi/ReqResiCreate"
              component={ReqResiCreate}
            />

            <Route
              exact
              path="/reqresi/ReqResiDetail/:id"
              component={ReqResiDetail}
            />
            <Route
              exact
              path="/reqresi/ReqResiEdit/:id"
              component={ReqResiEdit}
            />



            <Route
              exact
              path="/reqresi/ReqResiUnlockedList"
              component={ReqResiUnlockedList}
            />
            <Route
              exact
              path="/reqresi/ReqResiUnlockedList/:id"
              component={ReqResiUnlockedList}
            />
            <Route
              exact
              path="/reqresi/ReqResiSearchList"
              component={ReqResiSearchList}
            />
            <Route
              exact
              path="/reqresi/MyReqResiList"
              component={MyReqResiList}
            />

          </Switch>
          </Layout >
          </div>

      <div >
          <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

        <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
      </div>

      </div>
        );

  }
  }

// const mapStateToProps = state => ({
// auth: state.auth
// });

export default connect(
  // mapStateToProps,
  null,
  {showBackButton, getUserForAuth, logoutUser  }
)(ReqResiDashboard);
