import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import store from './store';
import 'semantic-ui-css/semantic.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './index.css';
import "react-image-gallery/styles/css/image-gallery.css"
// import { createStore, applyMiddleware } from 'redux';
// import reduxThunk from 'redux-thunk';
//import registerServiceWorker from './registerServiceWorker';
import App from './components/App';
// import reducers from './reducers';
//
// // Development only axios helpers!
// import axios from 'axios';
// window.axios = axios;
//
// const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <div>
      <ReduxToastr
        position="top-left"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
      />
      <App />
    </div>
  </Provider>,
  document.querySelector('#root')
);
