import axios from "axios";
import { toastr } from "react-redux-toastr";

import {openModal, closeModal } from "./modalAction";

import {
  FETCH_PROJECTCS,
  FETCH_PROJECTC,
  SEARCH_PROJECTCS,
  FETCHING_PROJECTCS,
} from "./types";


//search  ProjectCs

export const searchProjectCs = (
  values,
  limit,
  offset
) => async dispatch => {

  try {
    const res = await axios.post("/api/ProjectCSearch", {
      values,
      limit,
      offset
    });

    dispatch({ type: SEARCH_PROJECTCS, payload: res.data });
    dispatch({ type: FETCHING_PROJECTCS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetchAllProjectCs for data use

export const fetchAllProjectCs = () => async dispatch => {

  try {
    const res = await axios.post("/api/AllProjectCList");

    dispatch({ type: FETCH_PROJECTCS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again searchMyProjectCs");
  }
};


//#####################################################
//fetch single ProjectC
export const fetchProjectC = id => async dispatch => {
  try {
    const res = await axios.get(`/api/ProjectCDetail/${id}`);

    dispatch({ type: FETCH_PROJECTC, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//delete ProjectC
export const deleteProjectC = (id) => async dispatch => {
  try {
    const res = await axios.delete(`/api/ProjectCDelete/${id}`);
    if (res.data.msg ==='success') {
      toastr.success('ProjectC Deleted Successfully');
    }
   
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//update ProjectC
export const updateProjectC = (id, values) => async dispatch => {
  try {
    const res = await axios.put(`/api/ProjectCEdit/${id}`, values);
   
    if (res.data.msg ==='success') {
      toastr.success('ProjectC Updated Successfully');
    }
    dispatch({ type: FETCH_PROJECTC, payload: null });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//update ProjectC
export const updateProjectCForAddImagePath = (id, path ) => async () => {
  try {
    const res = await axios.put(`/api/ProjectCEditForAddImagePath`, {id:id, path:path});
    if(res.data.msg === 'success'){
      toastr.success('Image Path Added');
    }
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again AddImagePath");
  }
};



// create ProjectC
export const createProjectC = (values) => async dispatch => {
  try {
  
    const res = await axios.post("/api/ProjectCCreate", {
      values
    });
 
    if (res.data.msg ==='success') {
      toastr.success('ProjectC Created Successfully');
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};


