import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modalAction';
import PropComRentSearch from '../propertyCom/PropComRentSearch';
//import * as actions from '../../actions/companysAction';
const actions = { closeModal };

class SearchPropComRentModal extends Component {
  render() {
    return (
      <Modal  size='small' open={true} onClose={this.props.closeModal}>

        <Modal.Content className='border'>
          <Modal.Description>
            <PropComRentSearch />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(SearchPropComRentModal);
