const sortingO = [
  'Demand-Low To High',
  'Demand-High To Low',
  'Newest',
  'Oldest'
];
export const sorting = () => {
  return sortingO.map(value => ({ key: value, text: value, value: value }));
};

//#########
const advertisedByO = [ 'Owner','Financer', 'Others'];
export const advertisedBy = () => {
  return advertisedByO.map(value => ({ key: value, text: value, value: value }));
};
//#########
const propertyForO = [ 'SALE','RENT'];
export const propertyFor = () => {
  return propertyForO.map(value => ({ key: value, text: value, value: value }));
};
export const propertyForOption = () => {
  return propertyForO.map(value => ({ key: value, text: value, value: value }));
};
const brokerResponseO = ['Brokers Please Do Not Contact', 'Brokers Can Contact'];
export const brokerResponse = () => {
  return brokerResponseO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############
const categoryO = [ 'MALL','DDA MKT COMPLEX', 'SHOPPING cum OFFICE COMPLEX'];
export const category = () => {
  return categoryO.map(value => ({ key: value, text: value, value: value }));
};
//###############
const projectTypeO = [
  'Residential',
  'Commercial'
];
export const projectType = () => {
  return projectTypeO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const areaUnitO = [ '' ,'sq ft','sq yd','sq m'];
export const areaUnit = () => {
  return areaUnitO.map(value => ({ key: value, text: value, value: value }));
};
//###############
const propertyTypeO = [
  'Office Space',
    'Shop',
    'Space in Shopping Mall',
    'Showroom',
    'Commercial Land /Plot',
    'FLAT-DDA',
    'Builder Floor'
];
export const propertyType = () => {
  return propertyTypeO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//** */
const frequencyO = [
  'One-Time',
  'Monthly',
  'Quaterly',
  'Half-Yearly',
  'Yearly',
  'Other'
];
export const frequency = () => {
  return frequencyO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

const booleanOptionO = ['','Yes', 'No'];
export const booleanOption = () => {
  return booleanOptionO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############
const numberOptionO = ['','N/A','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const numberOption = () => {
  return numberOptionO.map(value => ({ key: value, text: value, value: value }));
};

//#######
const bedroomO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const bedroom = () => {
  return bedroomO.map(value => ({ key: value, text: value, value: value }));
};
//############
const bathroomO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const bathroom = () => {
  return bathroomO.map(value => ({ key: value, text: value, value: value }));
};


//#######
const furnishedStatusO = ['Newly Furnished','Furnished', 'Semi-Furnished', 'Unfurnished'];
export const furnishedStatus = () => {
  return furnishedStatusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############
const wardrobesO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const wardrobes = () => {
  return wardrobesO.map(value => ({ key: value, text: value, value: value }));
};
//###############
const acO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const ac = () => {
  return acO.map(value => ({ key: value, text: value, value: value }));
};
//#######
const fanO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const fan = () => {
  return fanO.map(value => ({ key: value, text: value, value: value }));
};

//###############
const interiorO = ['','New', 'Old'];
export const interior = () => {
  return interiorO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//############
const companyLeaseO = ['Yes', 'No', "Doesn't Matter"];
export const companyLease = () => {
  return companyLeaseO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const agreementO = ['Registrar', 'Notary', "Doesn't Matter"];
export const agreement = () => {
  return agreementO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//#######
const occupancyStatusO = ['Owner', 'Tenant', 'Vacant'];
export const occupancyStatus = () => {
  return occupancyStatusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############
const loanOfferedByO = ['', 'All The Leading Banks'];
export const loanOfferedBy = () => {
  return loanOfferedByO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const statusO = ['Ready To Move', 'Under Construction'];
export const status = () => {
  return statusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//#######
const typeOfOwnershipO = ['','Freehold', 'Leasehold','Power of Attorney'];
export const typeOfOwnership = () => {
  return typeOfOwnershipO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############
const facingO = [
'',
  'East',
  'West',
  'North',
  'South',
  'North - East',
  'North - West',
  'South - East',
  'South -West'
];
export const facing = () => {
  return facingO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const entryO = [
  '',
  'East',
  'West',
  'North',
  'South',
  'North - East',
  'North - West',
  'South - East',
  'South -West'
];
export const entry = () => {
  return entryO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//#######
const OverlookingO = ['','Park', 'Road', 'Other Building'];
export const Overlooking = () => {
  return OverlookingO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############

const floorNoO = [
  '',
  'Ground',
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '10+',
  'Top',
  
];
export const floorNo = () => {
  return floorNoO.map(value => ({ key: value, text: value, value: value }));
};
//############
const totalFloorsO = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '10+'
];
export const totalFloors = () => {
  return totalFloorsO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//###############
const localityO = [
  'Sector-01',
  'Sector-02',
  'Sector-03',
  'Sector-04',
  'Sector-05',
  'Sector-06',
  'Sector-07',
  'Sector-08',
  'Sector-09',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18A',
  'Sector-18B',
  'Sector-19A',
  'Sector-19B',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//**************
const propcomStatusO = ['active', 'inactive'];
export const propcomStatus = () => {
  return propcomStatusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

const cityO = ['Dwarka', 'Gurugram','Noida', 'Rohini'];
export const city = () => {
  return cityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//**************
const adAllowedToDealersO = ['Some', 'All'];
export const adAllowedToDealers = () => {
  return adAllowedToDealersO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};