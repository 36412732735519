import React from 'react';
const imageOfProjectO = [
    'Park',
    'Children Play Area',
    'Entrance View',
    'Front Elevation 1',
    'Front Elevation 2',
    'Side Elevation',
    'Paved Compound',
    'Car Parking',
    'Temple',
    'Security Room',
    'Community Hall',
    'Layout1',
    'Layout2',
    'Layout3',
    'Layout4',
    'Layout5',
];
export const imageOfProject = values => {
  return imageOfProjectO.map(value => <option key={value} value={value}>{value}</option>);
};
const imageOfPropertyO = [
  'Drawing Room',
  'Living Room',
  'Dining Room',
  'Kitchen',
  'Lobby',
  'Main Door',
  'Entrance View',
  'Overlooking View',
  'View From Balcony',
  'Master Bedroom',
  "Master Bedroom's Bathroom",
  'Bathroom 1',
  'Bedroom 1',
  'Bathroom 2',
  'Bedroom 2',
  'Bathroom 3',
  'Bedroom 3',
  'Bathroom 4',
  'Bedroom 4',
  'Bathroom 5',
  'Bedroom 5',
  'Bathroom 6',
  'Bedroom 6',
  'Floor Plan 1',
  'Floor Plan 2',
  'Floor Plan 3',
  'Floor Plan 4',
  'Floor Plan 5'
  
  
];
export const imageOfProperty = values => {
  return imageOfPropertyO.map(value => <option key={value} value={value}>{value}</option>);
};

const belongToO = ['Property', 'Project'];
export const belongTo = values => {
  return belongToO.map(value => <option key={value} value={value}>{value}</option>);
};
