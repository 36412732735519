import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
//import writtenNumber from "written-number";

import {searchStatics} from "../../actions/staticAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button, } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";

import {
  adForOption,
  //adLocationProperty,
  adCategoryOption,
  adLocationPropertyCommonO,
  adLocationPropertyBFloorO,
  adLocationDealer,
  adSubLocation,

} from './Data';




const StaticSearch = props => {
  
  const { handleSubmit,  reset, submitting } = props;

  const { adFor,adCategory, adLocationA } =
  props.state.form &&
  props.state.form.StaticSearch !== undefined &&
  props.state.form.StaticSearch.values !== undefined &&
  props.state.form.StaticSearch.values;

  const onSubmit = values => {
    const offset = 0
const { limit} = props.state.statics;
    props.searchStatics(values, limit,offset);
  };
  //********************** */
  

//@@@@@

const adLocationProperty = ()=>{
  const data =()=>{
    if(adCategory==='Commercial'){
      return adLocationPropertyCommonO
    }
    if(adCategory==='Residential'){
      return [...adLocationPropertyCommonO,
        ...adLocationPropertyBFloorO]
    }
  }
return data() !==undefined && data().map(value => ({
  key: value,
  text: value,
  value: value,style:{fontSize:'18px'}
}));
}

const renderAdForPropertyField = ()=>{
return (
 < React.Fragment>
        
        <label >Ad Category</label>
        <Field
          search={true}
          name="adCategory"
          type="text"
          component={SelectInput}
          options={adCategoryOption()}
          placeholder="Ad Category"
        />

        
          <label >Ad Location</label>
          
          <Field
            search={true}
            name="adLocationA"
            type="text"
            component={SelectInput}
            multiple={true}
            options={adLocationProperty()}
            placeholder="Ad Location "
            />
          
 </React.Fragment>
)

}

//@@@@@
const renderAdForDealerField = ()=>{

return (
 < React.Fragment>
        
        {/* <label >Ad Category</label>
        <Field
          search={true}
          name="adCategory"
          type="text"
          component={SelectInput}
          options={adCategory()}
          placeholder="Ad Category"
        /> */}

        
<label >Ad Location</label>
          <Field
            search={true}
            name="adLocationA"
            type="text"
            component={SelectInput}
            multiple={true}
            options={adLocationDealer()}
            placeholder="Ad Location "
            />
          
 </React.Fragment>
)

}


//@@@@@@@@@@@@
const renderDealerOrPropertyField = () =>{
if((adFor==='Property SALE') || (adFor==='Property RENT')){
return renderAdForPropertyField()
}
if((adFor==='Dealer') ){
return renderAdForDealerField()
}
}



//********************** */


  return (
    
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Static Property Ad
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form ">
          <div  >

          <Form 
          style={{ padding: '10px' }} 
          onSubmit={handleSubmit(onSubmit)}
          >

          <Button
          color="teal"
          size='mini'
          type="button"
          onClick={()=>props.closeModal()}

          >
          Cancel
          </Button>
            
            <Button
            size='mini'
              color="orange"
              type="button"
              disabled={ submitting} 
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            size='mini'
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          <label >Ad For</label>
        <Field
          search={true}
          name="adFor"
          type="text"
          component={SelectInput}
          options={adForOption()}
          placeholder="Ad For"
        />

        {renderDealerOrPropertyField()}

        {/*  */}
        {adLocationA && (
          
          ((adLocationA.filter(
        item => item.toString() === 'STATIC ALL'
      ).length > 0) || (adLocationA.filter(
        item => item.toString() === 'BUILDER FLOOR STATIC ALL'
      ).length > 0 )) // value exists in the array
      
      &&
      ((adLocationA.filter(
        item => item.toString() === 'SIMILAR PROPERTY'
      ).length < 1) && (adLocationA.filter(
        item => item.toString() === 'BUILDER FLOOR SIMILAR PROPERTY'
      ).length < 1 ))
      
      )
            && (
            <>
            <label > Ad Sub Location</label>
            <Field
            search={true}
            name="adSubLocationA"
            type="text"
            component={SelectInput}
            multiple={true}
            options={adSubLocation()}
            placeholder="Ad Sub Location "
            />
            </>
            )
}
          
            
            {/*  */}
            <label htmlFor="">Lower Started On Date</label>
            <Field
              name="startL"
              type="text"
              component={DateInput}
              placeholder= "In DD-MM-YYYY format Lower Started On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Higher Started On Date</label>
            <Field
              name="startH"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Started On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Lower Expired On Date</label>
            <Field
              name="expireL"
              type="text"
              component={DateInput}
              placeholder= " In DD-MM-YYYY format Lower Expired On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label htmlFor="">Higher Expired On Date</label>
            <Field
              name="expireH"
              type="text"
              component={DateInput}
              placeholder=" In DD-MM-YYYY format Higher Expired On Date"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />



            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting} 
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {searchStatics,closeModal}
)(
  reduxForm({
    form: "StaticSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(StaticSearch)
);
