import axios from "axios";
import { toastr } from "react-redux-toastr";
import {openModal, closeModal } from "./modalAction";

import {
  FETCH_DEALERSUGGESTEDS,
  SEARCH_DEALERSUGGESTEDS,
  FETCHING_DEALERSUGGESTEDS,
  DESELECT_DEALERSUGGESTED,
  SELECT_DEALERSUGGESTED,
  RESET_SELECTION_DEALERSUGGESTED,
} from "./types";

//select dealerSuggested
export const selectDynamic = id => async dispatch => {
  try {
    
    dispatch({ type: SELECT_DEALERSUGGESTED, payload: id });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
// deselect dealerSuggested

export const deselectDynamic = id => async dispatch => {
  try {
    
    dispatch({ type: DESELECT_DEALERSUGGESTED, payload: id });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
// RESET SELECTION
export const resetSelections = () => async dispatch => {
  dispatch({ type: RESET_SELECTION_DEALERSUGGESTED });
};
export const resetFetching = () => async dispatch => {
  dispatch({ type:FETCHING_DEALERSUGGESTEDS, payload: true });
};

//search  DealerSuggesteds
export const searchDealerSuggesteds = (
  values,
  limit,
  offset
) => async dispatch => {

  try {
    const res = await axios.post("/api/DealerSuggestedSearch", {
      values,
      limit,
      offset
    });

    dispatch({ type: SEARCH_DEALERSUGGESTEDS, payload: res.data });
    dispatch({ type:FETCHING_DEALERSUGGESTEDS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
//*********************************
//fetch all DealerSuggesteds for Advertisement by one Dealer
export const fetchAdByDealer = ( values,
  limit,
  offset ) => async dispatch => {

  try {
    const res = await axios.post("/api/AdByDealer",{
      values,
      limit,
      offset
    } );

    dispatch({ type: FETCH_DEALERSUGGESTEDS, payload: res.data });
    dispatch({ type:FETCHING_DEALERSUGGESTEDS, payload: false });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again fetchDealerSuggesteds");
  }
};

//delete DealerSuggested
export const deleteDealerSuggested = (id) => async dispatch => {
  try {
    const res = await axios.delete(`/api/DealerSuggestedDelete/${id}`);

    if (res.data.msg ==='success'){
      toastr.success('Dealer Suggested Deleted Successfully ');
    }
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

