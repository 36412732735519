
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {fetchCompanys} from '../../actions/companysAction';
import {fetchBuilders} from '../../actions/builderAction';
import {fetchAllProjectCs} from '../../actions/projectCAction';
import {fetchAllProjectRs} from '../../actions/projectRAction';
import { getUserForAuth,showSidebar,hideSidebar ,logoutUser,fetchUsers } from "../../actions/authAction";
import {fetchProjectNameResis,fetchProjectNameComs,fetchLocalitys} from "../../actions/appParameterAction";

import {   Image, 
  Icon,
  Button,
  Dropdown,
 } from "semantic-ui-react";


class Navbar extends Component {
  
  componentDidMount() {
    if (!this.props.auth.userA){
      this.props.getUserForAuth();
    }
    if(this.props.state.projectCs.projectCsForData.length <1){
      this.props.fetchAllProjectCs();
    }
    if(this.props.state.projectRs.projectRsForData.length <1){
      this.props.fetchAllProjectRs();
    }
    // ********
    
    if(this.props.projectNameResis.length <1){
      this.props.fetchProjectNameResis();
    }
    if(this.props.projectNameComs.length <1){
      this.props.fetchProjectNameComs();
    }
    if(this.props.localitys.length <1){
      this.props.fetchLocalitys();
    }

    //*********** */
    if(this.props.companysForData.length <1){
      this.props.fetchCompanys()
 
    }
    if(this.props.buildersForData.length <1){
      this.props.fetchBuilders();
 
    }
    if(this.props.usersForData.length <1){
      this.props.fetchUsers()
      
    }
  }
  
  //*******************************************
handleSidebarToggle=()=>{
  this.props.showSidebar();
  window.scroll(0,0)
}

onLogoutClick = () => {
  this.props.logoutUser();
};

 // *****************************************
  render() {
   
    const { isAuthenticated,   } = this.props.auth !== null && this.props.auth;
    
//&&&&&&&&&&&&&&&&&&&&&&

  const renderBackButton =()=>{
    return this.props.visibleBB=== true ? (
      <button

		  onClick={() => this.props.history.goBack()}

          className='back-btn'
        >
          <Icon name="arrow left" />

        </button>
    ) : (
      <div></div>
      )}
    
//@@@@

const renderRefreshButton =()=>{
  return  (
    <Button
    circular
    icon='refresh'
    onClick={() => window.location.reload(false)}
    style={{backgroundColor: 'dodgerblue', color:'white'}}
  />
  ) }
    //$$$$$$$$$$$$$$$$$$$$$$$$$$$

const renderShowHideSidebarButton =()=>{
  return this.props.visible=== true ? (
    <button className='btn'  onClick={this.props.hideSidebar}

    ><Icon name="close" /></button>
  ) : (
    <button  className='btn' onClick={this.handleSidebarToggle }

    ><Icon name="sidebar" /></button>
    )}

    const authLinks = (
      <div>

      <nav id="navbar-top" className='border-bottom-nav' style={{backgroundColor:'white',flexDirection:'row'}}>



         <div><Image size='mini' src='/logo.png' style={{ marginRight: '.5em' }} />
         </div>
         {renderBackButton()}
         {/* <div>FindRealEstate</div> */}
         {renderShowHideSidebarButton()}
         {renderRefreshButton()}

         <ul>

 <li>
            <Link to={'/'} >

        <div style={{fontSize:'1.2rem',fontWeight:'bold',marginRight:'10px'}}><Icon name='home' />Home</div>
        </Link>

      </li>

      <li style={{fontSize:'1.5rem',fontWeight:'bold',marginRight:'5px',padding:'5px',color:'blue'}}>
      { isAuthenticated ? (
<Dropdown
pointing="top right" 
//text={user.name}
icon='user outline'
>

  <Dropdown.Menu> 
  <Dropdown.Item
    header='true'
  
    //text="Sign Out"
   // icon="power"
  ><span className="font-18B"
  style={{color:'blue'}}>
    {this.props.auth.user && this.props.auth.user.name}
    {' '}</span></Dropdown.Item>
  <Dropdown.Item
    onClick={this.onLogoutClick}
    text="Sign Out"
    icon="power"
  />
    <Dropdown.Item
      as={Link}
      to = {`/passchange/${this.props.auth.user.id}`}
      text="Change Password"
      icon="settings"
    />
 
    
  </Dropdown.Menu>


</Dropdown>
):(<span></span>)  
}



</li>


        </ul>

      </nav>
      {/* NAV DESKTOP */}

      <nav id="navbar-desktop" className='border-bottom-nav'  style={{backgroundColor:'white',flexDirection:'row'}}>

        <div style={{display:'flex'}}>

        <div style={{display:'flex',alignItems:'center'}}><Image size='mini' src='/logo.png' style={{ marginRight: '.5em' }} />
        <div style={{fontSize:'1.5rem',fontWeight:'bold',color:'slateblue'}}>admin.findprop.in</div>
        </div>

        {renderBackButton()}
        
        {renderShowHideSidebarButton()}
        {renderRefreshButton()}
        </div>

        <ul>

<li>
           <Link to={'/'} >

       <div style={{fontSize:'1.2rem',fontWeight:'bold',marginRight:'10px'}}><Icon name='home' />Home</div>
       </Link>

     </li>

     <li style={{fontSize:'1.5rem',fontWeight:'bold',marginRight:'5px',padding:'5px',color:'blue'}}>

<Dropdown
pointing="top right" 
//text={user.name}
icon='user outline'
>
  <Dropdown.Menu> 
  <Dropdown.Item
    header='true'
  
    //text="Sign Out"
   // icon="power"
  ><span style={{color:'blue'}}>
     {this.props.auth.user && this.props.auth.user.name}
    {' '}</span></Dropdown.Item>
  <Dropdown.Item
    onClick={this.onLogoutClick}
    text="Sign Out"
    icon="power"
  />
    <Dropdown.Item
      as={Link}
      to="/passchange"
      text="Change Password"
      icon="settings"
    />
    
  </Dropdown.Menu>

</Dropdown>




</li>


       </ul>
 
     </nav>

      

    
    
      </div>
    );



    return (
      <div>
        {
          authLinks
        }
      </div>
    );
  }
}
//@@@@@@@@@@@@@@@@@



const mapStateToProps = state => ({ 
  state,
  auth: state.auth,
  projectNameResis: state.appParameters.projectNameResis,
  projectNameComs: state.appParameters.projectNameComs,
  localitys: state.appParameters.localitys,
  buildersForData:state.builders.buildersForData,
  companysForData:state.companys.companysForData,
  usersForData:state.auth.usersForData,
  visible: state.auth.visible,
  visibleBB: state.auth.visibleBB,
  userA: state.auth.userA,
});

export default connect(
  mapStateToProps,
  { fetchProjectNameResis,fetchProjectNameComs,fetchLocalitys,
    getUserForAuth, showSidebar,hideSidebar,logoutUser,
    fetchAllProjectRs,fetchAllProjectCs,fetchCompanys,fetchBuilders, fetchUsers   }
)(withRouter(Navbar));
