import without from "lodash/without";
import {
  DESELECT_STATIC,
  SELECT_STATIC,
  RESET_SELECTION_STATIC,
  FETCH_STATICS,
  FETCH_STATIC,
  SEARCH_STATICS,
  FETCHING_STATICS,
} from '../actions/types';

const INITIAL_STATE = {
  city:'', 
  fetching:true,
  statics: [],
  staticsC: [],
  staticsProperty: [],
  staticsDealer: [],
  offsetP: 0, // for static property
  limitP: 10,
  offsetD: 0, // for static dealer
  limitD: 10,
  static: null,
  offsetStatic: 0,
  offset: 0,
  offsetC: 0,
  limitStatic: 10,
  limit: 10,
  limitC: 10,
  selections: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_STATICS:
      return { ...state, ...action.payload };
    case FETCH_STATIC:
      return { ...state, static: action.payload };
    
    case SEARCH_STATICS:
     
      return { ...state, ...action.payload };
      case FETCHING_STATICS:
        return { ...state, fetching: action.payload };
        
        case SELECT_STATIC:
    
          return { ...state, selections: [...state.selections, action.payload] };
        case DESELECT_STATIC:
          return {
            ...state,
            selections: without(state.selections, action.payload)
          };
    
        case RESET_SELECTION_STATIC:
          
          return { ...state, selections: [] };
    default:
      return state;
  }
};
