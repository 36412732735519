import {
  FETCH_PROJECTRS,
  FETCH_PROJECTR,
  SEARCH_PROJECTRS,
  FETCHING_PROJECTRS,
} from '../actions/types';

const INITIAL_STATE = {
  city:'', 
  fetching:true,
  projectRs: [],
  projectRsForData :[],
  projectRsBuilderOngoing: [],
  projectRsBuilderPast: [],
  projectRsBuilderUpcoming: [],
  projectR: null,
  offset: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case FETCH_PROJECTRS:
      return { ...state, ...action.payload };
    case FETCH_PROJECTR:
      return { ...state, projectR: action.payload };
    
    case SEARCH_PROJECTRS:
      
      return { ...state, ...action.payload };
   
    case FETCHING_PROJECTRS:
      return { ...state, fetching: action.payload };
    default:
      return state;
  }
};
