import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import { openModal } from "../../actions/modalAction";
import { searchBuilders,updateBuilderRanking } from "../../actions/builderAction";

import {
  Icon,
  Button,
} from "semantic-ui-react";



class BuilderSearchList extends Component {
  state = {
    pageO:'',
    id:'',
  };
  componentDidMount() {
    const {offset, limit} = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    this.props.searchBuilders(values, limit,offset);
    
  }
  handleSearchComp = () => {
    this.props.openModal("SearchBuilderModal");
  }

  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  // **************
  
// ***************************


    
  handleUpdateRanking = (id) => {
  this.props.updateBuilderRanking(id)
  }
  //^^^^^^^^^^^^^^^^^^^^^^^^^
    onChange=(e) => {
      this.setState({ [e.target.name]: e.target.value });
    }
  //*******************************************************
  back = () => {
    const {
      offset,
      limit,
      limitStatic,
      offsetStatic
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
      return;
    }

    this.props.searchBuilders(values, limit, (offset - limit),limitStatic, (offsetStatic - limitStatic));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
     limit,
     count,
     limitStatic,
     offsetStatic
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
      return;
    }

    this.props.searchBuilders(values, limit, (offset + limit), limitStatic, (offsetStatic + limitStatic));
    window.scroll(0,0)
  };
//***************** */
handleGoToPage = () => {
  const {
  
   limit,
   count,
   limitStatic,
   
  } = this.props;
  const values =
    (this.props.form !== undefined && this.props.form.values) || {};
   // use Math.abs for strict equality ===  to work and safe guard against negative value from user
   if (Math.abs(this.state.pageO) === 0) {
    return; 
  }

const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)


  this.props.searchBuilders(values, limit, (page * limit), limitStatic, (page * limitStatic));
  window.scroll(0,0)

};

//****************** */
  renderPaginator() {
    if (this.props.builders.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }

  //************************
  renderList=(company)=> {
    const {
      nameClass,
      cname,
      
      cimage,
      
      locality,
      city,
      
      nameCP1,
      mobileCP1,
      
      designationCP1,
      
      nameCP2,
      mobileCP2,
      
      designationCP2,
      companySubscriptionStartOn,
      companySubscriptionExpireOn,
      photoCP1,
    photoCP2,
      
      _id
    } = company;

    
          //************************
  

    const renderButton = () =>
      (
        <Button 
        // basic
        // inverted
        floated="right"
        size="mini"
        color="teal"
        type="button"
        as={Link}
        //target='_blank'
        to={`/builder/builder/${_id}`}
      >
        Detail
      </Button>

      ) 

 
      
//###########################

const rendermobileCP1 = () => 
  (
 <span >
 <span >
   {mobileCP1}
 </span>
 <span className='nodisplay-tab'>
    <CopyToClipboard text={mobileCP1}  >
    <span className="icon-button"  onClick= {() => this.handleOpenPopup(mobileCP1,'blue' )}>
 <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
   </CopyToClipboard>
   </span>
   </span>
   )
   const rendermobileCP2 = () => 
       (
       <span >
       <span >
         {mobileCP2}
       </span>
       <span className='nodisplay-tab'>
          <CopyToClipboard text={mobileCP2}  > 
          <span className="icon-button"  onClick= {() => this.handleOpenPopup(mobileCP2,'teal' )}>
       <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
       </span>
         </CopyToClipboard>
         </span>
         </span>
        )

  //******************
  const classN = nameClass ==='static'? 'cardStatic border mb-010':'card border mb-010'
//*******************************************
return (
  <div key={_id} >
        <div className={classN} style={{padding:'5px'}}>{/* 1 */}


        <div className='disflex-dsl border-bottom nodisplay' >{/* 2 */}

        <img className= 'yimg imgDimention' src={`${cimage}`} alt="Builder Logo"  />

        <div style={{flex:'80%',  marginLeft:'10px',padding:'0px'}}>
          <div className='font-dsl-head'> {cname}</div >
          <div className='font-dsl-loc'>{locality}{' '}{city} New Delhi </div>
          </div>

        </div>{/* 2 */}
        {/* for mobile start */}
<div className='nodisplay-tab'>
<div className='disflex-dsl border-bottom' >{/* 2 */}

                <img className= 'yimg imgDimention-ad' src={`${cimage}`} alt="Builder Logo"  />

                <div style={{flex:'80%',  marginLeft:'10px',padding:'0px'}}>
                <div className='font-dsl-head-main'> {cname}</div >

                </div>

                </div>{/* 2 */}
                <div className='font-dsl-loc-main'>{locality}{' '}{city} New Delhi </div>

</div>
        {/* for mobile end */}
        <div> {/* 3 */}
        <div style={{padding:'5px'}}>{/* 4 */}
        <div className='display-end' style={{marginBottom:'10px'}}>
        <div className= 'display-flex' >  
        
        {renderButton()}
        <Button
          size="mini"
          floated="right"
          color="orange"
          onClick={() => this.handleUpdateRanking(_id)}
        >
          Update Ranking
        </Button>
        </div>

         </div>
         {/*  */}
<div className='media-600 ' style={{display:'flex',justifyContent:'center'}}> {/*start of div containing cp1 and cp2 detail */}

{ nameCP1 &&  (<div className='card-cp' > {/* cp1 detail*/}
  <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
  <img 
  className="icon-button"
  onClick={() => this.handleOpenModal(photoCP1)}
  src={`${photoCP1}`} alt="Photo_First contact person" 
  style={{width:"100px", height:"100px",padding:'5px'}} />
  </div>


<div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


<div>{nameCP1 && (
<div >
  {nameCP1}
</div>
)}
</div>  {/* */}

<div>{designationCP1 && (
<div>{designationCP1}</div>
)}</div>  {/* */}
<div>{mobileCP1 && (
rendermobileCP1()
)}</div>
  {/* */}

  </div>
  </div>)}
{/*end cp1 details */}

  { nameCP2 &&  (<div className='card-cp' >{/* cp2 detail*/}
  <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
  <img
  className="icon-button"
  onClick={() => this.handleOpenModal(photoCP2)}
  src={`${photoCP2}`} alt="Photo_Second contact person" 
  style={{width:"100px", height:"100px",padding:'5px'}} />
  </div>


<div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


<div>{nameCP2 && (
<div >
  {nameCP2}
</div>
)}
</div>  {/* */}
<div>{designationCP2 && (
<div>{designationCP2}</div>
)}</div>  {/* */}
<div>{mobileCP2 && (
rendermobileCP2()
)}</div>
  {/* */}

  </div>
  </div>)}

</div> 

    {/*end of div containing cp1 and cp2 detail */}

{/* <div className='row'></div> */}

<div > 
                  {companySubscriptionStartOn && (
                  <div className='row'>
                  <div className='side'>
                  <b>Subscription Start On</b>
                  </div>
                  <div className='main'>

                  <b>{ format(new Date(companySubscriptionStartOn), "dd-MM-yyyy")}</b>{' '}
                  
                  </div>

                  </div>
                  )}
                  </div>

                  <div >
                  {companySubscriptionExpireOn && (
                  <div className='row'>
                  <div className='side'>
                  <b>Subscription Expire On</b>
                  </div>
                  <div className='main'>

                  <b>{ format(new Date(companySubscriptionExpireOn), "dd-MM-yyyy")}</b>{' '}
                  
                  </div>

                  </div>
                  )}
                  </div>

  <div >
  {_id  && (
   <div className='row'>
     <div className='side'>
       <b>_id</b>
     </div>
     <div className='main'>
      <b>{_id}</b> 
     </div>

   </div>
 )}
</div>

</div>{/* 4 */}


        </div>{/* 3 */}

        </div>{/*1  */}
  </div>



);


  }

  //###########################################renderListStatic end
  renderLoading() {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }


  render() {
    //********************** */
    return (

      <div>
      <div className='maindiv-820'>
{/**/}
<div className='cashbook-H box-shadow'>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchComp}>
      <span className='icon-button border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.count}{' '}Builders
</div>
</div>


</div>
{/**/}
      <div>
      
        {this.renderLoading()}


        {this.props.builders.map(this.renderList)}

        
        {/*  */}
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
{/*  */}

      <br/><br/><br/>

      </div>
      


      </div>
      </div>

        );

    //************************ */


  }
}

const mapStateToProps = state => {

  return {

    fetching: state.builders.fetching,
    offset: state.builders.offset,
    limit: state.builders.limit,
    count: state.builders.count,
    builders: state.builders.builders,
    users: state.auth.usersA,
    userA: state.auth.userA,
    auth:state.auth,
    form: state.form.BuilderSearch
  };
};
export default connect(
  mapStateToProps,
  { openModal, searchBuilders ,updateBuilderRanking,}
)(BuilderSearchList);
