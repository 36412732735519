

import isEmpty from '../validation/is-empty';

import {
  SET_CURRENT_USER,
  SEARCH_USERS,
  FETCH_USERS,
  FETCH_USER,
  FETCH_USERDETAIL,
  // ***
  SHOW_SIDEBAR,
  HIDE_SIDEBAR,
  SHOW_BACKBUTTON ,
  HIDE_BACKBUTTON ,

} from '../actions/types';

const INITIAL_STATE = {
  isAuthenticated: false,
  user: null,
  usersForData: [],
  usersA: [],
  userA: null,
  userD: null,
  offset: 0,
  limit: 10,
  visible:false,
  visibleBB:true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    //###################################################
    case FETCH_USERS:
      return { ...state, ...action.payload };

    case FETCH_USER:
      return { ...state, userA: action.payload };
      case FETCH_USERDETAIL:
        return { ...state, userD: action.payload };
    
    case SEARCH_USERS:
     
      return { ...state, ...action.payload };
//*********************SIDEBAR */
        case SHOW_SIDEBAR:
        return { ...state, visible: action.payload };
        case HIDE_SIDEBAR:
        return { ...state, visible: action.payload };
        //*********************backbutton */
        case SHOW_BACKBUTTON:
        return { ...state, visibleBB: action.payload };
        case HIDE_BACKBUTTON:
        return { ...state, visibleBB: action.payload };
    default:
      return state;
  }
};
