import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";
import { openModal } from "../../actions/modalAction";
import {
  Button,
} from "semantic-ui-react";



class PropComSummary extends Component {
  state = {
    id:'',
  };
  

  handleOpenModalImage = (id,imageOf) => {   
    this.props.openModal("ShowImagesModal" , {id:id,imageOf:imageOf});
  };
  

//*******************************************************

  renderProjectSummary=()=> {
    const {
      imagePath,
      _id,
      projectType,
      category,
      propertyTypeProj,
      projectName,
      
        locality,
       
      locationOnMap,
      configuration,
      status,
      
      totalFloors,
    } = this.props.projectCom;

    const renderconfiguration = () => {
      return configuration.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{' '}{item} <span style={{color:'blue'}}>|</span></b>
          </span>
        );
      });
    };
    const renderpropertyTypeProj = () => {
      return propertyTypeProj.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{' '}{item} <span style={{color:'blue'}}>|</span></b>
          </span>
        );
      });
    };

    

   
  //******************



const classN = (this.props.nameClass ==='static'? 'cardStatic-1':'card-1')
const classBG = (this.props.nameClass ==='static'? 'card border cardBackground':'card border ')

    return (
<div key={_id}  style={{margin:'5px'}}>




                <div className={classBG}  style={{padding:'0px',borderRadius:'5px'}} >

                  <span className="yribbon1">
                    <span style={{ fontSize:'14px',color:'white'}}>
                   
                  <b>{category}</b>
                    </span>
                  </span>

                {/* property summary section + image section */}
                <div className={classN} >

                  {/* property summary section  */}
            <div className='flex-value-B 'style={{paddingRight:'10px',margin: 'auto'}}>


            <div>{configuration && configuration.length >0 && (
          <span  className='bathroom'>
            {' '}{renderconfiguration()}{' '}
          </span>
      )}
      {propertyTypeProj && propertyTypeProj.length >0 && (
            <span  className='furnishedStatus'>
              {' '}{renderpropertyTypeProj()}{' '}
            </span>
        )}

      {status && (
            <span  className='status nodisplay'>
            { ' '}{' '}{status} {' '}
            </span>
        )}


      {totalFloors && (
          <span className='furnishedStatus nodisplay'>
            {' '}{totalFloors} Floors{' '}
          </span>
      )}{ ' '}

      {projectType && (
          <span className='category nodisplay'>
            {' '}{projectType}{' '}
          </span>
      )}{ ' '}
      {category && (
          <span className='furnishedStatus nodisplay'>
           {' '} {category}{' '}
          </span>
      )}{ ' '}


            </div>  {/* for property type*/}

{/* project and location section for desktop */}
<div className=' nodisplay'  >
           <div>
             {projectName && (
                <div className='projectName-proj'>
                  {projectName}</div>
            )}
            </div>  {/* for project name*/}

            <div className='locality'>
              {locality && (
              <span>
              <a target='_blank' href={`${locationOnMap}`}>
                <span >
                  {locality} Dwarka New Delhi{' '}
                </span>

                  <span style={{fontSize:'1.0rem',color:'blue' }}>
                  {' '}<img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"16px", height:"16px"}} />
                  </span>
                  </a>
                  </span>

            )}
            </div>  {/* for location and city etc.*/}
          
            </div>


            <div className='display-flex nodisplay'  >

                <Button
               // inverted
                floated="right"
                size="mini"
                color="teal"
                  // basic
                  // inverted

                  type="button"
                  as={Link}
                //  target='_blank'
                  to={`/projectC/ProjectCDetail/${_id}`}
                >
                Project Detail
                </Button>
                

              </div>

                  </div>
                  {/* end property summary section  */}
                  {/* image section */}
                  <div className='flex-value-A' style={{display:'flex',flexDirection:'column'}}>

                  <div onClick={()=>this.handleOpenModalImage(_id,'Project')}>
                  <img className= 'yimg image-respo' src={`${imagePath}`} alt="Display_Image" width="170" height="128" />
            </div>
            
                  </div>
                  {/*end image section  */}

                  </div>
{/* project and location section for mobile */}
                  <div className='nodisplay-tab pxy-10'>
                  <div>
{status && (
            <span  className='status '>
            { ' '}{' '}{status} {' '}
            </span> 
        )}


      {totalFloors && (
          <span className='furnishedStatus '>
            {' '}{totalFloors} Floors{' '}
          </span>
      )}{ ' '}

      {projectType && (
          <span className='category '>
            {' '}{projectType}{' '}
          </span>
      )}{ ' '}
      {category && (
          <span className='furnishedStatus '>
           {' '} {category}{' '}
          </span>
      )}{ ' '}
      {/* <span className='propertyFor '>
      {' '} Apartments{' '}
      </span> */}
</div>

                  <div>
             {projectName && (
                <div className='projectName-proj'>
                  {projectName}</div>
            )}
            </div>  {/* for project name*/}

            <div className='locality'>{locality && (
              <span>
              <a target='_blank' href={`${locationOnMap}`}>
                <span >
                  {locality} Dwarka New Delhi{' '}
                </span>

                  <span style={{fontSize:'1.0rem',color:'blue' }}>
                  {' '}<img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"16px", height:"16px"}} />
                  </span>
                  </a>
                  </span>

            )}
            </div>  {/* for location and city etc.*/}
                  </div>
                  {/*  */}
                  <div className='display-flex nodisplay-tab' style={{marginBottom:'5px'}}  >

                <Button
               // inverted
                floated="right"
                size="mini"
                color="teal"
                  // basic
                  // inverted

                  type="button"
                  as={Link}

                  to={`/projectC/ProjectCDetail/${_id}`}
                >
                Project Detail
                </Button>
                

              </div>
                  </div>
</div>
    );
  }

  // ********** 

  render() {
  
    return (

<div >


{this.renderProjectSummary()}

</div>



    );
  }
}




export default connect(
  null,
 {openModal,}
)(PropComSummary);



