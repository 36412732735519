import React from 'react';
import { Modal, } from 'semantic-ui-react';

const Confirm = ({  openB, onConfirmBulk, onCancelBulk }) => {
  
  return (
   
      <Modal size="mini" 
      open={openB} 
      //onClose={onCancel}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      >
       
        <Modal.Content className='border'>
        <div> <h6>Do You Want To Bulk Delete ?</h6></div>
          <div className= 'display-center'>
          
          <span
            className="btntask" style={{background:'#cc3333'}}
              onClick={onConfirmBulk}

          >
            Delete
          </span>
          <span
            className="btntask" 
              onClick={onCancelBulk}

          >
            Cancel
          </span>
          </div>
        </Modal.Content>
      </Modal>
    
  );
}



export default Confirm;
