import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import {updatePropResi,fetchPropResi} from "../../actions/propresiAction";
import { withRouter} from "react-router-dom";

import {
  Segment,
  Form,
  Button,
  Header, 
  Container
} from "semantic-ui-react";
import TextInput from "../form/TextInput";
import TextArea from "../form/TextArea";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";
import NumberInput from "../form/NumberInput";

import {
  advertisedBy,
  propertyForOption,
  propertyType,
  brokerResponse,
  category,
  areaUnit,
  bedroom,
  bathroom,
  balcony,
  additionalRooms,
  furnishedStatus,
  interior,
  status,
  adAllowedToDealers,
  loanOfferedBy,
  typeOfOwnership,
  facing,
  entry,
  Overlooking,
  floorNo,
  totalFloors,
  // locality,
  //************
  willingToRentOutTo,
tenantsWhoAreNonVegetarians,
tenantsWithPets,
companyLease,
agreement,
//******
propresiStatus,
projectType,
numberOption,
booleanOption,
frequency,
//************
// adCategory,
//   adFor,
//   adType,
//   adLocationAll,
//   adLocationSector,
//   adSubLocation,
//   adLocationSimilar,
//   adLocationDealer,

} from './Data';
import { projectResi, projectResiId,
  locality,userName, userId, financerName, financerId, companyId
} from "../functions/DataFunctions";
import {
  composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {isValidEmail,isValidPhone,} from "../validator/revalidator";

const validate = combineValidators({
  phone1:composeValidators(
    //isRequired('Phone1'),
    isValidPhone({}),

  )(),
  // phone2:isValidPhone({}),
  email:isValidEmail({}),
  whatsapp: isValidPhone({}),
  propertyFor : isRequired('Property For'),
  category : isRequired('Category'),
  propertyType:isRequired('Property Type') ,
  bedroom : isRequired('Bedroom'),
  bathroom : isRequired('Bathroom'),
  furnishedStatus : isRequired('Furnished Status'),
  status : isRequired('Status'),
  expectedPrice : isRequired('Demand'),
  expectedRent : isRequired('Rent Demand'),
  projectName : isRequired('Project Name'),
  projectId : isRequired('Project Id'),
  projectType : isRequired('Project Type'),
  locality : isRequired('Locality'),
  advertisedBy:isRequired('Advertised By') ,
  brokerResponse:isRequired('brokerResponse') ,
  creditRequireForAd :isRequired('Credit Require For Ad ') ,
  creditRequireForFinancerAd :isRequired('Credit Require For Financer Ad ') ,
  
  propresiStatus : isRequired('propresi Status'),
});




class PropResiEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchPropResi(id);
            
   }
  
  
  landmarks = () => {
    return [{_id:'',projectName:'',landmarks:'N/A'},...this.props.state.projectRs.projectRsForData].map(value => ({
      key: value.landmarks,
      text: `${value.landmarks} ( ${value.projectName} | ${value._id} )` ,
      value: value.landmarks,
    }));
  };
  locationOnMap = () => {
    return [{_id:'',projectName:'',locationOnMap:'N/A'},...this.props.state.projectRs.projectRsForData].map(value => ({
      key: value.locationOnMap,
      text: `${value.locationOnMap} ( ${value.projectName} | ${value._id} )` ,
      value: value.locationOnMap,
    }));
  };
    
  
  //** */
  onSubmit = values => {
    const { id } = this.props.match.params;
    this.props.updatePropResi(id, values, this.props.history);
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  render() {

        const { aproxValueOfWork, expectedPrice,expectedRent,
          securityDeposit,otherCharges,maintenanceCharges,
          propertyFor, adAllowedNumber,
          creditRequireForAd ,creditRequireForFinancerAd ,} =
          this.props.state.form &&
          this.props.state.form.PropResiEdit !== undefined &&
          this.props.state.form.PropResiEdit.values !== undefined &&
          this.props.state.form.PropResiEdit.values;
    const { handleSubmit, pristine, reset, submitting } = this.props;
    const renderSaleOrRentField = ()=>{
      if(propertyFor==='SALE'){
        return (
         < React.Fragment>
<label >
                  Demand{' '}
                  <span>
                    <b>
                      ({writtenNumber(expectedPrice, {
                        lang: 'enIndian'
                      }).toUpperCase()}
                      ){' '}
                    </b>
                  </span>
                </label><span className='color-mandatory'> * </span>
                <Field
                  name="expectedPrice"
                  placeholder="Demand"
                  type="number"
                  component={NumberInput}
                />
         </React.Fragment>
        )
      }else{
        if(propertyFor==='RENT'||propertyFor==='PG ACCOMMODATION'){
          return (
            < React.Fragment>
<label >
                  Rent Demand{' '}
                  <span>
                    <b>
                      ({writtenNumber(expectedRent, {
                        lang: 'enIndian'
                      }).toUpperCase()}
                      ){' '}
                    </b>
                  </span>
                </label><span className='color-mandatory'> * </span>
                <Field
                  name="expectedRent"
                  placeholder="Rent Demand"
                  type="number"
                  component={NumberInput}
                />
                <label >
                  SecurityDeposit{' '}
                  <span>
                    <b>
                      ({writtenNumber(securityDeposit, {
                        lang: 'enIndian'
                      }).toUpperCase()}
                      ){' '}
                    </b>
                  </span>
                </label>
                <Field
                  name="securityDeposit"
                  placeholder="SecurityDeposit"
                  type="number"
                  component={NumberInput}
                />
                <label >
                  Maintenance Charges{' '}
                  <span>
              <b>
                      ({writtenNumber(maintenanceCharges, {
                        lang: 'enIndian'
                      }).toUpperCase()}
                      ){' '}
                    </b>
                  </span>
                </label>
                <Field
                  name="maintenanceCharges"
                  placeholder="Maintenance Charges"
                  type="number"
                  component={NumberInput}
                />
                <label >Frequency Of Maintenance Charges</label>
                <Field
                  name="unitMaintenanceCharges"
                  placeholder="Monthly,Quarterly,Yearly,One-Time"
                  type="text"
                  options={frequency()}
                  search={true}
                  component={SelectInput}
                />
                <label >
                  Other Charges{' '}
                  <span>
                    <b>
                      ({writtenNumber(otherCharges, {
                        lang: 'enIndian'
                      }).toUpperCase()}
                      ){' '}
                    </b>
                  </span>
                </label>
                <Field
                  name="otherCharges"
                  placeholder="Other Charges"
                  type="number"
                  component={NumberInput}
                />
                <label >Frequency Of Other Charges</label>
                <Field
                  name="unitOtherCharges"
                  placeholder="Monthly,Quarterly,Yearly,One-Time"
                  type="text"
                  options={frequency()}
                  search={true}
                  component={SelectInput}
                />


                <label >Willing To RentOut To</label>
                <Field
                  name="willingToRentOutTo"
                  placeholder="Willing To RentOut To"
                  type="text"
                  options={willingToRentOutTo()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Not Willing To RentOut To</label>
                <Field
                  name="notWillingToRentOutTo"
                  type="text"
                  component={TextInput}
                  placeholder="Bussinessmen,Advocate,Police,Govt Job,Private Job"
                />
                <label >Tenants Who Are NonVegetarians</label>
                <Field
                  name="tenantsWhoAreNonVegetarians"
                  placeholder="Tenants Who Are NonVegetarians"
                  type="text"
                  options={tenantsWhoAreNonVegetarians()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Tenants With Pets</label>
                <Field
                  name="tenantsWithPets"
                  placeholder="Tenants With Pets"
                  type="text"
                  options={tenantsWithPets()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Company Lease</label>
                <Field
                  name="companyLease"
                  placeholder="Company Lease"
                  type="text"
                  options={companyLease()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Agreement Type</label>
                <Field
                  name="agreement"
                  placeholder="Agreement Type"
                  type="text"
                  options={agreement()}
                  //search={true}
                  component={SelectInput}
                />

         </React.Fragment>
          )
        }
      }
    }

//*************** */
    if (
      this.props.userA !== null &&
      (this.props.userA.userType === 'admin')
      ) {
      return (
        <div style={{maxWidth:'700px',margin:'auto'}}>
          <Segment attached
          textAlign="center"
          //attached="bottom"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Edit Residencial Property "
              />
        </Segment>
        <div className="card-form ">
          <div className=" label" >

              <Form  style={{ padding: 25 }}  onSubmit={handleSubmit(this.onSubmit)}>

              <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
              <Button
                size="mini"
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
              <br /><br />
              <div><span className='color-mandatory'>* </span>Mandatory Fields</div>
                

        <Header
        color="blue"
        content="Property Details"
        />


        <label >Property For</label><span className='color-mandatory'> * </span>
        <Field
        name="propertyFor"
        placeholder="Property For"
        type="text"
        options={propertyForOption()}
        //search={true}
        component={SelectInput}
        />
        <label >Category</label><span className='color-mandatory'> * </span>
        <Field
        name="category"
        placeholder="Category"
        type="text"
        options={category()}
        //search={true}
        component={SelectInput}
        />
        <label >Property Type</label><span className='color-mandatory'> * </span>
        <Field
        name="propertyType"
        placeholder="Property Type"    
        type="text"
        options={propertyType()}
        //search={true}
        component={SelectInput}
        />
        {renderSaleOrRentField()}
        <label >Carpet Area In Square Foot</label>
        <Field
        type="number"
        component={NumberInput}
        placeholder="Carpet Area"
        name="carpetArea"
        />
        <label >Area</label> 
        <Field
        name="area"
        type="number"
        component={NumberInput}
        placeholder="Area"
        />
        <label >Unit Of Area</label>
        <Field
        name="areaUnit"
        placeholder="Unit Of Area"
        type="text"
        options={areaUnit()}
        //search={true}
        component={SelectInput}
        />
        <label >Bedrooms</label><span className='color-mandatory'> * </span>
        <Field
        name="bedroom"
        placeholder="Bedrooms"
        type="text"
        options={bedroom()}
        //search={true}
        component={SelectInput}
        />
        <label >Additional Rooms</label>
        <Field
        name="additionalRooms"
        placeholder="Additional Rooms"
        type="text"
        options={additionalRooms()}
        //search={true}
        multiple={true}
        component={SelectInput}
        />
        <label >Bathrooms</label><span className='color-mandatory'> * </span>
        <Field
        name="bathroom"
        placeholder="Bathrooms"
        type="text"
        options={bathroom()}
        //search={true}
        component={SelectInput}
        />
        <label >Balconies</label>
        <Field
        name="balcony"
        placeholder="Balconies"
        type="text"
        options={balcony()}
        //search={true}
        component={SelectInput}
        />



        <label >Furnished Status</label><span className='color-mandatory'> * </span>
        <Field
        name="furnishedStatus"
        placeholder="Furnished Status"
        type="text"
        options={furnishedStatus()}
        //search={true}
        component={SelectInput}
        />
        <label >Interior</label>
        <Field
        name="interior"
        placeholder="Interior"
        type="text"
        options={interior()}
        //search={true}
        component={SelectInput}
        />
        <label >
        Aprox Value Of Interior Work{' '}
        <span>
        <b>
        ({writtenNumber(aproxValueOfWork, {
        lang: 'enIndian'
        }).toUpperCase()}
        ){' '}
        </b>
        </span>
        </label>
        <Field
        type="number"
        component={NumberInput}
        placeholder="Aprox Value Of Work"
        name="aproxValueOfWork"
        />

        {/*  Rent   */}
        <label >Wardrobes</label>
        <Field
        name="wardrobes"
        placeholder="Number of Wardrobes if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}
        />
        <label >AC</label>
        <Field
        name="ac"
        placeholder="Number of AC if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Fan</label>
        <Field
        name="fan"
        placeholder="Number of Fan if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Bed</label>
        <Field
        name="bed"
        placeholder="Number of Bed if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Dining Table</label>
        <Field
        name="diningTable"
        placeholder="Number of Dining Table if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Sofa</label>
        <Field
        name="sofa"
        placeholder="Number of Sofa if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Curtains</label>
        <Field
        name="curtains"
        placeholder="Number of Curtains if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >TV</label>
        <Field
        name="tv"
        placeholder="Number of TV if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Geyser</label>
        <Field
        name="geyser"
        placeholder="Number of Geyser if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Bathtub</label>
        <Field
        name="bathtub"
        placeholder="Number of Bathtub if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Modular Kitchen</label>
        <Field
        name="modularKitchen"
        placeholder="Yes or No"
        type="text"
        options={booleanOption()}
        search={true}
        component={SelectInput}
        />
        <label >Refrigerator</label>
        <Field
        name="refrigerator"
        placeholder="Number of Refrigerator if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Microwave</label>
        <Field
        name="microwave"
        placeholder="Number of Microwave if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >RO</label>
        <Field
        name="ro"
        placeholder="Number of RO if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Hob</label>
        <Field
        name="hob"
        placeholder="Number of Hob if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Chimney</label>
        <Field
        name="chimney"
        placeholder="Number of Chimney if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >Washing Machine</label>
        <Field
        name="washingMachine"
        placeholder="Number of Washing Machine if any"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}

        />
        <label >IGL Gas Connection</label>
        <Field 
        name="iglGasConnection"
        placeholder="Yes or No"
        type="text"
        options={booleanOption()}
        search={true}
        component={SelectInput}
        />

        <label >Other Items</label>
        <Field
        type="text"
        component={TextInput}
        placeholder="Other Items"
        name="others"
        />



        <label >Facing</label>
        <Field
        name="facing"
        placeholder="Facing"
        type="text"
        options={facing()}
        //search={true}
        component={SelectInput}
        />
        <label >Entry</label>
        <Field
        name="entry"
        placeholder="Entry"
        type="text"
        options={entry()}
        //search={true}
        component={SelectInput}
        />
        <label >Overlooking</label>
        <Field
        name="Overlooking"
        placeholder="Overlooking"
        type="text"
        options={Overlooking()}
        //search={true}
        component={SelectInput}
        />
        <label >Covered Car Parking</label>
        <Field
        name="carParkingCovered"
        placeholder="Covered Car Parking"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}
        />
        <label >Open Car Parking</label>
        <Field
        name="carParkingOpen"
        placeholder="Open Car Parking"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}
        />
        <label >Floor No</label>
        <Field
        name="floorNo"
        placeholder="Floor No"
        type="text"
        options={floorNo()}
        //search={true}
        component={SelectInput}
        />
        <label >Total Floors</label>
        <Field
        name="totalFloors"
        placeholder="Total Floors"
        type="text"
        options={totalFloors()}
        //search={true}
        component={SelectInput}
        />
        <label >Lifts In The Tower</label>
        <Field
        name="liftsInTheTower"
        type="text"
        options={numberOption()}
        search={true}
        component={SelectInput}
        placeholder="Number of Lifts In The Tower if any"
        />
        <label >Type Of Ownership</label>
        <Field
        name="typeOfOwnership"
        placeholder="Type Of Ownership"
        type="text"
        options={typeOfOwnership()}
        //search={true}
        component={SelectInput}
        />
        <label >Status</label><span className='color-mandatory'> * </span>
        <Field
        name="status"
        placeholder="Status"
        type="text"
        options={status()}
        //search={true}
        component={SelectInput}
        />
        <label >Possession</label>
        <Field
        name="possession"
        type="text"
        component={DateInput}
        placeholder="Possession"
        dateFormat="dd-MM-yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        />
        <label >Loan Offered By</label>
        <Field
        name="loanOfferedBy"
        placeholder="Loan Offered By"
        type="text"
        options={loanOfferedBy()}
        //search={true}
        component={SelectInput}
        />
        <label >Address</label>
        <Field
        name="address"
        type="text"
        component={TextInput}
        placeholder="Address"
        />
        <label >Society/Complex Name</label><span className='color-mandatory'> * </span>
        <Field
        name="projectName"
        placeholder="Society/Complex Name"
        type="text"
        options={projectResi(this.props.state.projectRs.projectRsForData)}
        search={true}
        component={SelectInput}
        />
        <label >Project Id</label><span className='color-mandatory'> * </span>
        <Field
        name="projectId"
        placeholder="Project Id"
        type="text"
        options={projectResiId(this.props.state.projectRs.projectRsForData)}
        search={true}
        component={SelectInput}
        />
        <label >Project Type</label><span className='color-mandatory'> * </span>
        <Field
        name="projectType"
        placeholder="Project Type"
        type="text"
        options={projectType()}
        //search={true}
        component={SelectInput}
        />

        <label >Locality</label><span className='color-mandatory'> * </span>
        <Field
        name="locality"
        placeholder="Locality"
        type="text"
        options={locality(this.props.state.appParameters.localitys)}
      search={true}
        component={SelectInput}
        />


        {/*  */}
        <label >Location On Map</label>
        <Field
        name="locationOnMap"
        type="text"
        options={this.locationOnMap()}
        search={true}
        component={SelectInput}
        placeholder="Location On Map"
        />

        <label >Landmarks</label>
        <Field
        name="landmarks"
        placeholder="Landmarks"
        type="text"
        options={this.landmarks()}
        search={true}
        component={SelectInput}
        />
        <label >About/USP of Property</label>
        <Field
        name="description"
        placeholder="About/USP of Property"
        type="text"
        component={TextArea}
        rows={3}
        />

        <Header
        color="blue"
        content="Contact Details Of Owner"
        />
        <label >Owner Name </label>
        <Field
        name="name"
        type="text"
        component={TextInput}
        placeholder="Owner Name"
        />
        <label >Phone1</label>
        <Field
        name="phone1"
        type="text"
        component={TextInput}
        placeholder="Phone1"
        />
        <label >Phone2</label>
        <Field
        name="phone2"
        type="text"
        component={TextInput}
        placeholder="Phone2"
        />
        <label >Email</label>
        <Field
        name="email"
        type="text"
        component={TextInput}
        placeholder="Email"
        />
        <label >Whatsapp ID</label>
        <Field
        name="whatsapp"
        type="text"
        component={TextInput}
        placeholder="Whatsapp ID"
        />
        <Header
        color="blue"
        content="For My Property Functionality"
        />
        <label >User Id</label>
        <Field
        name="userS"
        placeholder=" User _id"
        type="text"
        search={true}
        component={SelectInput}
        options={userId(this.props.state.auth.usersForData)}
        />
        <label >User's Name</label>
        <Field
        name="userName"
        placeholder="User's Name"
        type="text"
        search={true}
        component={SelectInput}
        options={userName(this.props.state.auth.usersForData)}
        />
        {/*  */}

        <Header
        color="blue"
        content="For Ad Functionality"
        />
        <label >Advertised By</label><span className='color-mandatory'> * </span>
        <Field
        name="advertisedBy"
        placeholder="You Are"
        type="text"
        options={advertisedBy()}
        //search={true}
        component={SelectInput}
        />
        <label >Broker Response</label><span className='color-mandatory'> * </span>
        <Field
        name="brokerResponse"
        placeholder="Broker Response"
        type="text"
        options={brokerResponse()}
        //search={true}
        component={SelectInput}
        />


        {/* <label >Finance Property Position</label>
        <Field
        name="positionFinPropRS"
        type="text"
        component={TextInput}
        placeholder="Position"
        /> */}
        {propertyFor === 'SALE' &&
        (<>
        <label >Financer's ID</label>
        <Field
        name="financerId"
        placeholder="Dealer's ID"
        type="text"
        search={true}
        component={SelectInput}
        options={financerId(this.props.state.companys.companysForData)}
        />
        </>)
        }

{propertyFor === 'SALE' &&
        (<>
        <label >Financer's Name</label>
        <Field
        name="financerName"
        placeholder="Dealer's Name"
        type="text"
        search={true}
        component={SelectInput}
        options={financerName(this.props.state.companys.companysForData)}
        />
        </>)
        }

{propertyFor === 'SALE' &&
        (<>
        <label >Finance Property Started On</label>
        <Field
        name="startedOn"
        type="text"
        component={DateInput}
        placeholder="Started On"
        dateFormat="dd-MM-yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        />
        </>)
        }

{propertyFor === 'SALE' &&
        (<>
        <label >Finance Property Expired On</label>
        <Field
        name="expiredOn"
        type="text"
        component={DateInput}
        placeholder="Expired On"
        dateFormat="dd-MM-yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        />
        </>)
        }
        

        {/*  */}
        <label >Block Ad</label>
        <Field
        name="adBlocked"
        placeholder="Yes or No if yes no ad will be create/renew"
        type="text"
        options={booleanOption()}
        search={true}
        component={SelectInput}
        />
                {/*  */}
                                       
<label >Ad Lock Allowed</label>
<Field
name="adlockAllowed"
placeholder="Yes / No if yes one dealer can lock ad space no other dealer allowed to ad"
type="text"
//search={true}
component={SelectInput}
options={booleanOption()}
/>
<label >
Ad Allowed Number{' '}
<span>
<b>
({writtenNumber(adAllowedNumber, {
lang: 'enIndian'
}).toUpperCase()}
){' '}
</b>
</span>
</label><span className='color-mandatory'> * </span>
<Field
type="number"
component={NumberInput}
placeholder="1,2,3,4 ..."
name="adAllowedNumber"
/>

<label >
Credit Require For Ad{' '}
<span>
<b>
({writtenNumber(creditRequireForAd, {
lang: 'enIndian'
}).toUpperCase()}
){' '}
</b>
</span>
</label><span className='color-mandatory'> * </span>
<Field
type="number"
component={NumberInput}
placeholder="5/10/15 ..."
name="creditRequireForAd"
/>

   <label >
        Credit Require For Financer Ad{' '}
        <span>
        <b>
        ({writtenNumber(creditRequireForFinancerAd, {
        lang: 'enIndian'
        }).toUpperCase()}
        ){' '}
        </b>
        </span>
        </label><span className='color-mandatory'> * </span>
        <Field
        type="number"
        component={NumberInput}
        placeholder="5/10/15 ..."
        name="creditRequireForFinancerAd"
        />
             
        
        <label >adAllowedToDealers</label><span className='color-mandatory'> * </span>
        <Field
        //search={true}
        name="adAllowedToDealers"
        type="text"
        component={SelectInput}
        options={adAllowedToDealers()}
        placeholder="All / Some"
        />
        <label >ids Of Dealer AllowedToAd</label>
        <Field
        name="idsOfDealerAllowedToAd"
        placeholder="ids Of Dealer AllowedToAd"
        type="text"
        search={true}
        multiple={true}
        component={SelectInput}
        options={companyId(this.props.state.companys.companysForData)}
        />
            {/*  */}
        <label >Property Inactive On</label>
        <Field
        name="inactiveOn"
        type="text"
        component={DateInput}
        placeholder="Inactive On"
        dateFormat="dd-MM-yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        />

        <label >propresi Status</label><span className='color-mandatory'> * </span>
        <Field
        //search={true}
        name="propresiStatus"
        type="text"
        component={SelectInput}
        options={propresiStatus()}
        placeholder="propresiStatus"
        />
        <label >Property Created On</label>
        <Field
        name="createdAt"
        type="text"
        component={DateInput}
        placeholder="Created On"
        dateFormat="dd-MM-yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        />
            {/*  */}
                <br />

                <Button
                  color="olive"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Submit
                </Button>
                <Button
                  color="orange"
                  type="button"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Clear Values
                </Button>
              </Form>
              <br/><br/><br/>
            </div>
            </div>
            </div>
      );
    }
    return (
      <Container text>
        <Segment>
          <div style={{ color: "red", textAlign: "center" }}>
            <h2>
              You Are Not Authorised To Edit Residencial Property For {" "}
            </h2>
          </div>
        </Segment>
      </Container>
    );
  }
}

export default connect(
  state => {

    return {
      state,
      userA: state.auth.userA,
      initialValues: state.propresis.propresi
    }; // pull initial values from account reducer
  },
   {updatePropResi,fetchPropResi,}
)(
  reduxForm({
    form: "PropResiEdit", // a unique identifier for this form
    validate
  })(withRouter(PropResiEdit))
);

    
   
