import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";

import {searchReqResisUnlocked} from "../../actions/reqresiAction";
import { closeModal } from "../../actions/modalAction";
import { Segment, Form, Button,  Header } from "semantic-ui-react";
import NumberInput from "../form/NumberInput";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";


import {
  requiredFor,
  propertyType,
  bedroom,
  bathroom,
  additionalRooms, 
  furnishedStatus,
  entry,
  floorNo,
  // locality,
  //booleanOption,
  sorting
} from "./Data";
import { projectResi, 
   locality, 
  // userName, userId, 

} from "../functions/DataFunctions";


  class ReqResiSearch extends Component {

   onSubmit = values => {
    this.props.searchReqResisUnlocked(values);
  };
  render(){
  const { handleSubmit, pristine, reset, submitting } = this.props;
  const { budgetMinL,budgetMinH,budgetMaxL,budgetMaxH,  } =
    this.props.state.form &&
    this.props.state.form.ReqResiSearch !== undefined &&
    this.props.state.form.ReqResiSearch.values !== undefined &&
    this.props.state.form.ReqResiSearch.values;



  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>
      <Segment
          textAlign="center"
          //attached="bottom"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Search  Requirement for Residential Property"
              />
        </Segment>
        <div className="card-form ">
          <div className=" label" >

          <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Button
              color="teal"
              size="mini"
              type="button"
              onClick={()=>this.props.closeModal()}

            >
              Cancel
            </Button>
          <Button
            size="mini"
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <br /><br />
          <label htmlFor="">Sort By</label>
          <Field
            //search={true}
            name="sorting"
            type="text"
            component={SelectInput}
            options={sorting()}
            placeholder="Sort By"
          />
          <label htmlFor="">Required For</label>
          <Field
            name="requiredFor"
            placeholder="Required For"
            type="text"
            options={requiredFor()}
            //search={true}
            component={SelectInput}
          />
            <label htmlFor="">Property Type</label>
            <Field
              name="propertyType"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label htmlFor="">Bedroom</label>
            <Field
              name="bedroom"
              placeholder="Bedroom"
              type="text"
              options={bedroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label htmlFor="">Bathroom</label>
            <Field
              name="bathroom"
              placeholder="Bathroom"
              type="text"
              options={bathroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label htmlFor="">Additional Rooms</label>
            <Field
              name="additionalRooms"
              placeholder="Additional Rooms"
              type="text"
              options={additionalRooms()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label htmlFor="">Furnished Status</label>
            <Field
              name="furnishedStatus"
              placeholder="Furnished Status"
              type="text"
              options={furnishedStatus()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />


            <label htmlFor="">Entry</label>
            <Field
              name="entry"
              placeholder="Entry"
              type="text"
              options={entry()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label htmlFor="">Floor No</label>
            <Field
              name="floorNo"
              placeholder="Floor No"
              type="text"
              options={floorNo()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label htmlFor="">Complex/Project Name</label>
            <Field
              name="projectName"
              placeholder="Complex/Project Name"
              type="text"
              options={projectResi(this.props.state.projectRs.projectRsForData)}
              search={true}
              multiple={true}
              component={SelectInput}
            />
<label htmlFor="">Locality</label>
<Field
name="locality"
placeholder="Locality"
type="text"
options={locality(this.props.state.appParameters.localitys)}
search={true}
multiple={true}
component={SelectInput}
/>


            <label htmlFor="">Minimum Budget Low {" "}
            <span>
              <b>
                ({writtenNumber(budgetMinL, {
                  lang: "enIndian"
                }).toUpperCase()}
                ){" "}
              </b>
            </span></label>
          <Field
            name="budgetMinL"
            placeholder="Lower Value of Minimum Budget to Search Requirement in a Range"
            type="number"
            component={NumberInput}
          />
          <label htmlFor="">Minimum Budget High {" "}
            <span>
              <b>
                ({writtenNumber(budgetMinH, {
                  lang: "enIndian"
                }).toUpperCase()}
                ){" "}
              </b>
            </span></label>
          <Field
            name="budgetMinH"
            placeholder="Higher Value of Minimum Budget to Search Requirement in a Range"
            type="number"
            component={NumberInput}
          />
          <label htmlFor="">Maximum Budget Low {" "}
            <span>
              <b>
                ({writtenNumber(budgetMaxL, {
                  lang: "enIndian"
                }).toUpperCase()}
                ){" "}
              </b>
            </span></label>
          <Field
            name="budgetMaxL"
            placeholder="Lower Value of Maximum Budget to Search Requirement in a Range"
            type="number"
            component={NumberInput}
          />
          <label htmlFor="">Maximum Budget High {" "}
            <span>
              <b>
                ( {writtenNumber(budgetMaxH, {
                  lang: "enIndian"
                }).toUpperCase()}
                ){" "}
              </b>
            </span></label>
          <Field
            name="budgetMaxH"
            placeholder="Higher Value of Maximum Budget to Search Requirement in a Range"
            type="number"
            component={NumberInput}
          />
          <label htmlFor="">Requirement Created FROM</label>
          <Field
            name="from"
            type="text"
            component={DateInput}
            dateFormat="dd-MM-yyyy"
            placeholder="Requirement Created FROM"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          <label htmlFor="">Requirement Created TO</label>
          <Field
            name="to"
            type="text"
            component={DateInput}
            dateFormat="dd-MM-yyyy"
            placeholder="Requirement Created TO"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />

            <br />

            <Button
              color="blue"
              type="submit"
              disabled={pristine || submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
          </Form>
          <br/><br/><br/>
              </div>
              </div>
              </div>
  );
}
};
const mapStateToProps = state => {
  return {
    state,

    userA: state.auth.userA
  };
};
export default connect(
  mapStateToProps,
  {searchReqResisUnlocked,closeModal}
)(
  reduxForm({
    form: "ReqResiSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(ReqResiSearch)
);
