import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {searchProjectCs} from "../../actions/projectCAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,  } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";

import {
  category,
  status,
  ageOfConstruction,
  
} from "./Data";

import { projectCom, locality, 
} from "../functions/DataFunctions";

const ProjectCSearch = props => {
  
  const { handleSubmit,  reset, submitting } = props;
  
  const onSubmit = values => {
const offset = 0
const { limit} = props.state.projectCs;

    props.searchProjectCs(values, limit,offset);
    props.closeModal()
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Commercial Projects
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form ">
          <div  >

          <Form 
          style={{ padding: '10px' }} 
          onSubmit={handleSubmit(onSubmit)}
          >

<Button
              color="teal"
              size='mini'
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
            
            <Button
            size='mini'
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            size='mini'
            style={{color:'black'}}
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          {/* <label htmlFor="">Sort By</label>
          <Field
            //search={true}
            name="sorting"
            type="text"
            component={SelectInput}
            options={sorting()}
            placeholder="Sort By"
          /> */}

            <label htmlFor="">Category</label>
            <Field
              name="categoryA"
              placeholder="Category"
              type="text"
              options={category()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            {/* <label htmlFor="">Configuration</label>
            <Field
              name="configurationA"
              placeholder="Configuration"
              type="text"
              options={configuration()}
              //search={true}
              multiple={true}
              component={SelectInput}
            /> */}
            <label htmlFor="">Status</label>
            <Field
              name="statusA"
              placeholder="Status"
              type="text"
              options={status()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label htmlFor="">Age Of Construction</label>
            <Field
              name="ageOfConstruction"
              placeholder="Age Of Construction"
              type="text"
              options={ageOfConstruction()}
              //search={true}
              //multiple={true}
              component={SelectInput}
            />

            <label htmlFor="">Project/Complex Name</label>
            <Field
              name="projectNameA"
              placeholder="Project/Complex Name"
              type="text"
              options={projectCom(props.state.projectCs.projectCsForData  )}
              search={true}
              multiple={true}
              component={SelectInput}
            />

            <label htmlFor="">Locality</label>
            <Field
              name="localityA"
              placeholder="Locality"
              type="text"
              options={locality(props.state.appParameters.localitys)}
              search={true}
              multiple={true}
              component={SelectInput}
            />

            <label htmlFor="">Possession By Date</label>
            <Field
              name="to"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Possession By Date "
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />


            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {searchProjectCs,closeModal}
)(
  reduxForm({
    form: "ProjectCSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(ProjectCSearch)
);
