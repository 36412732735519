import React, { Component } from "react";
import { connect } from "react-redux";
//import { Grid } from "semantic-ui-react";
import { Switch, Route,  } from "react-router-dom";
import { showBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";
//import PropResiNav from "./PropResiNav";
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import PropResiCreate from "./PropResiCreate";

import PropResiDetail from "./PropResiDetail";
import PropResiEdit from "./PropResiEdit";

// import PropResiAllSearchList from "./PropResiAllSearchList";
import PropResiSaleSearchList from "./PropResiSaleSearchList";
import BuilderFloorSaleSearchList from "./BuilderFloorSaleSearchList";
import PropResiRentSearchList from "./PropResiRentSearchList";


import Layout from "../layout/Layout"; 

class PropResiDashboard extends Component {
  componentDidMount() {
    this.props.getUserForAuth();
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };
  render() {

  return (
<div>
<div className='maindiv'>


<Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
      
      <Route
        exact
        path="/propresi/PropResiCreate"
        component={PropResiCreate}
      />
      <Route
        exact
        path="/propresi/PropResiDetail/:id"
        component={PropResiDetail}
      />
      <Route
        exact
        path="/propresi/PropResiEdit/:id"
        component={PropResiEdit}
      />

      {/* <Route
        exact
        path="/propresi/PropResiAllSearchList"
        component={PropResiAllSearchList}
      /> */}
      <Route
        exact
        path="/propresi/BuilderFloorSaleSearchList"
        component={BuilderFloorSaleSearchList}
      />
      <Route
        exact
        path="/propresi/PropResiSaleSearchList"
        component={PropResiSaleSearchList}
      />
      <Route
        exact
        path="/propresi/PropResiRentSearchList"
        component={PropResiRentSearchList}
      />
      
    </Switch>
    </Layout >
    </div>

<div >
    <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
</div>

</div>
  );
}
}
// const mapStateToProps = state => ({
// auth: state.auth
// });


export default connect(
  // mapStateToProps,
  null,
  { showBackButton,getUserForAuth, logoutUser  }
)(PropResiDashboard);
