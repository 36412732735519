import React, { Component } from "react";
import { connect } from "react-redux";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"

import {searchPropResiSales} from "../../actions/propresiAction";

import { openModal } from "../../actions/modalAction";
import {
  Icon, 
} from "semantic-ui-react";

import PropResiSummary from './PropResiSummary';





class PropResiSaleSearchList extends Component {
  state = {
    pageO:'',
    id:'',
  };
  componentDidMount() {
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
      
      const {
          offsetRS,
          limitRS,
      } = this.props;
      
      this.props.searchPropResiSales(values, limitRS, offsetRS );

    
  }
  handleSearchPropResiSale = () => {
    this.props.openModal("SearchPropResiSaleModal");
  };

  
//*******************************************************

  //^^^^^^^^^^^^^^^^^^^^^^^^^
  onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
//*******************************************************

  
  back = () => {
    const {
      //countRS,
      offsetRS,
      limitRS,
  } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offsetRS === 0) {
      return;
    }

    this.props.searchPropResiSales(values, limitRS, (offsetRS - limitRS));
    
    window.scroll(0,0)
  };

  advance = () => {
    const {
      countRS,
      offsetRS,
      limitRS,
  } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) ||{};
    if (offsetRS + limitRS > countRS) {
      return;
    }

    this.props.searchPropResiSales(values, limitRS, (offsetRS + limitRS));
    
    window.scroll(0,0)


  };
  //***************** */
 handleGoToPage = () => {
  const {
    countRS,
    limitRS,
} = this.props;
  const values =
    (this.props.form !== undefined && this.props.form.values) ||{};
  // use Math.abs for strict equality ===  to work and safe guard against negative value from user
  if (Math.abs(this.state.pageO) === 0) {
    return; 
  }

const page=(Math.abs(this.state.pageO*limitRS) > countRS)?(Math.ceil(countRS/limitRS-1)):(Math.abs(this.state.pageO)-1)


    this.props.searchPropResiSales(values, limitRS, (page * limitRS));
    
    window.scroll(0,0)

};

//****************** */
  renderPaginator=()=> {
    if (this.props.propresisRS !== undefined && this.props.propresisRS.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offsetRS}
          limit={this.props.limitRS}
          count={this.props.countRS}
        />
      );
    }
  }

  renderLoading=()=> {
    if (this.props.fetchingRS===true) {
      return (<Loading/>)
    }
  }

  renderNoProperty=()=> {
    if (this.props.propresisRS.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE </b></span>

        </div>
      );
    }
  }

  render() {
  
    return (

<div className='maindiv-820 '>


<div> {/* main column start */}

<div className='card-attached border-radius-top border-sb' style={{textAlign:'center',backgroundColor:'dodgerblue'}} >
	
{/**/}
<div style={{display:'flex',color:'white',padding:'5px'}}>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchPropResiSale}>
      <span className='icon-button border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.countRS} Residential Property For Sale
</div>
</div>


</div>
{/**/}

	</div>
  {/*  */}

    
  <div className='card-attached border-sb pxy-05'  >

{this.renderLoading()}

{this.props.propresisRS.map((item)=> (<PropResiSummary key={item._id} propresi={item}/>))}


</div>



{/*  */}

<div className='card-attached border-sb '  >

<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>

</div>
{/*  */}


<br/><br/><br/><br/>

</div> {/* main column end */}
{/* side column */}

{/* side column end */}
{/* side column display at bottom in mobile start */}

{/* side column mobile end */}


</div>



    );
  }
}


const mapStateToProps = ({ propresis,auth, form, }) => {
  const { fetchingRS,limitRS, offsetRS, countRS, } = propresis

  return {  
    fetchingRS,
    limitRS,
    offsetRS,
    countRS,
    form: form.PropResiSaleSearch,
    propresisRS: propresis.propresisRS,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {searchPropResiSales,openModal, }
)(PropResiSaleSearchList);
