import axios from "axios";
import { toastr } from "react-redux-toastr";
import {openModal, closeModal } from "./modalAction";

import {
  FETCH_CONTACTREQS,
  //FETCH_CONTACTREQ,
  SEARCH_CONTACTREQS,
  FETCHING_CONTACTREQS,
} from "./types";

export const resetFetching = () => async dispatch => {
  dispatch({ type:FETCHING_CONTACTREQS, payload: true });
};

//search  ContactReqs
export const searchContactReqs = (
  values,
  limit,
  offset
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/ContactReqSearch", {
      values,
      limit,
      offset
    });
   
    dispatch({ type: SEARCH_CONTACTREQS, payload: res.data });
    dispatch({ type:FETCHING_CONTACTREQS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch all ContactReqs for one Dealer
export const fetchContactReqsForDealer = (
  values,
  limit,
  offset) => async dispatch => {

  try {
    const res = await axios.post("/api/ContactReqListForDealer",{
      values,
      limit,
      offset
    } );

    dispatch({ type: FETCH_CONTACTREQS, payload: res.data });
    dispatch({ type:FETCHING_CONTACTREQS, payload: false });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//fetch all ContactReqs ByParty
export const fetchContactReqsByParty = (
  values,
  limit,
  offset) => async dispatch => {

  try {
    const res = await axios.post("/api/ContactReqListByParty",{
     values,
     limit,
      offset
    } );

    dispatch({ type: FETCH_CONTACTREQS, payload: res.data });
    dispatch({ type:FETCHING_CONTACTREQS, payload: false });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
