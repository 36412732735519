import React, { Component } from "react";
import { Link, } from "react-router-dom";
import { connect } from "react-redux";
import writtenNumber from "written-number";
import format from "date-fns/format";
//import Paginator from "../paginator/Paginator";
import {fetchMyReqResis} from "../../actions/reqresiAction";

import { getUserForAuth } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import {
  Header,
  // Divider, 
  // Container,
  // Segment,
  Icon,
  Button,
  // Label,
  // Modal,
} from "semantic-ui-react";



class ReqResiSearchList extends Component {
  state = {

      open: false,
      result: "show the modal to capture a result"
    };
  componentDidMount() {
    this.props.fetchMyReqResis();
  }

  capitalizeFirst=(input)=> {
  			var words = input.split(' ');
  			var CapitalizedWords = [];
  			words.forEach(element => {
  				CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
  			});
  			return CapitalizedWords.join(' ');
  		}

  renderList=(reqresi)=> {
    const {
      name,
      phone1,
      phone2,
      email,
      whatsapp,
      _id,
      // creditUsed,
      // veiwLimit,
      // dealers,
      requiredFor,
      propertyType,
      bedroom,
      bathroom,
      additionalRooms,
      furnishedStatus,
      projectName,
      locality,
      budgetMin,
      budgetMax,
      areaCarpetMin,
      areaCarpetMax,
      createdAt,
    } = reqresi;


    const renderpropertyType = () => {
      return propertyType.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };
    const renderbedroom = () => {
      return bedroom.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };

    const renderbathroom = () => {
      return bathroom.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };

    const renderadditionalRooms = () => {
      return additionalRooms.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };
    const renderfurnishedStatus = () => {
      return furnishedStatus.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };
    const renderprojectName = () => {
      return projectName.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };
    const renderlocality = () => {
      return locality.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} |</b>
          </span>
        );
      });
    };




      //**************************
   // const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));

    return (
      <div key={_id}>
          <div className='card' >

 <div style={{padding:'10px',margin: 'auto'}} >


           		<div >
           		{name && (
           		  <div className='row'>
           			<div className='side'>
           			  <b>NAME</b>
           			</div>
           			<div className='main'>
           			  <b>{name}</b>
           			</div>

           		  </div>
           		)}
           	  </div>
           	  <div >
           	  {phone1 && (
           		<div className='row'>
           		  <div className='side'>
           			<b>PHONE1</b>
           		  </div>
           		  <div className='main'>
           			<b>{phone1}</b>
           		  </div>

           		</div>
           	  )}
           	</div>

           	<div >
           	{phone2 && (
           	  <div className='row'>
           		<div className='side'>
           		  <b>PHONE2</b>
           		</div>
           		<div className='main'>
           		  <b>{phone2}</b>
           		</div>

           	  </div>
           	)}
             </div>

             <div >
             {email && (
           	<div className='row'>
           	  <div className='side'>
           		<b>EMAIL</b>
           	  </div>
           	  <div className='main'>
           		<b>{email}</b>
           	  </div>

           	</div>
             )}
           </div>

           <div >
           {whatsapp && (
             <div className='row'>
           	<div className='side'>
           	  <b>WHATSAPP ID</b>
           	</div>
           	<div className='main'>
           	  <b>{whatsapp}</b>
           	</div>

             </div>
           )}
           </div>


          <div >
          {requiredFor && (
          <div className='row'>
          <div className='side'>
            <b>Require For</b>
          </div>
          <div className='main'>
            <b>{requiredFor}</b>
          </div>

          </div>
          )}
          </div>

          <div >
				{propertyType != null &&
				  propertyType.length > 0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Type Of Property</b>
					</div>
					<div className='main'>
					  {renderpropertyType()}
					</div>

				  </div>
				)}
				</div>

        <div >
        {bedroom != null &&
        bedroom.length > 0 && (
        <div className='row'>
        <div className='side'>
          <b>Bedroom</b>
        </div>
        <div className='main'>
          {renderbedroom()}
        </div>

        </div>
        )}
        </div>
        <div >
        {bathroom != null &&
        bathroom.length > 0 && (
        <div className='row'>
        <div className='side'>
          <b>Bathroom</b>
        </div>
        <div className='main'>
          {renderbathroom()}
        </div>

        </div>
        )}
        </div>
        <div >
        {additionalRooms != null &&
        additionalRooms.length > 0 && (
        <div className='row'>
        <div className='side'>
        <b>Additional Rooms</b>
        </div>
        <div className='main'>
        {renderadditionalRooms()}
        </div>

        </div>
        )}
        </div>

              <div >
            {furnishedStatus != null &&
              furnishedStatus.length > 0 && (
              <div className='row'>
              <div className='side'>
                <b>Furnished Status</b>
              </div>
              <div className='main'>
                {renderfurnishedStatus()}
              </div>

              </div>
            )}
            </div>


          <div >
				{budgetMin > 0 && (
				  <div className='row'>
					<div className='side'>
					  <b>Minimum Budget</b>
					</div>
					<div className='main'>
					<Icon className="rupee" ></Icon>
					  <b> {budgetMin}</b>
					  <b>
					   {' '} ({this.capitalizeFirst(writtenNumber(budgetMin, {
						  lang: "enIndian"
						}))}
						){" "}
					  </b>
					</div>

				  </div>
				)}
				</div>

        <div >
        {budgetMax > 0 && (
        <div className='row'>
        <div className='side'>
          <b>Maximum Budget</b>
        </div>
        <div className='main'>
        <Icon className="rupee" ></Icon>
          <b> {budgetMax}</b>
          <b>
           {' '} ({this.capitalizeFirst(writtenNumber(budgetMax, {
            lang: "enIndian"
          }))}
          ){" "}
          </b>
        </div>

        </div>
        )}
        </div>
        <div >
      {areaCarpetMin > 0 && (
        <div className='row'>
        <div className='side'>
          <b>Minimum Carpet Area</b>
        </div>
        <div className='main'>
        <Icon className="rupee" ></Icon>
          <b> {areaCarpetMin}</b>
          <b>
           {' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMin, {
            lang: "enIndian"
          }))}
          ){" "}
          </b>
        </div>

        </div>
      )}
      </div>

      <div >
    {areaCarpetMax > 0 && (
      <div className='row'>
      <div className='side'>
        <b>Maximum Carpet Area</b>
      </div>
      <div className='main'>
      <Icon className="rupee" ></Icon>
        <b> {areaCarpetMax}</b>
        <b>
         {' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMax, {
          lang: "enIndian"
        }))}
        ){" "}
        </b>
      </div>

      </div>
    )}
    </div>

    <div >
  {projectName  != null &&
    projectName.length > 0 && (
    <div className='row'>
    <div className='side'>
      <b>Complex/Project Name</b>
    </div>
    <div className='main'>
      {renderprojectName ()}
    </div>

    </div>
  )}
  </div>

  <div >
  {locality != null &&
  locality.length > 0 && (
  <div className='row'>
  <div className='side'>
    <b>Locality</b>
  </div>
  <div className='main'>
    {renderlocality()}
  </div>
  </div>
  )}
  </div>


        <div >
				{createdAt && (
				  <div className='row'>
					<div className='side'>
					  <b>Created On</b>
					</div>
					<div className='main'>

					  <b>{ format(new Date(createdAt), "dd-MM-yyyy")}</b>
					</div>

				  </div>
				)}
				</div>
{/**/}
*<div  >

<React.Fragment>
    <div className='nodisplay'><Button
    // basic
    // inverted
    floated="right"
    size="mini"
    color="teal"
    type="button"
    as={Link}
   // target='_blank'
    to={`/reqresi/ReqResiDetail/${_id}`}
  >
    Detail
  </Button> </div>
  <div className='nodisplay-tab'><Button
    // basic
    // inverted
    floated="right"
    size="mini"
    color="teal"
    type="button"
    as={Link}
    //target='_blank'
    to={`/reqresi/ReqResiDetail/${_id}`}
  >
    Detail
  </Button> </div>
  </React.Fragment>


  </div>
{/**/}
 </div >{/* end  3rd div */}

          </div>
          </div>

    );
  }

  renderLoading() {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }


  render() {
    return (
<div>
<div className='maindiv'>

<div>

    <div>

    </div>
<div className='card' style={{textAlign:'center',padding:'10px',}} >

  <Header> My Requirement For Residential Property</Header>
</div>


{this.props.reqresis.map(this.renderList)}


<br/><br/><br/><br/>

</div>



</div>
</div>


    );
  }
}


const mapStateToProps = ({ reqresis, selection, form,auth }) => {
  const { fetching,limit, offset, count } = reqresis;

  return {
    fetching,
    limit,
    offset,
    count,
    form: form.ReqResiSearch,
    selection,
    reqresis: reqresis.reqresis,
      userA: auth.userA,
  };
};
export default connect(
  mapStateToProps,
  {getUserForAuth,fetchMyReqResis,openModal}
)(ReqResiSearchList);
