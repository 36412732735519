
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import { openModal } from "../../actions/modalAction";
import Paginator from "../paginator/Paginator";
import {searchUsers} from "../../actions/authAction";
import {
  Container,
  Icon,
  Segment,
  Button,
} from "semantic-ui-react";
 


class UserList extends Component {


  componentDidMount() {
    const {offset, limit} = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};

    this.props.searchUsers(values, limit,offset);
    
  } 
  handleSearchUser = () => {
    this.props.openModal("SearchUsersModal");
  }

  renderList(user) {
    const {userId,uphone1,uphone2,uwhatsapp, uname, uemail, companyName, city, _id,
      createdAt,userGroup,userStatus,balanceCreditProp,balanceCreditReq ,
      userSubscriptionStartOn,userSubscriptionExpireOn
    } = user;
    
    //******************
			const renderEditButton = () =>
      this.props.userA !== null &&
      (this.props.userA.userType === "admin") ? (
        <Button
        // basic
        inverted
        floated="right"
        size="mini"
        color="green"
        type="button"
        as={Link}
        to={`/company/UserEdit/${_id}`}
      >
        Edit
      </Button>
      ) : (
      <div></div>
      );
  //^^^^^^^^^^^^^^^
  
    
    const classBGC = ()=>{
      if(Date.parse(userSubscriptionExpireOn) > Date.now()){
if(userGroup === 'Dealer'){
  return 'bgColor-pinkLight card-attached border-radius-bottom border-sb pxy-20 mb-010'
}else if(userGroup === 'Builder') {
return 'bgColor-skyblueLight card-attached border-radius-bottom border-sb pxy-20 mb-010'
}else{
  return 'card-attached border-radius-bottom border-sb pxy-20 mb-010'
}
      }else{
        return 'bgColor-red card-attached border-radius-bottom border-sb pxy-20 mb-010'
      }


    } 
    
      return (
      <div key={_id}>

        {/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > {uname}{' '} {userId}</span>
	</div>

	</div> 

	{/* <div className='card-attached border-radius-bottom border-sb pxy-20 mb-010'  > */}
  <div className={classBGC()}  >
  		{/*  */}

<Button.Group size="small" floated="right">
{renderEditButton()} 

</Button.Group>

        <br />
        <br />
        <div>
        <div>
          {userId && (
        <div className='row'>
        <div className='side'>
        <b>User Id</b>
        </div>
        <div className='main'>
        <b>{userId}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uname && (
        <div className='row'>
        <div className='side'>
        <b>User Name</b>
        </div>
        <div className='main'>
        <b>{uname}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uphone1 && (
        <div className='row'>
        <div className='side'>
        <b>User Phone1</b>
        </div>
        <div className='main'>
        <b>{uphone1}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uphone2 && (
        <div className='row'>
        <div className='side'>
        <b>User Phone2</b>
        </div>
        <div className='main'>
        <b>{uphone2}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uemail && (
        <div className='row'>
        <div className='side'>
        <b>User Email</b>
        </div>
        <div className='main'>
        <b>{uemail}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uwhatsapp && (
        <div className='row'>
        <div className='side'>
        <b>User Whatsapp ID</b>
        </div>
        <div className='main'>
        <b>{uwhatsapp}</b>
        </div>

        </div>
        )}
        </div>



        <div >
        {companyName && (
        <div className='row'>
        <div className='side'>
        <b>Company Name</b>
        </div>
        <div className='main'>
        <b>{companyName}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {city && (
        <div className='row'>
        <div className='side'>
        <b>City</b>
        </div>
        <div className='main'>
        <b>{city}</b>
        </div>

        </div>
        )}
        </div>

        <div >
        {userGroup && (
        <div className='row'>
        <div className='side'>
        <b>User Group</b>
        </div>
        <div className='main'>
        <b>{userGroup}</b>
        </div>

        </div>
        )}
        </div>


        <div >
        {userStatus && (
        <div className='row'>
        <div className='side'>
        <b>User Status</b>
        </div>
        <div className='main'>
        <b>{userStatus}</b>
        </div>

        </div>
        )}
        </div>

        <div >
        {balanceCreditProp >0 && (
        <div className='row'>
        <div className='side'>
        <b>Balance Credit Prop</b>
        </div>
        <div className='main'>
        <b>{balanceCreditProp}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {balanceCreditReq >0 && (
        <div className='row'>
        <div className='side'>
        <b>Balance Credit Req</b>
        </div>
        <div className='main'>
        <b>{balanceCreditReq}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {_id && (
        <div className='row'>
        <div className='side'>
        <b>User _id</b>
        </div>
        <div className='main'>
        <b>{_id}</b>
        </div>

        </div>
        )}
        </div>

        <div >
        {createdAt && (
        <div className='row'>
        <div className='side'>
        <b>Created On</b>
        </div>
        <div className='main'>
        <b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
        </div>

        </div>
        )}
        </div>

        <div > 
        {userSubscriptionStartOn && (
        <div className='row'>
        <div className='side'>
        <b>Subscription Start On</b>
        </div>
        <div className='main'>

        <b>{format(new Date(userSubscriptionStartOn), "dd-MM-yyyy")}</b>{' '}

        </div>

        </div>
        )}
        </div>

        <div >
        {userSubscriptionExpireOn && (
        <div className='row'>
        <div className='side'>
        <b>Subscription Expire On</b>
        </div>
        <div className='main'>

        <b>{format(new Date(userSubscriptionExpireOn), "dd-MM-yyyy")}</b>{' '}

        </div>

        </div>
        )}
        </div>

        </div>

        </div>
      </div>
    );
  }

  back = () => {
    const {
      offset,
      limit
     
    } = this.props;

    if (offset === 0) {
      return;
    }
    
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};

    this.props.searchUsers(values, limit, (offset - limit));
  };

  advance = () => {
    const {
      offset,
      limit,
      count
      
    } = this.props;

    if (offset + limit > count) {
      return;
    }
 
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};

    this.props.searchUsers(values, limit, (offset + limit));
  };

  renderPaginator() {
    if (this.props.users.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }


  render() {
    if (
      this.props.userA !== null &&
      (this.props.userA.userType === "admin")
    ) {
    return (
      <Container text>
        {/**/}
<div className='cashbook-H box-shadow'>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchUser}>
      <span className='icon-button border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.count}{' '}All Users
</div>
</div>


</div>
{/**/}
      

        {this.props.users.map(this.renderList.bind(this))}

        {this.renderPaginator()}

        <br/><br/><br/>
      </Container>
    );
  }
  return (
    <Container text>
      <Segment>
        <div style={{ color: "red", textAlign: "center" }}>
          <h2>You Are Not Authorised To View Users </h2>
        </div>
      </Segment>
    </Container>
  );
  }
  }


const mapStateToProps = ({ auth, form }) => {
  const { limit, offset, count } = auth;

  return {
    limit,
    offset,
    count,
    form: form.UserSearch,
    userA: auth.userA,
    users: auth.usersA,
  };
};

export default connect(
  mapStateToProps,
  {searchUsers,openModal,}
)(UserList);
