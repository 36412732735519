const sortingO = [
  'Budget-Low To High',
  'Budget-High To Low',
  'Area-Low To High',
  'Area-High To Low',
  'Newest',
  'Oldest'
];
export const sorting = () => {
  return sortingO.map(value => ({ key: value, text: value, value: value }));
};
const reqresiStatusO = ['inactive', 'active'];
export const reqresiStatus = () => {
  return reqresiStatusO.map(value => ({ key: value, text: value, value: value }));
};
//#########
const areaUnitO = ['Sq Foot', 'Sq Yard', 'Sq Meter'];
export const areaUnit = () => {
  return areaUnitO.map(value => ({ key: value, text: value, value: value }));
};
//#########
const propertyForO = ['', 'Sale', 'Rent'];
export const propertyFor = () => {
  return propertyForO.map(value => ({ key: value, text: value, value: value }));
};
//#######
const requiredForO = ['', 'Purchase', 'Rent'];
export const requiredFor = () => {
  return requiredForO.map(value => ({ key: value, text: value, value: value }));
};

const booleanOptionO = ['Yes', 'No',"Doesn't Matter"];
export const booleanOption = () => {
  return booleanOptionO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const projectTypeO = [
  'Residential',
  'Commercial'
];
export const projectType = () => {
  return projectTypeO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const propertyTypeO = [

  'Flat',
  'Floor',
  'Penthouse',
  'Duplex House',
  'Residential Land /Plot'
];
export const propertyType = () => {
  return propertyTypeO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$


const bedroomO = ['1', '2', '3', '4', '5', '6', '6+'];
export const bedroom = () => {
  return bedroomO.map(value => ({ key: value, text: value, value: value }));
};
//############
const bathroomO = ['1', '2', '3', '4', '5', '6', '6+'];
export const bathroom = () => {
  return bathroomO.map(value => ({ key: value, text: value, value: value }));
};
//###############
const liftsInTheTowerO = ['0','1', '2', '3', '4', '5', '6', '6+'];
export const liftsInTheTower = () => {
  return liftsInTheTowerO.map(value => ({ key: value, text: value, value: value }));
};
const additionalRoomsO = ['Servant Room', 'Study Room', 'Store', 'Pooja Room'];
export const additionalRooms = () => {
  return additionalRoomsO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//#######
const furnishedStatusO = ['Newly Furnished','Furnished', 'Semi-Furnished', 'Unfurnished'];
export const furnishedStatus = () => {
  return furnishedStatusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//###############
const acO = ['1', '2', '3', '4', '5', '6', ' 7', '8', ' 9', '10', '10+'];
export const ac = () => {
  return acO.map(value => ({ key: value, text: value, value: value }));
};
//#######
const fanO = ['1', '2', '3', '4', '5', '6', ' 7', '8', ' 9', '10', '10+'];
export const fan = () => {
  return fanO.map(value => ({ key: value, text: value, value: value }));
};

//############
const companyLeaseO = ['Yes', 'No', "Doesn't Matter"];
export const companyLease = () => {
  return companyLeaseO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const agreementO = ['Registrar', 'Notary', "Doesn't Matter"];
export const agreement = () => {
  return agreementO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//#######
const occupancyStatusO = ['Owner', 'Tenant', 'Vacant'];
export const occupancyStatus = () => {
  return occupancyStatusO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############
const whiteO = ['100%', 'Circle Rate', 'Mimimum', 'Maximum', 'Flexible'];
export const white = () => {
  return whiteO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const siteVisitDaysO = ['All Seven Days', 'Saturday & Sunday', 'Only Sunday'];
export const siteVisitDays = () => {
  return siteVisitDaysO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};

//#######
const freeholdO = ['Yes', 'No'];
export const freehold = () => {
  return freeholdO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############
const facingO = [
  'East',
  'West',
  'North',
  'South',
  'North - East',
  'North - West',
  'South - East',
  'South -West',
  "Doesn't Matter"
];
export const facing = () => {
  return facingO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const entryO = [
  'East',
  'West',
  'North',
  'South',
  'North - East',
  'North - West',
  'South - East',
  'South -West',
  "Doesn't Matter"
];
export const entry = () => {
  return entryO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//#######
const OverlookingO = ['Park', 'Road', 'Other Building',"Doesn't Matter"];
export const Overlooking = () => {
  return OverlookingO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//############
const carParkingCoveredO = ['1', '2', '3', '4', '5', '6', '6+'];
export const carParkingCovered = () => {
  return carParkingCoveredO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############
const carParkingOpenO = ['1', '2', '3', '4', '5', '6', '6+'];
export const carParkingOpen = () => {
  return carParkingOpenO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//#######
const floorNoO = [
  'Ground',
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '15+',
  'Top'
];
export const floorNo = () => {
  return floorNoO.map(value => ({ key: value, text: value, value: value }));
};
//############
const totalFloorsO = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '10+'
];
export const totalFloors = () => {
  return totalFloorsO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
//###############

//###############
const localityO = [
  'Sector-01',
  'Sector-02',
  'Sector-03',
  'Sector-04',
  'Sector-05',
  'Sector-06',
  'Sector-07',
  'Sector-08',
  'Sector-09',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18A',
  'Sector-18B',
  'Sector-19A',
  'Sector-19B',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
const cityO = ['Dwarka', 'Gurugram','Noida', 'Rohini'];
export const city = () => {
  return cityO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};