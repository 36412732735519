import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import {updateStatic,fetchStatic,resetStatic} from "../../actions/staticAction";
import { withRouter} from "react-router-dom";

import {
  Segment,
  Form,
  Button,
  Header,
} from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import NumberInput from "../form/NumberInput";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";

import {
  
  adForOption,
  adCategoryOption,
  adLocationPropertyCommonO,
  adLocationPropertyBFloorO,
  adLocationDealer,
  adSubLocation,
  // position,
  // locality,

} from './Data';

class StaticEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchStatic(id);

  }
  componentWillUnmount() {
this.props.resetStatic()
  }
  onSubmit = values => {
    const { id } = this.props.match.params;
    this.props.updateStatic(id, values, this.props.history);
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  render() {

    const { adFor,adCategory, adLocation,creditUsed,position } =
      this.props.state.form &&
      this.props.state.form.StaticEdit !== undefined &&
      this.props.state.form.StaticEdit.values !== undefined &&
      this.props.state.form.StaticEdit.values;

    const { handleSubmit, pristine, reset, submitting } = this.props;
//********************** */


const adLocationProperty = ()=>{
  const data =()=>{
    if(adCategory==='Commercial'){
      return adLocationPropertyCommonO
    }
    if(adCategory==='Residential'){
      return [...adLocationPropertyCommonO,
        ...adLocationPropertyBFloorO]
    }
  }
return data() !==undefined && data().map(value => ({
  key: value,
  text: value,
  value: value,style:{fontSize:'18px'}
}));
}
//@@@@@
const renderAdSubLocationField = ()=>{
  if((adLocation==='BUILDER FLOOR STATIC ALL') || (adLocation==='STATIC ALL')){
    return (
      < React.Fragment>               
               <label >Ad Sub Location</label>
             <Field
               search={true}
               name="adSubLocation"
               type="text"
               component={SelectInput}
               options={adSubLocation()}
               placeholder="Ad Sub Location"
             />
      </React.Fragment>
     )
  }else{
    <div></div>
  }
  

}
const renderAdForPropertyField = ()=>{
  
    return (
     < React.Fragment>
            
            <label >Ad Category</label>
            <Field
              search={true}
              name="adCategory"
              type="text"
              component={SelectInput}
              options={adCategoryOption()}
              placeholder="Ad Category"
            />

            
              <label >Ad Location</label>
              <Field
              search={true}
              name="adLocation"
              type="text"
              component={SelectInput}
              options={adLocationProperty()}
              placeholder="Ad Location"
              />
              {renderAdSubLocationField()}
     </React.Fragment>
    )
  
  }

//@@@@@
const renderAdForDealerField = ()=>{
  
    return (
     < React.Fragment>
            
            {/* <label >Ad Category</label>
            <Field
              search={true}
              name="adCategory"
              type="text"
              component={SelectInput}
              options={adCategory()}
              placeholder="Ad Category"
            /> */}

            
              <label >Ad Location</label>
              <Field
              search={true}
              name="adLocation"
              type="text"
              component={SelectInput}
              options={adLocationDealer()}
              placeholder="Ad Location"
              />
              {/* <label >Ad Sub Location</label>
            <Field
              search={true}
              name="adSubLocation"
              type="text"
              component={SelectInput}
              options={adSubLocation()}
              placeholder="Ad Sub Location"
            /> */}
     </React.Fragment>
    )
  
  }


//@@@@@@@@@@@@
const renderDealerOrPropertyField = () =>{
  if((adFor==='Property SALE') || (adFor==='Property RENT')){
    return renderAdForPropertyField()
  }
  if((adFor==='Dealer') ){
   return renderAdForDealerField()
  }
}

//********************** */



const renderOtherField = ()=>{
  
    return (
     < React.Fragment>
           {/* <label >Credit Used</label>
            <Field
              name="creditUsed"
              type="number"
              component={TextInput}
              placeholder="Credit Used"
            />
            <label >Position</label>
            <Field
              name="position"
              type="text"
              component={TextInput}
              placeholder="Position"
            /> */}
            <label >
            Credit Used{' '}
        <span>
        <b>
        ({writtenNumber(creditUsed, {
        lang: 'enIndian'
        }).toUpperCase()}
        ){' '}
        </b> 
        </span>
        </label><span className='color-mandatory'> * </span>
        <Field
        type="number"
        component={NumberInput}
        placeholder="Credit Used"
        name="creditUsed"
        />
        <label >
        Position{' '}
        <span>
        <b>
        ({writtenNumber(position, {
        lang: 'enIndian'
        }).toUpperCase()}
        ){' '}
        </b>
        </span>
        </label><span className='color-mandatory'> * </span>
        <Field
        type="number"
        component={NumberInput}
        placeholder="Position"
        name="position" 
        />
        
            <label >Started On</label>
            <Field
              name="start"
              type="text"
              component={DateInput}
              placeholder="Started On"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Expired On</label>
            <Field
              name="expire"
              type="text"
              component={DateInput}
              placeholder="Expired On"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            /> 
     </React.Fragment>
    )
  
  }


//********************** */


      return (
        <div style={{maxWidth:'700px',margin:'auto'}}>
        <Segment
            textAlign="center"
            //attached="bottom"
            inverted
            color="teal"
            style={{ border: "none" }}
          >
          <Header
                 // color="blue"
                content="Edit Static"
                />
          </Segment>
  				<div className="card ">
  	          <div className=" label" >

              <Form style={{ padding: 25 }} onSubmit={handleSubmit(this.onSubmit)}>
             
              <Button
            color="teal"
            size="mini"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
             <Button
                size="mini"
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
              <br /><br />
              
              <label >Ad For</label>
            <Field
              search={true}
              name="adFor"
              type="text"
              component={SelectInput}
              options={adForOption()}
              placeholder="Ad For"
            />

            {renderDealerOrPropertyField()}

            {renderOtherField()}

    
                <br />

                <Button
                  color="olive"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Submit
                </Button>
                <Button
                  color="orange"
                  type="button"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Clear Values
                </Button>
              </Form>
    <br />
      <br />
        <br />
          <br />
  				</div>
              </div>
              </div>

      );

  }
}

export default connect(
  state => {

    return {
      state,
      userA: state.auth.userA,
      initialValues: state.statics.static
    }; // pull initial values from account reducer
  },
   {updateStatic,fetchStatic,resetStatic}
)(
  reduxForm({
    form: "StaticEdit", // a unique identifier for this form
    //validate
  })(withRouter(StaticEdit))
);





