import React, { Component } from "react";
import { connect } from "react-redux";
//import { Grid ,Button,Icon } from "semantic-ui-react";
import { Switch, Route, } from "react-router-dom";
import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import StaticCreate from "./StaticCreate";
import StaticEdit from "./StaticEdit";
//import StaticDetail from "./StaticDetail";


import StaticSearchList from "./StaticSearchList";
import StaticPropAdByDealer from "./StaticPropAdByDealer";
import StaticDealerAdByDealer from "./StaticDealerAdByDealer";

import Layout from "../layout/Layout";

 

class StaticDashboard extends Component {
  componentDidMount() {
    this.props.getUserForAuth();
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };

  render() {

    return (
    <div>
    <div className='maindiv'>

    <Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
    
    <Route
      exact
      path="/static/StaticDealerAdByDealer"
      component={StaticDealerAdByDealer}
    />
    <Route
      exact
      path="/static/StaticDealerAdByDealer/:id"
      component={StaticDealerAdByDealer}
    />
    <Route
      exact
      path="/static/StaticPropAdByDealer"
      component={StaticPropAdByDealer}
    />
    <Route
      exact
      path="/static/StaticPropAdByDealer/:id"
      component={StaticPropAdByDealer}
    />
      <Route
        exact
        path="/static/StaticCreate"
        component={StaticCreate}
      />
      {/* <Route
        exact
        path="/static/StaticDetail/:id"
        component={StaticDetail}
      /> */}

      <Route
        exact
        path="/static/StaticEdit/:id"
        component={StaticEdit}
      />


      <Route
        exact
        path="/static/StaticSearchList"
        component={StaticSearchList}
      />

    </Switch>
        </Layout >
        </div>

    <div className='nodisplay'>
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>
      );


}
}
// const mapStateToProps = state => ({
// auth: state.auth
// });


export default connect(
  // mapStateToProps,
  null,
  {showBackButton, getUserForAuth, logoutUser  }
)(StaticDashboard);
