import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import {fetchCompanys} from "../../actions/companysAction";
import {createCreditAdd} from "../../actions/creditAddAction";

import { withRouter } from "react-router-dom";

import {
 
  Segment,
  Form, 
  Button,
  Header,
} from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import TextArea from "../form/TextArea";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";
import NumberInput from "../form/NumberInput";

import {
  combineValidators,
  isRequired,
} from 'revalidate';

const validate = combineValidators({
  amountPaid:isRequired('Amount Paid') ,
  creditAdd:isRequired('Credit Add') ,
  creditType:isRequired('Credit Type') ,
  expiredOn:isRequired('ExpiredOn') ,
  paymentDetail:isRequired('Payment Detail') ,
});


class CreditAddCreate extends Component {
  componentDidMount() {
    this.props.fetchCompanys();
  }

  onSubmit = values => {
    const dealerN = this.props.companysForData.length >0 && this.props.companysForData.find(val => {
      return val._id.toString() === this.props.match.params.id;
    }).cname
    const valuesF={...values,dealerN}
    const { id } = this.props.match.params;
    this.props.createCreditAdd(id,valuesF );
  };

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    const { amountPaid,creditAdd} =
      this.props.state.form &&
      this.props.state.form.CreditAddCreate !== undefined &&
      this.props.state.form.CreditAddCreate.values !== undefined &&
      this.props.state.form.CreditAddCreate.values;

    const creditType = () => {
      return ['Credit For Property','Credit For Require'].map(value => ({
        key: value,
        text: value,
        value: value
      }));
    };

    return (
     <div style={{maxWidth:'700px',margin:'auto'}}>
      <Segment
          textAlign="center"
          attached
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Create CreditAdd"
              />
        </Segment>
        <div className="card ">
          <div className=" label" >

          <Form style={{ padding: 25 }} onSubmit={handleSubmit(this.onSubmit)}>
          <Button
            size="mini"
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <br /><br />
          <label >
          Amount Paid{' '}
                  <span>
                    <b>
                      ({writtenNumber(amountPaid, {
                        lang: 'enIndian'
                      }).toUpperCase()}
                      ){' '}
                    </b>
                  </span>
                </label>
            
            <Field
              name="amountPaid"
              type="number"
              component={NumberInput}
              placeholder="Amount Paid"
            />
            <label >
            CreditAdded{' '}
                  <span>
                    <b>
                      ({writtenNumber(creditAdd, {
                        lang: 'enIndian'
                      }).toUpperCase()}
                      ){' '}
                    </b>
                  </span>
                </label>
            
            <Field
              name="creditAdd"
              type="number"
              component={NumberInput}
              placeholder="credit Added"
            />
            
            <label >Credit Type</label>
            <Field
              name="creditType"
              type="text"
              component={SelectInput}
              options={creditType()}
              placeholder="Credit Type"
            />


            <label >Expired On</label>
            <Field
              name="expiredOn"
              type="text"
              component={DateInput}
              placeholder="Expired On"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Payment Detail</label>
                <Field
                  name="paymentDetail"
                  placeholder="Payment Detail"
                  type="text"
                  component={TextArea}
                  rows={3}
                />

            <label >Note</label>
                <Field
                  name="note"
                  placeholder="Note"
                  type="text"
                  component={TextArea}
                  rows={3}
                />

            <br />

            <Button
              color="olive"
              type="submit"
              disabled={pristine || submitting}
            >
              Submit
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
          </Form>
          <br/>
                   </div>
                   </div>
                   </div>


    );
  }
}

export default connect(
  state => {
    
    return {
      state,
      companysForData: state.companys.companysForData,
    };
  },
  {createCreditAdd,fetchCompanys}
)(
  reduxForm({
    form: "CreditAddCreate", // a unique identifier for this form
     validate
  })(withRouter(CreditAddCreate))
);
