import axios from "axios";
import { toastr } from "react-redux-toastr";

import {openModal, closeModal } from "./modalAction";

import {
  FETCH_PROPRESIS,
  FETCH_PROPRESI,
  SEARCH_PROPRESIS,
  FETCHING_PROPRESIS,
} from "./types";




//******************
//fetch individual  FinancerPropResi
export const fetchFinancerPropResis = (id) => async dispatch => {
  try {
    const res = await axios.post(`/api/FinancerPropResis`,{id:id});
    
    dispatch({ type: FETCH_PROPRESIS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetching:false} });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
}; 
//fetch individual  FinancerPropResi for ad book
export const fetchFinancerPropResisAd = (id) => async dispatch => {
  try {
    const res = await axios.post(`/api/FinancerPropResisAd`,{id:id});
    
    dispatch({ type: FETCH_PROPRESIS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetching:false} });
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};


//******************
//search  PropResis SALE

export const searchPropResiSales = (
  values,
  limitRS,
  offsetRS,
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/PropResiSaleSearch", {
  values,
  limitRS,
  offsetRS,
    });
   
    dispatch({ type: SEARCH_PROPRESIS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetchingRS:false} });
    dispatch(closeModal());
  } catch (error) {
    
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//******************
//search  BuilderFloor SALE

export const searchBuilderFloorSales = (
  values,
  limitRSBF,
  offsetRSBF,
  
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/BuilderFloorSaleSearch", {
      values,
      limitRSBF,
      offsetRSBF,
      
    });
    
    dispatch({ type: SEARCH_PROPRESIS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetchingRSBF:false} });
    dispatch(closeModal());
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//*****************
//search  PropResis RENT

export const searchPropResiRents = (
  values,
  limitRR,
  offsetRR,
   
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/PropResiRentSearch", {
  values,
  limitRR,
  offsetRR,
       
    });
  
    dispatch({ type: SEARCH_PROPRESIS, payload: res.data });
    dispatch({ type: FETCHING_PROPRESIS, payload: {fetchingRR:false} });
    dispatch(closeModal());
  } catch (error) {
    if(error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }else{
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};

//fetch single PropResi
export const fetchPropResi = id => async dispatch => {
  try {
    const res = await axios.get(`/api/PropResiDetail/${id}`);
   
    dispatch({ type: FETCH_PROPRESI, payload: res.data });
  } catch (error) {
    
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//#####################################################

//delete PropResi
export const deletePropResi = (id) => async () => {
  try {
    const res = await axios.delete(`/api/PropResiDelete/${id}`);
    
    if(res.data.msg ==='success'){
      toastr.success('Property Deleted')
    }
  } catch (error) {
    
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//update PropResi
export const updatePropResi = (id, values) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/PropResiEdit/${id}`, values);
    if(res.data.msg ==='success'){
       toastr.success('Property Updated')
     }
     dispatch({ type: FETCH_PROPRESI, payload: null });
  } catch (error) {
   
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again_updatePropResi");
    }
  
  }
};
//************************

//update updatePropResiForAddImagePath
export const updatePropResiForAddImagePath = (id, path) => async () => {
  try {
    const res = await axios.put(`/api/PropResiEditForAddImagePath`, {id:id, path:path});
    if(res.data.msg === 'success'){
      toastr.success('Image Path Added');
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
//#####################################################
//update PropResi to INACTIVE
export const updatePropResiToInactive = (id) => async dispatch => {
  try {
    const res = await axios.put(`/api/PropResiInactive/${id}`);

  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};


// create PropResi
export const createPropResi = (values) => async dispatch => {
  try {

    const res = await axios.post("/api/PropResiCreate", {
      values
    });
    if (res.data.msg ==='success') {
      toastr.success('PropResi Created Successfully');
    }
      

  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
    }
    if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  
  }
};
