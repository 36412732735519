import React, { Component } from 'react';
//import { Link, } from 'react-router-dom';
import { reduxForm, Field } from "redux-form";
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authAction';
import { closeModal } from "../../actions/modalAction";
import {
  Button,
  Form,
  Image,
  Checkbox
} from 'semantic-ui-react';

import TextInput from "../form/TextInput";
//import SelectInput from "../form/SelectInput";
import {
  combineValidators,
  isRequired,
} from 'revalidate';

const validate = combineValidators({
//************user */

  cid:isRequired('Company ID') ,
  userId:isRequired('UserId') ,
  password:isRequired('Password') ,
  
});

class Login extends Component {
  state = {
    showP:false,
  }

   
  onSubmit = values => {
    this.props.loginUser(values);
  };
  

  toggle = () => this.setState({ showP: !this.state.showP })
  render() {
  
    const { handleSubmit} = this.props;
    const showPass = this.state.showP ? "text" : "password"
    // const { userGroup} =
    //   this.props.state.form &&
    //   this.props.state.form.Login !== undefined &&
    //   this.props.state.form.Login.values !== undefined &&
    //   this.props.state.form.Login.values;

      
    return (
      <div className='border borderwidth-5'
      style={{borderWidth:'5px'}}
      >
    
      <div  className="card-attached " style={{maxWidth:'500px',margin:'auto'}}>
            {/*  */}  
        
            <div className='disflex-paginator' >
  
  <Image size='mini' src='/logo.png' style={{ marginRight: '.5em' }} />
<div  style={{fontSize:'1.2rem',fontWeight:'bold',marginRight:'5px'}}>
<span style={{color:'blue'}}>ADMIN.</span>
  <span style={{color:'dodgerblue'}}>FIND</span>
  
  <span style={{color:'blue'}}>PROP</span><span>.IN</span></div>
</div>


{/*  */}
<div className='masthead  font-18B textAlign-center pxy-10 color-white'>  
Login to your account
</div>
<br/>
{/*  */}
            <div className="card-attached-2 ">
            <div className=" label pxy-10" >
          
            <Form  onSubmit={handleSubmit(this.onSubmit)}>
            {/* <label>Company ID</label>{' '}<span style={{color:'red'}}><b>*</b></span>
              <Field
              name="cid"
              type="text"
              component={TextInput}
              placeholder="Company ID"
              /> */}
            <label >User Id</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="userId"
              type="text"
              component={TextInput}
              placeholder="User Id"
            />
              
              <label >Password</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="password"
              type={showPass}
              //type="password"
              component={TextInput}
              placeholder="Password"
            />
            
              <Checkbox
              //name="showP"
              //type="checkbox"
              label='Show Password'
              onChange={this.toggle}
              checked={this.state.showP}
              />
              <br/><br/>
              <div className='disflex-paginator' >
              <Button 
              color="teal" 
              //type="button"
               >
                Login
              </Button>
              <Button
            color="blue"
            type="button"
            onClick={()=>this.props.closeModal()}

          >
            Cancel
          </Button>
              </div>
              
            </Form>
          </div>

          




      </div>
      
      </div>

      </div>
    );
  }
}



const mapStateToProps = state => ({
  state,
});

export default connect(
  mapStateToProps,
  { loginUser, closeModal }
)(
  reduxForm({
    form: "Login",   // a unique identifier for this form
    //destroyOnUnmount: false,
     validate
  })(Login)
  
  );
