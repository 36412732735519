import React from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import Resizer from 'react-image-file-resizer';
import { fetchAppParameter } from "../../actions/commonAction";

import {
  Image,
  //Segment,
  Header,
  Button,
  Icon
} from 'semantic-ui-react';


import { imageOfProperty,imageOfProject, belongTo, } from "./DataImg";

class AddImage extends React.Component {
  state = {
    selectedFile: null, 
    loaded: 0,
    description:'',
    //@
    files: [],
    imageOf: '',
    belongTo: '',
    description:'',
    cropResult: null,
    image: {},
    imageURL: '',
    //*********** */
    height:768,
    width:1280,
    orientation:'Landscape',
  };
  
  componentDidMount() {
    if (!this.props.parameter){
      this.props.fetchAppParameter()
        }
  }

  fileChangedHandler=(event)=> {
        var fileInput = false
        this.setState({files:event.target.files})
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
          Resizer.imageFileResizer(
                event.target.files[0],
                this.state.width,
                this.state.height,
                'JPEG',
                90,
                0,
                uri => {

                    let imageUrl = URL.createObjectURL(uri);
                    this.setState({
                      cropResult: imageUrl,
                      image: uri
                    });

                },
                'blob'
            );
        }
    }

    // ****************

  cancelImage = () => {
    this.setState({
      files: [],
      image: {},
      cropResult: null,
    });
  };

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  // ***************
  handleUploadImage = async ev => {
    ev.preventDefault();
    const imageURL=this.props.parameter !== undefined && this.props.parameter !== null && this.props.parameter.currentURL
    
    const data = new FormData();
    data.append('file', this.state.image);
    data.append('id', this.props.match.params.id);
    data.append('imageURL', imageURL);
    data.append('belongTo', this.state.belongTo);
    data.append('imageOf', this.state.imageOf);
     
    console.log('imageURL',imageURL)
     try {    
      if(!this.state.belongTo.trim() || !this.state.imageOf.trim()  ){
        if (!this.state.belongTo.trim()) {
          toastr.error("Image Belongs To is required");
        }
        if (!this.state.imageOf.trim() ) {
          toastr.error("Image Of is required");
        }
        
      
      }else{  
        const resImage = await axios.post(`${imageURL}/api/AddImage`, data);
        if(resImage.data.msg ==='success'){
          const values = {
            id : this.props.match.params.id,
            belongTo: this.state.belongTo,
            imageOf:this.state.imageOf,
            path:resImage.data.path
          }
          
          const res= await axios.post(`/api/ImageCreate`, values);
          if(res.data.msg ==='success'){
            toastr.success('Image Added Successfully')
            this.setState({
              //belongTo: '',
              imageOf: '',
              files: [],
              image: {},
              cropResult: null, 
            });
          }
        }
        
        }                         
      


    } catch (error) {

      toastr.error("Oops", "Something went wrong.Please Try Again_addimage");
    }
  

  };

  // ***************
  handleUploadVideo = async ev => {
    ev.preventDefault();
    const imageURL=this.props.parameter !== undefined && this.props.parameter !== null && this.props.parameter.currentURL
    
    const data = new FormData();
    data.append('file', this.state.selectedFile);
    data.append('id', this.props.match.params.id);
    data.append('videoURL', imageURL);
    data.append('belongTo', this.state.belongTo);
    data.append('videoOf', this.state.imageOf);
     console.log('imageURL',imageURL)
    
     try { 
       
      if(!this.state.belongTo.trim() || !this.state.imageOf.trim() || !this.state.description.trim() ){
        if (!this.state.belongTo.trim()) {
          toastr.error("Image Belongs To is required");
        }
        if (!this.state.imageOf.trim() ) {
          toastr.error("Image Of is required");
        }
        if (!this.state.description.trim() ) {
          toastr.error("Description is required");
        }
        
      
      }else{  
        const resVideo = await axios.post(`${imageURL}/api/AddVideo`, 
      data,
      {
        onUploadProgress: ProgressEvent => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
          })
        },
      }
      );
      
      
      if(resVideo.data.msg ==='success'){
        const values = {
          id : this.props.match.params.id,
          belongTo: this.state.belongTo,
          videoOf:this.state.imageOf,
          description:this.state.description,
          path:resVideo.data.path
        }
          
        const res= await axios.post(`/api/VideoCreate`, values);
        if(res.data.msg ==='success'){
          toastr.success('Video Added Successfully')
          this.setState({
            //belongTo: '',
            imageOf: '',
            description:'',
            selectedFile: null,  
          });
        }
            
          }
        }                       
      

      // **********
      
    } catch (error) {

      toastr.error("Oops", "Something went wrong.Please Try Again_addVideo");
    }
  

  };

  //*************** */
  
  setHeightWidthToPortrait =()=>{
    
      this.setState({
        height:1280,
        width:768,
        orientation:'Portrait'
      })
    }
    setHeightWidthToLandscape =()=>{
    
      this.setState({
        height:768,
        width:1280,
        orientation:'Landscape'
      })
    }
  
  
  //@@@@@@@@
  handleselectedFile = event => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })
  }

  
  //@@@@@@@@@@@@@

  render() {
   
    const imageOf=()=>{
      if(this.state.belongTo==="Project"){
      return imageOfProject()
      }
      if(this.state.belongTo==="Property"){
        return imageOfProperty()
      }
    }
//****** */
const renderAddImageButton =()=>{
  if(this.state.files.length <1){
    return(
      <React.Fragment>
      <div>
<label size="tiny" for="hidden-new-file" class="ui icon button">

<span className="icon-size">
<Icon  name="plus circle"/>
</span><span className="icon-size">Add Image</span>

</label>
<input
type="file" id="hidden-new-file" accept="image/*"
style={{display: 'none'}}
onChange={this.fileChangedHandler}
/>
</div>
    </React.Fragment>
    )
  }
}
//*******************
    const renderPreview =()=>{
      if(this.state.files.length >0){
        return(
          <React.Fragment>
          <div
          //style={{padding:'15px'}}
          >
          <br/>
          <Header  color="teal" content="Preview and Upload" />
          <h6><span>Orientation : {this.state.orientation} #</span> {' '} <span>Height : {this.state.height} #</span>{' '}<span>Width : {this.state.width} </span></h6>
            <br/>
            <Image
            src={this.state.cropResult}/>
    
            </div>
    
    
            <br /><br />
      <div className='disflex-paginator'>
            <Button.Group>
              <Button
                //loading={loading}
                onClick={this.handleUploadImage}
                // style={{ width: '120px' }}
                positive
                icon="check"
                content="upload"
              />
              <Button
                size='mini'
                onClick={this.cancelImage}
                // style={{ width: '100px' }}
                icon="close"
                content="cancell"
              />
            </Button.Group>
    
        </div>
        </React.Fragment>
        )
      }
    }
    //******************** */
    return (
      <div className='main-div disflex-paginator' style={{backgroundColor:'white'}}>
      <div  style={{padding:'15px'}}>
{/**/}

{/**/}
<div className='margin-auto-H smalldiv border-radius-3'  >
<div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{/*  */}
Add Image
{/*  */}
</div>
</div>
{/**/}

<br/>
{/*  */}
<form onSubmit={this.handleSubmit}>
          <Header color="teal" sub content="Image Belongs To" />
          <label className='field-wrap'>
            <select
            className='inputPage1'
              name="belongTo"
            value={this.state.belongTo}
            onChange={this.handleChange}
            >
              <option value="">Image Belongs To </option>
              {belongTo()}
            </select>
          </label>
          <Header color="teal" sub content="Image Of" />
          <label className='field-wrap'>
            <select
            className='inputPage1'
              name="imageOf"
            value={this.state.imageOf}
            onChange={this.handleChange}
            >
              <option value="">Image Of </option>
              {imageOf()}
            </select>
          </label>
          <Header color="teal" sub content="Image Of" />
          <lable className='field-wrap'> 
          <input
        className='inputPage1'
          placeholder="Image Of"
          name="imageOf"
          type="text"
          value={this.state.imageOf}
          onChange={this.handleChange}
        />
        
        </lable>
        </form>
        <br/>
        <div>
            <Button
            disabled={this.state.orientation === 'Portrait' ? true : false}
              size="mini"
              //floated="right"
              color="blue"
              onClick={ this.setHeightWidthToPortrait}
            >
              Portrait
            </Button>
            <Button
            disabled={this.state.orientation === 'Landscape' ? true : false}
              size="mini"
              //floated="right"
              color="blue"
              onClick={ this.setHeightWidthToLandscape}
            >
              Landscape
            </Button></div>
            <br/>
{/*  */}
{/**/}
<div className='disflex-paginator'>
{renderAddImageButton()}
<Button
color="teal"
//size="mini"
type="button"
onClick={this.cancelImage}
>
Clear Image 
</Button>
</div>
{/**/}

{renderPreview()}


{/* video upload */}
<Header  color="teal" content="Upload Video" />
        <div>
        <label>Description</label>
        </div>
        <div className='field-wrap'>
        <textarea 
        className='inputPage1'
        type="textArea"  
        name="description" 
        value={this.state.description} 
        onChange={this.handleChange} 
        />
        </div>
        
         <div >
        <input type="file" accept="video/*" name="" id="" onChange={this.handleselectedFile} />
        <button onClick={this.handleUploadVideo}>Upload</button>
        <div> {Math.round(this.state.loaded,2) } %</div>{' '}<span style={{color:'blue'}}><b>{this.state.message}</b></span>
      </div>
        {/*  */}
      

</div>
</div>

      
    );
  }
}

const mapStateToProps = (state) => {

  return {
    parameter:state.commons.parameter,
  };
};

export default connect(
  mapStateToProps,
{  fetchAppParameter}
)(AddImage);
